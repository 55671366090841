/* eslint-disable require-jsdoc */
import { EventEmitter } from '@zeainc/zea-engine'
import './CADBody.js'

import { floatsPerSceneBody, BODY_FLAG_CUTAWAY, BODY_FLAG_INVISIBLE } from './CADConstants.js'

/** Class representing a GLCADBody.
 * @ignore
 */
export class GLCADBody extends EventEmitter {
  constructor(cadBody, bodyId) {
    super()
    this.cadBody = cadBody
    this.bodyId = bodyId
    this.flags = 0

    this.dirtyValues = new Set()
  }

  bind(
    cadpassdata,
    sceneBodyItemData,
    cadBodyTextureData,
    bodyItemDataChanged,
    highlightedBodies,
    highlightChangeBatch,
    pushhighlightChangeBatchToWorker
  ) {
    this.cadBodyTextureData = cadBodyTextureData

    const cadBodyDescAddr = this.cadBody.getBodyDataTexelCoords()

    // const offset = bodyId * floatsPerSceneBody
    if (!this.cadBody.isVisible()) this.flags |= BODY_FLAG_INVISIBLE

    if (this.cadBody.isCutawayEnabled && this.cadBody.isCutawayEnabled()) {
      this.flags |= BODY_FLAG_CUTAWAY
    }

    const material = this.cadBody.getMaterial()

    const shaderId = cadpassdata.genShaderID(material.getShaderName())
    // console.log('Shader:' + material.getShaderName() + ':' + shaderId);
    let glmaterialcoords = material.getMetadata('glmaterialcoords')
    if (!glmaterialcoords) {
      glmaterialcoords = cadpassdata.materialLibrary.addMaterial(material)
    }

    sceneBodyItemData[0] = this.cadBody.getBodyDescId()
    sceneBodyItemData[1] = shaderId

    cadBodyTextureData[0] = this.cadBody.getBodyDescId()
    cadBodyTextureData[1] = this.flags
    cadBodyTextureData[2] = cadBodyDescAddr.x
    cadBodyTextureData[3] = cadBodyDescAddr.y

    cadBodyTextureData[4] = glmaterialcoords.x
    cadBodyTextureData[5] = glmaterialcoords.y

    this.visibilityChanged = () => {
      // TODO: Actually modify the draw sets for each visibility chage.
      // It should be similar to hilight changes.
      const visibile = this.cadBody.isVisible()
      if (!visibile) {
        if ((this.flags & BODY_FLAG_INVISIBLE) == 0) {
          this.flags |= BODY_FLAG_INVISIBLE
          this.cadBodyTextureData[1] = this.flags
          bodyItemDataChanged(this.bodyId)
        }
      } else {
        if ((this.flags & BODY_FLAG_INVISIBLE) != 0) {
          this.flags &= ~BODY_FLAG_INVISIBLE
          this.cadBodyTextureData[1] = this.flags
          bodyItemDataChanged(this.bodyId)
        }
      }
    }
    this.cadBody.on('visibilityChanged', this.visibilityChanged)

    this.materialChanged = () => {
      const material = this.cadBody.getMaterial()
      let glmaterialcoords = material.getMetadata('glmaterialcoords')
      if (!glmaterialcoords) {
        glmaterialcoords = cadpassdata.materialLibrary.addMaterial(material)
      }
      cadBodyTextureData[4] = glmaterialcoords.x
      cadBodyTextureData[5] = glmaterialcoords.y
      bodyItemDataChanged(this.bodyId)
    }
    this.cadBody.getParameter('Material').on('valueChanged', this.materialChanged)

    // /////////////////////////////////
    // Body Xfo
    this.globalXfoParam = this.cadBody.getParameter('GlobalXfo')
    this.updateXfo()
    this.globalXfoChanged = () => {
      bodyItemDataChanged(this.bodyId)
      this.dirtyValues.add('Xfo')
    }
    this.globalXfoParam.on('valueChanged', this.globalXfoChanged)

    // /////////////////////////////////
    // Highlight
    if (this.cadBody.isHighlighted()) {
      this.updateHighlightColor()
      highlightedBodies.push(this.bodyId)
    }

    this.highlightChanged = () => {
      if (!highlightChangeBatch.dirty) {
        setTimeout(pushhighlightChangeBatchToWorker, 1)
        highlightChangeBatch.dirty = true
      }
      const highlighted = this.cadBody.isHighlighted()
      if (highlighted) {
        this.dirtyValues.add('Highlight')
        bodyItemDataChanged(this.bodyId)
        if (highlightedBodies.indexOf(this.bodyId) == -1) {
          highlightedBodies.push(this.bodyId)

          // Note: filter out highlight/unhighlight in a single update.
          const indexInSelChangeSet = highlightChangeBatch.unhighlightedBodyIds.indexOf(this.bodyId)
          if (indexInSelChangeSet != -1) {
            highlightChangeBatch.unhighlightedBodyIds.splice(indexInSelChangeSet, 1)
          } else {
            highlightChangeBatch.highlightedBodyIds.push(this.bodyId)
          }
        }
      } else {
        const index = highlightedBodies.indexOf(this.bodyId)
        if (index != -1) {
          highlightedBodies.splice(index, 1)

          // Note: filter out highlight/unhighlight in a single update.
          const indexInSelChangeSet = highlightChangeBatch.highlightedBodyIds.indexOf(this.bodyId)
          if (indexInSelChangeSet != -1) {
            highlightChangeBatch.highlightedBodyIds.splice(indexInSelChangeSet, 1)
          } else {
            highlightChangeBatch.unhighlightedBodyIds.push(this.bodyId)
          }
        }
      }
    }
    this.cadBody.on('highlightChanged', this.highlightChanged)

    // /////////////////////////////////
    // Body Cut Plane
    this.updateCutaway()
    this.cutAwayChangedId = this.cadBody.on('cutAwayChanged', () => {
      bodyItemDataChanged(this.bodyId)
      this.dirtyValues.add('Cutaway')
    })
  }

  updateCutaway() {
    const cpoff = 24
    if (this.cadBody.isCutawayEnabled()) {
      if (!(this.flags & BODY_FLAG_CUTAWAY)) {
        this.flags |= BODY_FLAG_CUTAWAY
        this.cadBodyTextureData[1] = this.flags
      }

      const cutPlane = this.cadBody.getCutVector()
      const cutPlaneDist = this.cadBody.getCutDist()
      this.cadBodyTextureData[cpoff + 0] = cutPlane.x
      this.cadBodyTextureData[cpoff + 1] = cutPlane.y
      this.cadBodyTextureData[cpoff + 2] = cutPlane.z
      this.cadBodyTextureData[cpoff + 3] = cutPlaneDist
    } else {
      if (this.flags & BODY_FLAG_CUTAWAY) {
        this.flags &= ~BODY_FLAG_CUTAWAY
        this.cadBodyTextureData[1] = this.flags
      }
    }
  }

  updateHighlightColor() {
    const highlight = this.cadBody.getHighlight()
    if (highlight) {
      const hoff = 20
      this.cadBodyTextureData[hoff + 0] = highlight.r
      this.cadBodyTextureData[hoff + 1] = highlight.g
      this.cadBodyTextureData[hoff + 2] = highlight.b
      this.cadBodyTextureData[hoff + 3] = highlight.a
    }
  }

  updateXfo() {
    const bodyXfo = this.globalXfoParam.getValue()

    const off = 8
    this.cadBodyTextureData[off + 0] = bodyXfo.tr.x
    this.cadBodyTextureData[off + 1] = bodyXfo.tr.y
    this.cadBodyTextureData[off + 2] = bodyXfo.tr.z
    // this.cadBodyTextureData[off + 3]
    this.cadBodyTextureData[off + 4] = bodyXfo.ori.x
    this.cadBodyTextureData[off + 5] = bodyXfo.ori.y
    this.cadBodyTextureData[off + 6] = bodyXfo.ori.z
    this.cadBodyTextureData[off + 7] = bodyXfo.ori.w
    this.cadBodyTextureData[off + 8] = bodyXfo.sc.x
    this.cadBodyTextureData[off + 9] = bodyXfo.sc.y
    this.cadBodyTextureData[off + 10] = bodyXfo.sc.z
  }

  updateCadBodyTex() {
    this.dirtyValues.forEach((key) => {
      if (key == 'Xfo') this.updateXfo()
      else if (key == 'Highlight') this.updateHighlightColor()
      else if (key == 'Cutaway') this.updateCutaway()
    })
    this.dirtyValues.clear()
  }

  destroy() {
    this.cadBody.off('visibilityChanged', this.visibilityChanged)
    if (this.cadBody.cutAwayChanged) {
      this.cadBody.off('cutAwayChanged', this.cutAwayChangedId)
    } else {
      const cutParam = this.cadBody.getParameter('CutawayEnabled')
      if (cutParam) {
        cutParam.off('valueChanged', this.cutAwayEnabledId)
      }
    }
    this.cadBody.getParameter('Material').off('valueChanged', this.materialChanged)
    this.cadBody.getParameter('GlobalXfo').off('valueChanged', this.globalXfoChanged)
    this.cadBody.off('highlightChanged', this.highlightChanged)
  }
}
