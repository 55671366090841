import { Registry, AssetLoadContext, Xfo } from '@zeainc/zea-engine'
import { CADAsset } from './CADAsset'

/**
 * Represents a view of PMI data. within a CAD assembly.
 *
 * @extends TreeItem
 */
class XRef extends CADAsset {
  /**
   * Creates an instance of XRef setting up the initial configuration for Material and Color parameters.
   *
   * @param {string} name - The name value.
   */
  constructor(name) {
    super(name)
  }

  /**
   * The clone method constructs a new XRef, copies its values
   * from this item and returns it.
   *
   * @param {number} flags - The flags param.
   * @return {XRef} - The return value.
   */
  clone(flags) {
    const cloned = new XRef()
    cloned.copyFrom(this, flags)
    return cloned
  }

  // ///////////////////////////
  // Persistence

  /**
   * Initializes XRef's asset, material, version and layers; adding current `XRef` Geometry Item toall the layers in reader
   *
   * @param {BinReader} reader - The reader param.
   * @param {object} context - The context param.
   */
  readBinary(reader, context) {
    reader.loadStr() // read type
    const name = reader.loadStr() // read name
    this.setName(name)
    let relativePath = reader.loadStr()

    if (context.versions['zea-cad'].compare([3, 6, 2]) > 0) {
      const xfo = new Xfo()
      xfo.tr = reader.loadFloat32Vec3()
      xfo.ori = reader.loadFloat32Quat()
      this.__localXfoParam.loadValue(xfo)
    } else {
      // Note: the SpatialBridge now encodes the 'ReferenceName' into the
      // XRef, while CADEx didn't provide one. Use the name if it is provided.
      if (name == '') this.setName(relativePath)
    }

    if (context.assets[relativePath]) {
      const srcAsset = context.assets[relativePath]
      if (srcAsset.isLoaded()) {
        this.copyFrom(srcAsset, 0)
      } else {
        srcAsset.on('loaded', () => {
          this.copyFrom(srcAsset, 0)
        })
      }
    } else {
      context.assets[relativePath] = this

      if (!context.resources[relativePath]) {
        // Generate a path based on the path of the parent CADAsset.
        // const stem = relativePath.substring(0, relativePath.lastIndexOf('.'))
        // context.resources[relativePath] = context.folder + stem + '.zcad'
        if (relativePath.includes('/')) {
          relativePath = relativePath.slice(relativePath.lastIndexOf('/') + 1)
        } else if (relativePath.includes('\\')) {
          relativePath = relativePath.slice(relativePath.lastIndexOf('\\') + 1)
        }
        if (!context.resources[relativePath]) {
          context.resources[relativePath] = context.folder + relativePath + '.zcad'
        }
      }

      if (context.resources[relativePath]) {
        console.log('resolving XRef:', relativePath, ' > ', context.resources[relativePath])
        const url = context.resources[relativePath]
        context.incrementAsync()

        if (context.xrefs[relativePath]) {
          const xref = context.xrefs[relativePath]
          if (!xref.isLoaded()) {
            xref.on('loaded', () => {
              this.copyFrom(xref)
            })
          } else {
            this.copyFrom(xref)
          }
        } else {
          context.xrefs[relativePath] = this
          this.load(url, new AssetLoadContext(context)).then(
            () => {
              context.decrementAsync()
            },
            (error) => {
              console.log(`While Loading ${this.getPath()} unable to resolve ${relativePath}`)
              context.decrementAsync()
            }
          )
        }
      }
    }
  }
}

Registry.register('XRef', XRef)

export { XRef }
