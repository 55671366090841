/* eslint-disable camelcase */
import { GLShader, shaderLibrary, Registry } from '@zeainc/zea-engine'

/** Class representing a GL draw trim curve fans shader.
 * @extends GLShader
 * @ignore
 */
class GLDrawTrimCurveFansShader extends GLShader {
  /**
   * Create a GL draw trim curve fans shader.
   * @param {any} gl - The gl value.
   */
  constructor(gl) {
    super(gl)

    this.__shaderStages['VERTEX_SHADER'] = shaderLibrary.parseShader(
      'GLDrawTrimCurveFansShader.vertexShader',
      `
precision highp float;

attribute float vertexIds;
instancedattribute vec4 patchCoords;         // instanced attribute..
instancedattribute vec4 data0;     // instanced attribute..
instancedattribute vec4 data1;     // instanced attribute..
instancedattribute vec2 data2;     // instanced attribute..

<%include file="GLSLUtils.glsl"/>
<%include file="GLSLMath.glsl"/>

uniform sampler2D curvesAtlasTexture;
uniform ivec2 curvesAtlasTextureSize;

uniform ivec2 trimSetAtlasTextureSize;

uniform int numCurveVertices;

struct CurveRef {
  int curveId;
  vec2 tr;
  mat2 mat;
  int flags;

  ivec2 addr;
  int numCurveVertices;

};

vec2 getCurveVertex(in CurveRef curveRef, int vertexId) {
    if(curveRef.flags != 0)
      vertexId = curveRef.numCurveVertices - vertexId - 1;
    return curveRef.tr + curveRef.mat * fetchTexel(curvesAtlasTexture, curvesAtlasTextureSize, ivec2(curveRef.addr.x + vertexId, curveRef.addr.y)).rg;
}


uniform sampler2D curvesAtlasLayoutTexture;
uniform ivec2 curvesAtlasLayoutTextureSize;

uniform sampler2D trimSetTexture;
uniform ivec2 trimSetTextureSize;


<%include file="GLSLBinReader.glsl"/>

CurveRef getCurveRef(inout GLSLBinReader trimsetDataReader, in int curveRefStart, inout GLSLBinReader curvesAtlasLayoutDataReader) {

  CurveRef curveRef;

  // Get the Curve Id from the trimSet Atlas
  curveRef.curveId = GLSLBinReader_readInt(trimsetDataReader, trimSetTexture, curveRefStart + 0);

  // Get the Xfo for the curve
  curveRef.tr = vec2(
    GLSLBinReader_readFloat(trimsetDataReader, trimSetTexture, curveRefStart + 1), 
    GLSLBinReader_readFloat(trimsetDataReader, trimSetTexture, curveRefStart + 2)
    );
  curveRef.mat = mat2(
    vec2(
      GLSLBinReader_readFloat(trimsetDataReader, trimSetTexture, curveRefStart + 3), 
      GLSLBinReader_readFloat(trimsetDataReader, trimSetTexture, curveRefStart + 4)
    ),
    vec2(
      GLSLBinReader_readFloat(trimsetDataReader, trimSetTexture, curveRefStart + 5), 
      GLSLBinReader_readFloat(trimsetDataReader, trimSetTexture, curveRefStart + 6)
    ));

  // Get the flags for the curve
  curveRef.flags = GLSLBinReader_readInt(trimsetDataReader, trimSetTexture, curveRefStart + 7);


  curveRef.addr = ivec2(
    GLSLBinReader_readInt(curvesAtlasLayoutDataReader, curvesAtlasLayoutTexture, (curveRef.curveId * 8) + 0), 
    GLSLBinReader_readInt(curvesAtlasLayoutDataReader, curvesAtlasLayoutTexture, (curveRef.curveId * 8) + 1)
    );
  curveRef.numCurveVertices = GLSLBinReader_readInt(curvesAtlasLayoutDataReader, curvesAtlasLayoutTexture, (curveRef.curveId * 8) + 2);

  return curveRef;
}



void main(void) {

  vec2 pos;
  int vertexId = ftoi(vertexIds);
  if(vertexId == 0) {
    pos = (patchCoords.xy + patchCoords.zw * 0.5) / vec2(trimSetAtlasTextureSize);
  }
  else {
    vertexId--;

    CurveRef curveRef;
    curveRef.tr = data0.xy;
    curveRef.mat = mat2(data0.zw, data1.xy);
    curveRef.flags = ftoi(data2.x);

    curveRef.addr = ivec2(ftoi(data1.z), ftoi(data1.w));
    curveRef.numCurveVertices = numCurveVertices;

    //////////////////////////////////////////////
    pos = getCurveVertex(curveRef, vertexId);

    /*
    //////////////////////////////////////////////
    
    int loopStartPos = ftoi(data0.x);
    int curveIndexWithLoop = ftoi(data0.y);

    GLSLBinReader trimsetDataReader;
    GLSLBinReader_init(trimsetDataReader, trimSetTextureSize, 16);
    int numCurves = GLSLBinReader_readInt(trimsetDataReader, trimSetTexture, loopStartPos);

    GLSLBinReader curvesAtlasLayoutDataReader;
    GLSLBinReader_init(curvesAtlasLayoutDataReader, curvesAtlasLayoutTextureSize, 32);

    CurveRef curveRef = getCurveRef(trimsetDataReader, loopStartPos + 1 + (curveIndexWithLoop * 8), curvesAtlasLayoutDataReader);
    pos = getCurveVertex( curveRef, vertexId );


    // Tranform the curve points by the xfo2d to put it into the coords of the trim set.
    Xfo2d xfo2d = Xfo2d(data0.xy, data1.x, data0.zw);
    pos = Xfo2D_transformVec2(xfo2d, pos);


    //////////////////////////////////
    // Due to the reduced precision we use to store our data
    // we get cracks in the trim textures. To fix this we weld
    // the end points of the trim curves here.
    // For each end point of a curve, we find the joining end point 
    // and average their positions.

    if(vertexId == 0) {
      // Lookup the vertex of the previous curve.
      int prevCurveIndexWithinLoop = curveIndexWithLoop - 1;
      if(prevCurveIndexWithinLoop < 0)
        prevCurveIndexWithinLoop += numCurves;
      CurveRef prevCurveRef = getCurveRef(trimsetDataReader, loopStartPos + 1 + (prevCurveIndexWithinLoop * 8), curvesAtlasLayoutDataReader);

      // Get the end of the previous curve.
      vec2 prevCurveEndPos = getCurveVertex( prevCurveRef, prevCurveRef.numCurveVertices-1 );

      pos = (pos + prevCurveEndPos) * 0.5;
    }
    else if(vertexId == numCurveVertices-1) {
      // Lookup the vertex of the next curve.
      int nextCurveIndexWithinLoop = curveIndexWithLoop + 1;
      if(nextCurveIndexWithinLoop >= numCurves)
        nextCurveIndexWithinLoop = 0;
      CurveRef nextCurveRef = getCurveRef(trimsetDataReader, loopStartPos + 1 + (nextCurveIndexWithinLoop * 8), curvesAtlasLayoutDataReader);

      // Get the start of the next curve.
      vec2 nextCurveEndPos = getCurveVertex( nextCurveRef, 0 );
      pos = (pos + nextCurveEndPos) * 0.5;
    }

    */
    //////////////////////////////////////////////


    // Now transform the trim set into the coords of the full texture.
    pos = (patchCoords.xy + (pos * patchCoords.zw));
    pos /= vec2(trimSetAtlasTextureSize);
  }

  // transform the position into clip space.
  gl_Position = vec4(vec2(-1.0, -1.0) + (pos * 2.0), 0.0, 1.0);
}
`
    )

    this.__shaderStages['FRAGMENT_SHADER'] = shaderLibrary.parseShader(
      'GLDrawTrimCurveFansShader.fragmentShader',
      `
precision highp float;

#ifdef ENABLE_ES3
out vec4 fragColor;
#endif

void main(void) {

#ifndef ENABLE_ES3
    vec4 fragColor;
#endif

    fragColor = vec4(1.0/255.0,0.0,0.0,1.0);
    
#ifndef ENABLE_ES3
    gl_FragColor = fragColor;
#endif
}
`
    )
  }
}

Registry.register('GLDrawTrimCurveFansShader', GLDrawTrimCurveFansShader)

export { GLDrawTrimCurveFansShader }
