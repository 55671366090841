import { SystemDesc } from '@zeainc/zea-engine'

const sizeOfBBox = 24

/** Class representing a CAD trim set library.
 * @ignore
 */
class CADTrimSetLibrary {
  /**
   * Create a CAD trim set library.
   */
  constructor() {
    this.__reader = undefined
  }

  /**
   * The setBinaryBuffer method.
   * @param {any} trimSetReader - The trimSetReader param.
   * @param {number} version - The version param.
   */
  setBinaryBuffer(trimSetReader, version) {
    this.__reader = trimSetReader

    this.__numTrimSets = this.__reader.loadUInt32()
    if (version.compare([0, 0, 0]) > 0) {
      this.__totalTrimSurfaceArea = this.__reader.loadFloat32()
    } else {
      this.__totalTrimSurfaceArea = 0.0
      for (let i = 0; i < this.__numTrimSets; i++) {
        const dims = this.getTrimSetDim(i)
        const area = dims[0] * dims[1]
        this.__totalTrimSurfaceArea += area
      }
    }

    const sideLength = Math.sqrt(this.__totalTrimSurfaceArea)

    const maxTexSize = SystemDesc.gpuDesc.maxTextureSize
    // I'm not sure whats going on here.
    // The xlarge size should be at most maxTexSize >> 1 (e.g. 50% wasted space.)
    const xlargeTexSize = maxTexSize >> 1
    const largeTexSize = maxTexSize >> 2
    const medTexSize = maxTexSize >> 3
    const smallTexSize = maxTexSize >> 4

    // Note: on big scenes like the Spyder, the texels often fail
    // to pack when tex size is too small, causing artifacts in trimming.
    // Therefore the biggest texture size is half the maximum.
    this.__texelSizes = [
      sideLength / smallTexSize,
      sideLength / medTexSize,
      sideLength / largeTexSize,
      sideLength / xlargeTexSize,
    ]
    // console.log("sideLength:", sideLength, this.__texelSizes);

    ////////////////////////////
    // Debugging.
    // for (let i = 0; i < this.__numTrimSets; i++) {
    //   console.log({
    //     dims: this.getTrimSetDim(i),
    //     curves: this.getTrimSetCurves(i)
    //   })
    // }
  }

  /**
   * The getBinaryBuffer method.
   * @return {any} - The return value.
   */
  getBinaryBuffer() {
    if (!this.__reader) return null
    return this.__reader.data
  }

  /**
   * The getNumTrimSets method.
   * @return {any} - The return value.
   */
  getNumTrimSets() {
    return this.__numTrimSets
  }

  /**
   * The getTrimArea method.
   * @return {any} - The return value.
   */
  getTrimArea() {
    return this.__totalTrimSurfaceArea
  }

  /**
   * The getTexelSize method.
   * @param {any} lod - The lod param.
   * @param {any} numAssets - The numAssets param.
   * @return {any} - The return value.
   */
  getTexelSize(lod, numAssets) {
    // return 5
    // For scenes with many assets we drop down the texel detail
    // so they load without destroying the GPU.
    let lodId
    if (numAssets < 2) lodId = 3
    else if (numAssets < 6) lodId = 2
    else {
      lodId = 1 // LOD 0 is just a mess.
    }
    // lodId = 1

    // return 4
    return this.__texelSizes[lodId]
    // return this.__texelSizes[Math.clamp(lod, 0, this.__texelSizes.length-1)];
  }

  /**
   * The getTrimSetDim method.
   * @param {any} trimSetId - The trimSetId param.
   * @return {any} - The return value.
   */
  getTrimSetDim(trimSetId) {
    this.__reader.seek(8 + trimSetId * 4)
    this.__reader.seek(this.__reader.loadUInt32())

    const size_x = this.__reader.loadFloat32() // size in scene units
    const size_y = this.__reader.loadFloat32() // size in scene units
    return [size_x, size_y]
  }

  /**
   * The getTrimSetCurves method.
   * @param {any} trimSetId - The trimSetId param.
   * @return {any} - The return value.
   */
  getTrimSetCurves(trimSetId) {
    this.__reader.seek(8 + trimSetId * 4)
    this.__reader.seek(this.__reader.loadUInt32())

    const size_x = this.__reader.loadFloat32() // size in scene units
    const size_y = this.__reader.loadFloat32() // size in scene units
    const numHoles = this.__reader.loadUInt32()
    const numPermiterCurves = this.__reader.loadUInt32()
    const loadCurveRef = () => {
      return {
        id: this.__reader.loadFloat32(),
        xfo_tr: [this.__reader.loadFloat32(), this.__reader.loadFloat32()],
        xfo_rot: [
          this.__reader.loadFloat32(),
          this.__reader.loadFloat32(),
          this.__reader.loadFloat32(),
          this.__reader.loadFloat32(),
        ],
        flags: this.__reader.loadFloat32(),
      }
    }
    const perimeter = []
    for (let i = 0; i < numPermiterCurves; i++) {
      perimeter.push(loadCurveRef())
    }
    const holes = []
    for (let i = 0; i < numHoles; i++) {
      const hole = []
      const numHoleCurves = this.__reader.loadUInt32()
      for (let i = 0; i < numHoleCurves; i++) {
        hole.push(loadCurveRef())
      }
      holes.push(hole)
    }
    return {
      size: [size_x, size_y],
      perimeter,
      holes,
    }
  }

  /**
   * The dumpDebugTrimSets method.
   */
  dumpDebugTrimSets() {
    const trimSetsData = []
    for (let i = 0; i < this.__numTrimSets; i++) {
      try {
        trimSetsData.push({
          dims: this.getTrimSetDim(i),
          curves: this.getTrimSetCurves(i),
        })
      } catch (e) {
        console.warn('Error accessing TrimSet: ', i, e)
        trimSetsData.push({})
      }
    }
    return trimSetsData
  }
}

export { CADTrimSetLibrary }
