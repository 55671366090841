import { libsRegistry } from '@zeainc/zea-engine'

import pkg from '../package.json'
if (libsRegistry) {
  libsRegistry.registerLib(pkg)
} else {
  console.warn(
    "The version of the Zea Engine that you're using doesn't support the libraries registry. Please upgrade to the latest Zea Engine version."
  )
}

export * from './CADConstants.js'
// export * from './CADSurfaceLibrary.js'
export * from './CADAsset.js'
export * from './CADAssembly.js'
export * from './CADPart.js'
export * from './CADBody.js'
export * from './CADPart.js'
export * from './PMIItem.js'
export * from './PMIView.js'
export * from './XRef.js'

export * from './GLCADPass.js'
