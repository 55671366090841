import { Vec2, Vec3, Vec4, Box2, Quat, Color, Plane, Material, BinReader } from '@zeainc/zea-engine'
import { Hull } from './Hull.js'
import {
  geomLibraryHeaderSize,
  valuesPerCurveTocItem,
  valuesPerSurfaceTocItem,
  SURFACE_FLAG_PERIODIC_U,
  SURFACE_FLAG_PERIODIC_V,
  CADCurveTypes,
  CADSurfaceTypes,
  getCurveTypeName,
  getSurfaceTypeName,
} from './CADConstants.js'

/** Class representing a CAD surface library.
 * @ignore
 */
class CADSurfaceLibrary {
  /**
   * Create a CAD surface library.
   * @param {any} cadAsset - The cadAsset value.
   * @param {any} trimSetLibrary - The trimSetLibrary value.
   */
  constructor(cadAsset, trimSetLibrary) {
    this.__cadAsset = cadAsset
    this.__trimSetLibrary = trimSetLibrary
    this.__curveLibraryBuffer = undefined
    this.__meshes = []
    this.__hulls = []
    this.__formFactors = {}

    this.__maxNumKnots = 0
  }

  /**
   * The setBinaryBuffers method.
   * @param {any} curveLibraryBuffer - The curveLibraryBuffer param.
   * @param {any} surfaceLibraryBuffer - The surfaceLibraryBuffer param.
   * @param {number} version - The version param.
   */
  setBinaryBuffers(curveLibraryBuffer, surfaceLibraryBuffer, cadDataVersion) {
    this.__surfaceLibraryBuffer = surfaceLibraryBuffer
    this.cadDataVersion = cadDataVersion
    this.__surfaceLibraryReader = new BinReader(this.__surfaceLibraryBuffer)

    this.__surfaceLibrarySize = Math.sqrt(surfaceLibraryBuffer.byteLength / 8) // RGBA16 pixels
    this.__numSurfaces = this.__surfaceLibraryReader.loadUInt32()
    this.__totalSurfaceArea = this.__surfaceLibraryReader.loadFloat32()

    // this.__totalSurfaceCost = this.__surfaceLibraryReader.loadFloat32();

    // for (let i = 0; i < this.__numCurves; i++) {
    //   const dims = this.getCurveDims(i);
    //   console.log(this.getCurveTypeLabel(i), " length:", dims.length, " curvature:", dims.curvature);
    // }
    // for (let i = 0; i < this.__numSurfaces; i++) {
    //   const dims = this.getSurfaceDims(i);
    //   const area = dims.sizeU * dims.sizeV;

    //   console.log(this.getSurfaceTypeLabel(i), " sizeU:", dims.sizeU, " sizeV:", dims.sizeV, " curvatureU:", dims.curvatureU, " curvatureV:", dims.curvatureV);
    // }

    // if (this.__totalSurfaceArea == 0.0) {
    //   this.__totalSurfaceArea == 0.0;
    //   this.__totalSurfaceCost = 0.0;
    // for (let i = 0; i < this.__numSurfaces; i++) {
    //   const dims = this.getSurfaceDims(i);
    //   const area = dims.sizeU * dims.sizeV;

    //   console.log(this.getSurfaceTypeLabel(i), " sizeU:", dims.sizeU, " sizeV:", dims.sizeV, " curvatureU:", dims.curvatureU, " curvatureV:", dims.curvatureV);
    //   // this.__totalSurfaceArea += area;
    //   // this.__totalSurfaceCost += (1.0 + (dims.sizeU * dims.curvatureU)) * (1.0 + (dims.sizeV * dims.curvatureV));
    // }
    // }
    // console.log(this.__totalSurfaceCost);

    // this.__triCounts = [
    //   10,
    //   20,
    //   40,
    //   80,
    // ]

    if (this.__totalSurfaceArea == 0.0) {
      for (let i = 0; i < this.__numSurfaces; i++) {
        const dims = this.getSurfaceDims(i)
        const area = dims.sizeU * dims.sizeV
        this.__totalSurfaceArea += area
      }
    }

    this.__curveLibraryBuffer = curveLibraryBuffer
    this.__curveLibraryReader = new BinReader(this.__curveLibraryBuffer)
    this.__curveLibrarySize = Math.sqrt(curveLibraryBuffer.byteLength / 8) // RGBA16 pixels
    this.__numCurves = this.__curveLibraryReader.loadUInt32()

    // for (let i = 0; i < this.__numSurfaces; i++) {
    //   console.log(this.getSurfaceData(i, false));
    // }
    // for (let i = 0; i < this.__numCurves; i++) {
    //   console.log(this.getCurveData(i));
    // }
  }

  /**
   * The getCurveBuffer method.
   * @return {any} - The return value.
   */
  getCurveBuffer() {
    return this.__curveLibraryBuffer
  }

  /**
   * The getSurfaceBuffer method.
   * @return {any} - The return value.
   */
  getSurfaceBuffer() {
    return this.__surfaceLibraryBuffer
  }

  /**
   * The getNumSurfaces method.
   * @return {any} - The return value.
   */
  getNumSurfaces() {
    return this.__numSurfaces
  }

  /**
   * The getNumCurves method.
   * @return {any} - The return value.
   */
  getNumCurves() {
    return this.__numCurves
  }

  /**
   * The getDetailFactor method.
   * @param {any} lod - The lod param.
   * @return {any} - The return value.
   */
  getDetailFactor(lod) {
    // Given a target poly count, calculate the detail factor given the total surface cost.
    // const targetQuadCount = this.__triCounts[Math.clamp(0, lod, this.__triCounts.length-1)] * 1000;
    // return targetQuadCount / this.__totalSurfaceCost;
    const mult = Math.pow(2, lod)
    return mult * this.__cadAsset.curvatureToDetail
  }

  /**
   * The getCurveDataTexelCoords method.
   * @param {any} curveId - The curveId param.
   * @return {any} - The return value.
   */
  getCurveDataTexelCoords(curveId) {
    this.__curveLibraryReader.seek(geomLibraryHeaderSize + curveId * (valuesPerCurveTocItem * 2) /* bpc*/)
    const x = this.__curveLibraryReader.loadFloat16()
    const y = this.__curveLibraryReader.loadFloat16()
    return {
      x,
      y,
    }
  }

  /**
   * The __seekCurveData method.
   * @param {any} curveId - The curveId param.
   * @param {number} offsetInBytes - The offsetInBytes param.
   * @private
   */
  __seekCurveData(curveId, offsetInBytes = 0) {
    const addr = this.getCurveDataTexelCoords(curveId)
    // X, Y in pixels.

    const bytesPerPixel = 8 // RGBA16 pixel
    const byteOffset = addr.x * bytesPerPixel + addr.y * bytesPerPixel * this.__curveLibrarySize
    // console.log("__seekSurfaceData:" + curveId + " byteOffset:" + (byteOffset +offset) + " pixel:" + ((byteOffset +offset)/8) + " x:" + addr.x + " y:" + addr.y);
    this.__curveLibraryReader.seek(byteOffset + offsetInBytes)
  }

  /**
   * The getCurveType method.
   * @param {any} curveId - The curveId param.
   * @return {any} - The return value.
   */
  getCurveType(curveId) {
    this.__seekCurveData(curveId)
    const curveType = this.__curveLibraryReader.loadFloat16()
    return curveType
  }

  /**
   * The getCurveTypeLabel method.
   * @param {any} curveId - The curveId param.
   * @return {any} - The return value.
   */
  getCurveTypeLabel(curveId) {
    const curveType = this.getCurveType(curveId)
    return getCurveTypeName(curveType)
  }

  /**
   * The getCurveTypeLabel method.
   * @param {any} curveId - The curveId param.
   * @return {any} - The return value.
   */
  getCurveDims(curveId) {
    this.__curveLibraryReader.seek(geomLibraryHeaderSize + curveId * (valuesPerCurveTocItem * 2) /* bpc*/)

    return {
      addrX: this.__curveLibraryReader.loadFloat16(),
      addrY: this.__curveLibraryReader.loadFloat16(),
      curvature: this.__curveLibraryReader.loadFloat16(),
      length: this.__curveLibraryReader.loadFloat16(),
      flags: this.__curveLibraryReader.loadFloat16(),
    }
  }

  /**
   * The getCurveTypeLabel method.
   * @param {any} curveId - The curveId param.
   * @return {any} - The return value.
   */
  getCurveData(curveId) {
    const dims = this.getCurveDims(curveId)
    const curveType = this.getCurveType(curveId)
    const domain = new Vec2(this.__curveLibraryReader.loadFloat16(), this.__curveLibraryReader.loadFloat16())

    switch (curveType) {
      case CADCurveTypes.CURVE_TYPE_LINE: {
        return {
          curveId,
          dims,
          curveType: 'CURVE_TYPE_LINE',
          domain,
        }
        break
      }
      case CADCurveTypes.CURVE_TYPE_CIRCLE: {
        const radius = this.__curveLibraryReader.loadFloat16()
        return {
          curveId,
          dims,
          curveType: 'CURVE_TYPE_CIRCLE',
          domain,
          radius,
        }
        break
      }
      case CADCurveTypes.CURVE_TYPE_ELIPSE: {
        const majorRadius = this.__curveLibraryReader.loadFloat16()
        const minorRadius = this.__curveLibraryReader.loadFloat16()
        return {
          curveId,
          dims,
          curveType: 'SURFACE_TYPE_CYLINDER',
          domain,
          majorRadius,
          minorRadius,
        }
        break
      }
      case CADCurveTypes.CURVE_TYPE_NURBS_CURVE: {
        const degree = this.__curveLibraryReader.loadFloat16()
        const numCPs = this.__curveLibraryReader.loadFloat16()
        const numKnots = this.__curveLibraryReader.loadFloat16()
        this.__curveLibraryReader.advance(4)

        const controlPoints = []
        for (let j = 0; j < numCPs; j++) {
          const p = new Vec4(
            this.__curveLibraryReader.loadFloat16(),
            this.__curveLibraryReader.loadFloat16(),
            this.__curveLibraryReader.loadFloat16(),
            this.__curveLibraryReader.loadFloat16()
          )
          controlPoints.push(p)
        }
        const knots = []
        for (let j = 0; j < numKnots; j++) {
          knots.push(this.__curveLibraryReader.loadFloat16())
        }
        return {
          curveId,
          dims,
          curveType: 'CURVE_TYPE_NURBS_CURVE',
          domain,
          degree,
          numCPs,
          controlPoints,
          knots,
        }
      }
      default:
        console.warn('Invalid Curve Type:', curveType)
    }
  }

  /**
   * The getSurfaceDataTexelCoords method.
   * @param {any} surfaceId - The surfaceId param.
   * @return {any} - The return value.
   */
  getSurfaceDataTexelCoords(surfaceId) {
    this.__surfaceLibraryReader.seek(geomLibraryHeaderSize + surfaceId * (valuesPerSurfaceTocItem * 2) /* bpc*/)
    const x = this.__surfaceLibraryReader.loadUFloat16()
    const y = this.__surfaceLibraryReader.loadUFloat16()
    return {
      x,
      y,
    }
  }

  /**
   * The __seekSurfaceData method.
   * @param {any} surfaceId - The surfaceId param.
   * @param {number} offsetInBytes - The offsetInBytes param.
   * @private
   */
  __seekSurfaceData(surfaceId, offsetInBytes = 0) {
    const addr = this.getSurfaceDataTexelCoords(surfaceId)
    // X, Y in pixels.

    const bytesPerPixel = 8 // RGBA16 pixel
    const byteOffset = addr.x * bytesPerPixel + addr.y * bytesPerPixel * this.__surfaceLibrarySize
    // console.log("__seekSurfaceData:" + surfaceId + " byteOffset:" + (byteOffset +offset) + " pixel:" + ((byteOffset +offset)/8) + " x:" + addr.x + " y:" + addr.y);
    this.__surfaceLibraryReader.seek(byteOffset + offsetInBytes)
  }

  /**
   * The getSurfaceType method.
   * @param {any} surfaceId - The surfaceId param.
   * @return {any} - The return value.
   */
  getSurfaceType(surfaceId) {
    this.__seekSurfaceData(surfaceId)
    const surfaceType = this.__surfaceLibraryReader.loadFloat16()
    return surfaceType
  }

  /**
   * The getSurfaceTypeLabel method.
   * @param {any} surfaceId - The surfaceId param.
   * @return {any} - The return value.
   */
  getSurfaceTypeLabel(surfaceId) {
    const surfaceType = this.getSurfaceType(surfaceId)
    return getSurfaceTypeName(surfaceType)
  }

  /**
   * The getSurfaceDims method.
   * @param {any} surfaceId - The surfaceId param.
   * @return {any} - The return value.
   */
  getSurfaceDims(surfaceId) {
    this.__surfaceLibraryReader.seek(geomLibraryHeaderSize + surfaceId * (valuesPerSurfaceTocItem * 2) /* bpc*/)

    const loadTrimSetId = () => {
      if (this.cadDataVersion.compare([0, 0, 27]) < 0) {
        // Note: -1 is a valid value for trimset id, so can't use an unsigned float value.
        const partA = this.__surfaceLibraryReader.loadFloat16()
        const partB = this.__surfaceLibraryReader.loadFloat16()
        return partA + (partB << 8)
      } else {
        return this.__surfaceLibraryReader.loadSInt32From2xFloat16()
      }
    }
    return {
      addrX: this.__surfaceLibraryReader.loadUFloat16(),
      addrY: this.__surfaceLibraryReader.loadUFloat16(),
      curvatureU: this.__surfaceLibraryReader.loadFloat16(),
      curvatureV: this.__surfaceLibraryReader.loadFloat16(),
      sizeU: this.__surfaceLibraryReader.loadFloat16(), // size U
      sizeV: this.__surfaceLibraryReader.loadFloat16(), // size V
      flags: this.__surfaceLibraryReader.loadFloat16(),
      trimSetId: loadTrimSetId(), // trimSetId
    }
  }

  /**
   * The getSurfaceData method.
   * @param {any} surfaceId - The surfaceId param.
   * @return {any} - The return value.
   */
  getSurfaceData(surfaceId, includeTrimSet = true) {
    const dims = this.getSurfaceDims(surfaceId)

    const surfaceType = this.getSurfaceType(surfaceId)
    const readDomain = () => {
      const domain = new Box2()
      domain.p0.x = this.__surfaceLibraryReader.loadFloat16()
      domain.p0.y = this.__surfaceLibraryReader.loadFloat16()
      domain.p1.x = this.__surfaceLibraryReader.loadFloat16()
      domain.p1.y = this.__surfaceLibraryReader.loadFloat16()
      return domain
    }
    if (dims.trimSetId >= 0 && includeTrimSet) dims.trimSet = this.__trimSetLibrary.getTrimSetCurves(dims.trimSetId)

    switch (surfaceType) {
      case CADSurfaceTypes.SURFACE_TYPE_PLANE: {
        const domain = readDomain()
        return {
          surfaceId,
          dims,
          surfaceType: 'SURFACE_TYPE_PLANE',
          domain,
        }
        break
      }
      case CADSurfaceTypes.SURFACE_TYPE_FAN: {
        const domain = readDomain()
        const points = []
        const numPoints = dims.curvatureU + 1
        for (let j = 0; j < numPoints; j++) {
          const p = new Vec2(this.__surfaceLibraryReader.loadFloat16(), this.__surfaceLibraryReader.loadFloat16())
          points.push(p)
        }
        return {
          surfaceId,
          dims,
          surfaceType: 'SURFACE_TYPE_FAN',
          domain,
          points,
        }
        break
      }
      case CADSurfaceTypes.SURFACE_TYPE_CONE: {
        const domain = readDomain()
        const radius = this.__surfaceLibraryReader.loadFloat16()
        const semiAngle = this.__surfaceLibraryReader.loadFloat16()
        return {
          surfaceId,
          dims,
          surfaceType: 'SURFACE_TYPE_CONE',
          domain,
          radius,
          semiAngle,
        }
        break
      }
      case CADSurfaceTypes.SURFACE_TYPE_CYLINDER: {
        const domain = readDomain()
        const radius = this.__surfaceLibraryReader.loadFloat16()
        return {
          surfaceId,
          dims,
          surfaceType: 'SURFACE_TYPE_CYLINDER',
          domain,
          radius,
        }
        break
      }
      case CADSurfaceTypes.SURFACE_TYPE_SPHERE: {
        const domain = readDomain()
        const radius = this.__surfaceLibraryReader.loadFloat16()
        return {
          surfaceId,
          dims,
          surfaceType: 'SURFACE_TYPE_SPHERE',
          domain,
          radius,
        }
      }
      case CADSurfaceTypes.SURFACE_TYPE_TORUS: {
        const domain = readDomain()
        const majorRadius = this.__surfaceLibraryReader.loadFloat16()
        const minorRadius = this.__surfaceLibraryReader.loadFloat16()
        return {
          surfaceId,
          dims,
          surfaceType: 'SURFACE_TYPE_TORUS',
          domain,
          majorRadius,
          minorRadius,
        }
      }
      case CADSurfaceTypes.SURFACE_TYPE_LINEAR_EXTRUSION: {
        const domain = readDomain()

        let curveIndex
        // if (this.cadDataVersion.compare([0, 0, 27]) < 0) {
        //   // Note: -1 is a valid value for trimset id, so can't use an unsigned float value.
        //   const partA = this.__surfaceLibraryReader.loadFloat16()
        //   const partB = this.__surfaceLibraryReader.loadFloat16()
        //   curveIndex = partA + (partB << 8)
        // } else {
        // curveIndex = this.__surfaceLibraryReader.loadUInt32From2xUFloat16()

        const partA = this.__surfaceLibraryReader.loadUFloat16()
        const partB = this.__surfaceLibraryReader.loadUFloat16()
        curveIndex = partA + partB * 2048
        // }

        const curveData = this.getCurveData(curveIndex)

        const curve_tr = new Vec3(
          this.__surfaceLibraryReader.loadFloat16(),
          this.__surfaceLibraryReader.loadFloat16(),
          this.__surfaceLibraryReader.loadFloat16()
        )
        const curve_ori = new Quat(
          this.__surfaceLibraryReader.loadFloat16(),
          this.__surfaceLibraryReader.loadFloat16(),
          this.__surfaceLibraryReader.loadFloat16(),
          this.__surfaceLibraryReader.loadFloat16()
        )

        return {
          surfaceId,
          dims,
          surfaceType: 'SURFACE_TYPE_LINEAR_EXTRUSION',
          domain,
          curve_tr,
          curve_ori,
          curveData,
          partA,
          partB,
        }
      }
      case CADSurfaceTypes.SURFACE_TYPE_REVOLUTION_FLIPPED_DOMAIN:
      case CADSurfaceTypes.SURFACE_TYPE_REVOLUTION: {
        const domain = readDomain()

        let curveIndex
        if (this.cadDataVersion.compare([0, 0, 27]) < 0) {
          // Note: -1 is a valid value for trimset id, so can't use an unsigned float value.
          const partA = this.__surfaceLibraryReader.loadFloat16()
          const partB = this.__surfaceLibraryReader.loadFloat16()
          curveIndex = partA + (partB << 8)
        } else {
          curveIndex = this.__surfaceLibraryReader.loadUInt32From2xUFloat16()
        }

        const curve_tr = new Vec3(
          this.__surfaceLibraryReader.loadFloat16(),
          this.__surfaceLibraryReader.loadFloat16(),
          this.__surfaceLibraryReader.loadFloat16()
        )
        const curve_ori = new Quat(
          this.__surfaceLibraryReader.loadFloat16(),
          this.__surfaceLibraryReader.loadFloat16(),
          this.__surfaceLibraryReader.loadFloat16(),
          this.__surfaceLibraryReader.loadFloat16()
        )

        const curveData = this.getCurveData(curveIndex)

        return {
          surfaceId,
          dims,
          surfaceType: 'SURFACE_TYPE_REVOLUTION',
          domain,
          curve_tr,
          curve_ori,
          curveData,
        }
      }
      case CADSurfaceTypes.SURFACE_TYPE_NURBS_SURFACE: {
        const domain = readDomain()
        const degreeU = this.__surfaceLibraryReader.loadFloat16()
        const degreeV = this.__surfaceLibraryReader.loadFloat16()
        const numCPsU = this.__surfaceLibraryReader.loadFloat16()

        const numCPsV = this.__surfaceLibraryReader.loadFloat16()
        const numKnotsU = this.__surfaceLibraryReader.loadFloat16()
        const numKnotsV = this.__surfaceLibraryReader.loadFloat16()
        const flags = this.__surfaceLibraryReader.loadFloat16()
        const periodicU = (flags & SURFACE_FLAG_PERIODIC_U) != 0
        const periodicV = (flags & SURFACE_FLAG_PERIODIC_V) != 0
        // this.__surfaceLibraryReader.advance(2);

        const controlPoints = []
        for (let j = 0; j < numCPsU * numCPsV; j++) {
          const p = new Vec4(
            this.__surfaceLibraryReader.loadFloat16(),
            this.__surfaceLibraryReader.loadFloat16(),
            this.__surfaceLibraryReader.loadFloat16(),
            this.__surfaceLibraryReader.loadFloat16()
          )
          controlPoints.push(p)
        }
        const knotsU = []
        for (let j = 0; j < numKnotsU; j++) {
          knotsU.push(this.__surfaceLibraryReader.loadFloat16())
        }
        const knotsV = []
        for (let j = 0; j < numKnotsV; j++) {
          knotsV.push(this.__surfaceLibraryReader.loadFloat16())
        }
        return {
          surfaceId,
          dims,
          surfaceType: 'SURFACE_TYPE_NURBS_SURFACE',
          domain,
          periodicU,
          periodicV,
          degreeU,
          degreeV,
          numCPsU,
          numCPsV,
          controlPoints,
          knotsU,
          knotsV,
        }
      }
      case CADSurfaceTypes.SURFACE_TYPE_POLY_PLANE: {
        const p0 = this.__surfaceLibraryReader.loadFloat16Vec2()
        const p1 = this.__surfaceLibraryReader.loadFloat16Vec2()
        const p2 = this.__surfaceLibraryReader.loadFloat16Vec2()
        const p3 = this.__surfaceLibraryReader.loadFloat16Vec2()
        return {
          surfaceId,
          dims,
          surfaceType: 'SURFACE_TYPE_POLY_PLANE',
          points: [p0, p1, p2, p3],
        }
      }
      default: {
        const surfaceType = this.getSurfaceType(surfaceId)
        console.warn('Invalid Surface Type:', surfaceType, ' surfaceId:', surfaceId)
      }
    }
  }

  /** ************************************************************
   *  NURBS Utils
   **************************************************************/

  /**
   * Finds knot vector span.
   * @param {number} u - Parametric value.
   * @param {number} degree - Degree.
   * @param {array} knots - Knot vector.
   * @param {array} knotValues - The knotValues param.
   * @return {number} - Returns the span.
   */
  findSpan(u, degree, knots, knotValues) {
    if (this.cadDataVersion.compare([0, 0, 6]) >= 0) {
      this.cadDataVersion
      // EXPORT_KNOTS_AS_DELTAS

      let nextKnot = knots[0]
      let knot = nextKnot

      let span = 1
      const n = knots.length - degree - 1
      // Linear Search...
      for (; span < n; span++) {
        nextKnot += knots[span]
        if (span > degree && u < nextKnot) {
          span--
          break
        }
        knot = nextKnot
      }
      if (span == n) {
        span--
      }

      // Calculate knot values
      knotValues[degree] = knot
      let left = knot
      let right = knot
      for (let i = 1; i <= degree; i++) {
        left -= knots[span - i + 1]
        right += knots[span + i]
        knotValues[degree - i] = left
        knotValues[degree + i] = right
      }
      return span
    } else {
    }
  }

  /**
   * Calculate basis functions.
   * See The NURBS Book, page 70, algorithm A2.2
   * span : span in which u lies
   * @param {any} u - Parametric point.
   * @param {any} degree - Degree.
   * @param {any} knots - Knot vector.
   * @param {any} bvD - The bvD param.
   * @return {any} - Returns array[degree+1] with basis functions values.
   */
  calcBasisValues(u, degree, knots, bvD) {
    const left = []
    const right = []
    // Basis[0] is always 1.0
    const basisValues = [1.0]
    bvD[0] = 0.0

    for (let j = 1; j <= degree; ++j) {
      left[j] = u - knots[degree + 1 - j]
      right[j] = knots[degree + j] - u

      let saved = 0.0
      for (let r = 0; r < j; ++r) {
        const rv = right[r + 1]
        const lv = left[j - r]
        const temp = basisValues[r] / (rv + lv)
        basisValues[r] = saved + rv * temp
        saved = lv * temp
      }

      basisValues[j] = saved

      // Calculate N' if on second to last iteration
      if (j == degree - 1 || degree == 1) {
        saved = 0.0
        // Loop through all basis values
        for (let r = 0; r < degree; r++) {
          // Calculate a temp variable
          const jr_z = r + 1
          // Calculate right side
          const kp_0 = knots[jr_z + degree]
          const kp_1 = knots[jr_z]
          const tmp = (degree * basisValues[r]) / (kp_0 - kp_1)
          // Calculate derivative value
          bvD[r] = saved - tmp
          // Swap right side to left
          saved = tmp
        }
        // Save the last der-basis
        bvD[degree] = saved
      }
    }

    return basisValues
  }

  /**
   * Calculate basis function derivativess.
   * See The NURBS Book, page 70, algorithm A2.2
   * span : span in which u lies
   * https://github.com/pradeep-pyro/tinynurbs/blob/master/include/tinynurbs/core/basis.h#L163
   * @param {any} u - Parametric point.
   * @param {any} degree - Degree.
   * @param {any} knots - Knot vector.
   * @return {any} - Returns array[degree+1] with basis function derivative values.
   */
  calcBasisDerivatives(u, degree, knots) {
    const left = []
    const right = []
    let saved = 0.0
    let temp = 0.0

    const ndu = []
    for (let j = 0; j <= degree; j++) {
      ndu.push([])
    }
    ndu[0][0] = 1.0

    for (let j = 1; j <= degree; j++) {
      left[j] = u - knots[degree + 1 - j]
      right[j] = knots[degree + j] - u
      saved = 0.0

      for (let r = 0; r < j; r++) {
        const rv = right[r + 1]
        const lv = left[j - r]
        const rvlv = rv + lv

        // Lower triangle
        ndu[j][r] = rvlv
        temp = ndu[r][j - 1] / rvlv
        // Upper triangle
        ndu[r][j] = saved + rv * temp
        saved = lv * temp
      }

      ndu[j][j] = saved
    }

    const ders = [[], []]
    for (let j = 0; j <= degree; j++) {
      ders[0][j] = ndu[j][degree]
    }

    const a = [[], []]
    for (let j = 0; j <= degree; j++) {
      a[0].push(0)
      a[1].push(0)
    }

    for (let r = 0; r <= degree; r++) {
      let s1 = 0
      let s2 = 1
      a[0][0] = 1.0

      // for (int k = 1; k <= 1; k++)
      {
        const k = 1
        let d = 0.0
        const rk = r - k
        const pk = degree - k
        let j1 = 0
        let j2 = 0

        if (r >= k) {
          a[s2][0] = a[s1][0] / ndu[pk + 1][rk]
          d = a[s2][0] * ndu[rk][pk]
        }

        if (rk >= -1) {
          j1 = 1
        } else {
          j1 = -rk
        }

        if (r - 1 <= pk) {
          j2 = k - 1
        } else {
          j2 = degree - r
        }

        for (let j = j1; j <= j2; j++) {
          a[s2][j] = (a[s1][j] - a[s1][j - 1]) / ndu[pk + 1][rk + j]
          d += a[s2][j] * ndu[rk + j][pk]
        }

        if (r <= pk) {
          a[s2][k] = -a[s1][k - 1] / ndu[pk + 1][r]
          d += a[s2][k] * ndu[r][pk]
        }

        ders[k][r] = d

        const temp = s1
        s1 = s2
        s2 = temp
      }
    }

    let fac = degree
    // for (int k = 1; k <= 1; k++)
    {
      const k = 1
      for (let j = 0; j <= degree; j++) {
        ders[k][j] = ders[k][j] * fac
      }
      fac *= degree - k
    }

    return ders
  }

  // http://www.nar-associates.com/nurbs/programs/dbasisu.c
  /* Subroutine to generate B-spline basis functions and their derivatives for uniform open knot vectors. */

  /**
   * Calculate rational B-Spline surface point.
   * See The NURBS Book, page 134, algorithm A4.3
   *
   * p1, p2 : degrees of B-Spline surface
   * U1, U2 : knot vectors
   * P      : control points (x, y, z, w)
   * u, v   : parametric values
   *
   * returns point for given (u, v)
   *
   * @param {any} surfaceData - The surfaceData param.
   * @param {any} params - The params param.
   * @return {any} - The return value.
   */
  calcSurfacePoint(surfaceData, params) {
    const d = surfaceData

    const u = Math.remap(params[0], 0, 1, d.domain.p0.x, d.domain.p1.x)
    const v = Math.remap(params[1], 0, 1, d.domain.p0.y, d.domain.p1.y)

    const knotValuesU = []
    const spanU = this.findSpan(u, d.degreeU, d.knotsU, knotValuesU)
    const knotValuesV = []
    const spanV = this.findSpan(v, d.degreeV, d.knotsV, knotValuesV)

    const bvdsU = []
    const basisValuesU = this.calcBasisValues(u, d.degreeU, knotValuesU, bvdsU)
    const bvdsV = []
    const basisValuesV = this.calcBasisValues(v, d.degreeV, knotValuesV, bvdsV)

    // const dersU = this.calcBasisDerivatives(u, d.degreeU, knotValuesU)
    // const dersV = this.calcBasisDerivatives(v, d.degreeV, knotValuesV)
    // const basisValuesU = dersU[0]
    // const basisValuesV = dersV[0]

    // console.log("knotValuesU:", knotValuesU)
    // console.log("basisValuesU:", basisValuesU)
    // console.log("knotValuesV:", knotValuesV)
    // console.log("basisValuesV:", basisValuesV)
    // }
    // else {

    // }

    const pos = new Vec3(0, 0, 0)
    const tangentU = new Vec3(0, 0, 0)
    const tangentV = new Vec3(0, 0, 0)
    let w = 0.0
    const cvU0 = spanU - d.degreeU
    const cvV0 = spanV - d.degreeV
    for (let y = 0; y <= d.degreeV; ++y) {
      // let vindex = (spanV - d.degreeV + y) % d.numCPsV;
      const vindex = cvV0 + y
      for (let x = 0; x <= d.degreeU; ++x) {
        // const uindex = (spanU - d.degreeU + x) % d.numCPsU;
        const uindex = cvU0 + x

        const pt = d.controlPoints[uindex + vindex * d.numCPsU]
        const weight = pt.t

        const bvU = basisValuesU[x]
        const bvV = basisValuesV[y]
        // const bvU = dersU[0][x]
        // const bvV = dersV[0][y]

        const bvw = weight * bvU * bvV
        pos.addInPlace(pt.scale(bvw))
        w += bvw

        const bvdU = bvdsU[x]
        const bvdV = bvdsV[y]
        // const bvdU = dersU[1][x]
        // const bvdV = dersV[1][y]
        tangentU.addInPlace(pt.scale(bvdU * bvV))
        tangentV.addInPlace(pt.scale(bvU * bvdV))
      }
    }
    if (w == 0 || isNaN(w) || !isFinite(w)) console.warn('Unable to evaluate surface')

    // console.log('spanV:', spanV, ' v:', v, ' w:', w)
    pos.scaleInPlace(1 / w)

    ///////////////////////////////////////////////////////
    // Calculate normal.
    const spanRangeU = knotValuesU[d.degreeU + 1] - knotValuesU[d.degreeU]
    const spanRangeV = knotValuesV[d.degreeV + 1] - knotValuesV[d.degreeV]
    const eqKnotRangeU = (d.domain.p1.x - d.domain.p0.x) / d.knotsU.length
    const eqKnotRangeV = (d.domain.p1.y - d.domain.p0.y) / d.knotsV.length

    // console.log(v, 'spanRangeV:', spanRangeV, ' eqKnotRangeV:', eqKnotRangeV, spanRangeV / eqKnotRangeV)

    // Note: for COOLANT_INLET_PORT_01.ipt_faceWithBlackEdge.
    // this tollerance needed to be quite high. (bigger than 0.005)

    if (spanRangeU / eqKnotRangeU < 0.01) {
      // In some cases (COOLANT_INLET_PORT_01.ipt_faceWithBlackEdge.)
      // we have span segment which has close to zero delta, and
      // so the normals are broken. We want to advace along the
      // e.g. [0, 0, 0, 0.00001, 1, 3, 3, 3]
      // length of the span rather than when we have a pinched corner,
      // where we move along the toher direction.
      // console.log(v, 'spanRangeU:', spanRangeU, ' eqKnotRangeU:', eqKnotRangeU, spanRangeU / eqKnotRangeU)

      let cvU = cvU0
      if (v > d.domain.p1.y - 0.0001) {
        // If at the end then we grab the end of the pevious row.
        cvU = cvU0 + d.degreeU - 2
      } else {
        // if the broken normal is at the start of the U range, then
        // we will grab the next in the row.
        cvU = cvU0 + 1
      }

      const spanLerpV = (u - knotValuesV[d.degreeV]) / spanRangeV
      const cvV = cvV0 + Math.floor(spanLerpV * d.degreeV)

      const pt0 = d.controlPoints[cvU + cvV * d.numCPsU].toVec3()
      const pt1 = d.controlPoints[cvU + 1 + cvV * d.numCPsU].toVec3()

      tangentU.setFromOther(pt1.subtract(pt0))
    } else if (tangentU.length() < 0.05) {
      // The derivative in the V direction is zero,
      // so we calculate the linear derivative for the next control points along.

      let cvV
      if (spanV > d.degreeV) {
        // If at the end then we grab the end of the pevious row.
        cvV = cvV0 + d.degreeV - 2
      } else {
        // if the broken normal is at the start of the V range, then
        // we will grab the next in the row.
        cvV = cvV0 + 1
      }

      const spanLerpU = (u - knotValuesU[d.degreeU]) / spanRangeU
      const cvU = cvU0 + Math.floor(spanLerpU * d.degreeU)

      const pt0 = d.controlPoints[cvU + cvV * d.numCPsU].toVec3()
      const pt1 = d.controlPoints[cvU + 1 + cvV * d.numCPsU].toVec3()

      tangentU.setFromOther(pt1.subtract(pt0))
      // tangentU.setFromOther(pt0.subtract(pt1));
    }

    if (spanRangeV / eqKnotRangeV < 0.01) {
      // In some cases (COOLANT_INLET_PORT_01.ipt_faceWithBlackEdge.)
      // we have span segment which has close to zero delta, and
      // so the normals are broken. We want to advace along the
      // e.g. [0, 0, 0, 0.00001, 1, 3, 3, 3]
      // length of the span rather than when we have a pinched corner,
      // where we move along the toher direction.
      // console.log(v, 'spanRangeV:', spanRangeV, ' eqKnotRangeV:', eqKnotRangeV, spanRangeV / eqKnotRangeV)

      let cvV = cvV0
      if (v > d.domain.p1.y - 0.0001) {
        // If at the end then we grab the end of the pevious row.
        cvV = cvV0 + d.degreeV - 2
      } else {
        // if the broken normal is at the start of the V range, then
        // we will grab the next in the row.
        cvV = cvV0 + 1
      }

      const spanLerpU = (u - knotValuesU[d.degreeU]) / spanRangeU
      const cvU = cvU0 + Math.floor(spanLerpU * d.degreeU)

      const pt0 = d.controlPoints[cvU + cvV * d.numCPsU].toVec3()
      const pt1 = d.controlPoints[cvU + (cvV + 1) * d.numCPsU].toVec3()

      tangentV.setFromOther(pt1.subtract(pt0))
    } else if (tangentV.length() < 0.05) {
      // The derivative in the V direction is zero,
      // so we calculate the linear derivative for the next control points along.

      let cvU = cvU0
      if (v > d.domain.p1.y - 0.0001) {
        // If at the end then we grab the end of the pevious row.
        cvU = cvU0 + d.degreeU - 2
      } else {
        // if the broken normal is at the start of the U range, then
        // we will grab the next in the row.
        cvU = cvU0 + 1
      }

      const spanLerpV = (u - knotValuesV[d.degreeV]) / spanRangeV
      const cvV = cvV0 + Math.floor(spanLerpV * d.degreeV)

      const pt0 = d.controlPoints[cvU + cvV * d.numCPsU].toVec3()
      const pt1 = d.controlPoints[cvU + (cvV + 1) * d.numCPsU].toVec3()

      tangentV.setFromOther(pt1.subtract(pt0))
    }

    const normal = tangentU.cross(tangentV).normalize()

    return {
      pos,
      normal,
    }
  }

  // https://github.com/arennuit/libnurbs/blob/3f7daae483a615a13d21e5c674f412ccb8587b6e/nurbs%2B%2B-3.0.11/nurbs/nurbs.cpp

  /**
   * The generatePolygonSurface method.
   * @param {any} surfaceId - The surfaceId param.
   * @param {number} lod - The lod param.
   * @return {any} - The return value.
   */
  generatePolygonSurface(surfaceId, lod = 0) {
    if (this.__meshes[surfaceId]) {
      // const color = this.__meshes[surfaceId].mat.getParameter('BaseColor').getValue();
      // color.r = color.r + 0.2;
      // console.log("surface Instanced:" + surfaceId + ":" + color.r);
      return this.__meshes[surfaceId]
    }

    if (this.getSurfaceType(surfaceId) != CADSurfaceTypes.SURFACE_TYPE_NURBS_SURFACE) {
      return
    }
    const surfaceData = this.getSurfaceData(surfaceId)
    if (!surfaceData) {
      return
    }
    const M = surfaceData.numCPsU * Math.pow(2, lod)
    const N = surfaceData.numCPsV * Math.pow(2, lod)

    console.log('generatePolygonSurface:' + surfaceId + ' M:' + M + ' N:' + N)

    const quad = new Plane(1.0, 1.0, M, N)
    const normalsGeom = new Lines()
    normalsGeom.setNumVertices((M + 1) * (N + 1) * 2)
    normalsGeom.setNumSegments((M + 1) * (N + 1))
    const normalsGeom_PosAttr = normalsGeom.getVertexAttribute('positions')
    const normalsLength = 0.2

    let voff = 0
    const positions = quad.getVertexAttribute('positions')
    const normals = quad.getVertexAttribute('normals')
    for (let j = 0; j <= N; j++) {
      const v = j / N
      for (let i = 0; i <= M; i++) {
        const u = i / M
        const pt = this.calcSurfacePoint(surfaceData, [u, v])

        positions.getValueRef(voff).set(pt.pos.x, pt.pos.y, pt.pos.z)
        normals.getValueRef(voff).set(pt.normal.x, pt.normal.y, pt.normal.z)

        normalsGeom.setSegmentVertexIndices(voff, voff * 2, voff * 2 + 1)
        // if (v == 0.0)
        {
          normalsGeom_PosAttr.getValueRef(voff * 2).set(pt.pos.x, pt.pos.y, pt.pos.z)
          normalsGeom_PosAttr
            .getValueRef(voff * 2 + 1)
            .set(
              pt.pos.x + pt.normal.x * normalsLength,
              pt.pos.y + pt.normal.y * normalsLength,
              pt.pos.z + pt.normal.z * normalsLength
            )
        }

        voff++
      }
    }

    // quad.computeVertexNormals();

    const material = new Material('myMat', 'SimpleSurfaceShader')
    material.getParameter('BaseColor').setValue(Color.random(0.15))
    quad.material = material

    const normalsGeomMaterial = new Material('myMat', 'FlatSurfaceShader')
    normalsGeomMaterial.getParameter('BaseColor').setValue(new Color(1, 0, 0))
    normalsGeom.material = normalsGeomMaterial

    this.__meshes[surfaceId] = quad
    return { mesh: quad, normals: normalsGeom }
  }

  /**
   * The generateHullGeometry method.
   * @param {any} surfaceId - The surfaceId param.
   * @return {any} - The return value.
   */
  generateHullGeometry(surfaceId) {
    if (this.__hulls[surfaceId]) {
      // const color = this.__hulls[surfaceId].mat.getParameter('BaseColor').getValue();
      // color.r = color.r + 0.2;
      // console.log("surface Instanced:" + surfaceId + ":" + color.r);
      return this.__hulls[surfaceId]
    }

    if (this.getSurfaceType(surfaceId) != CADSurfaceTypes.SURFACE_TYPE_NURBS_SURFACE) {
      return
    }
    const surfaceData = this.getSurfaceData(surfaceId)
    if (!surfaceData) {
      return
    }
    console.log(
      'generateHullGeometry:' + surfaceId + ' numCPsU:' + surfaceData.numCPsU + ' numCPsV:' + surfaceData.numCPsV
    )
    const hull = new Hull(surfaceData.numCPsU, surfaceData.numCPsV)

    const positions = hull.getVertexAttribute('positions')
    let voff = 0
    for (let j = 0; j < surfaceData.numCPsV; j++) {
      for (let i = 0; i < surfaceData.numCPsU; i++) {
        const index = i + j * surfaceData.numCPsU
        const pt = surfaceData.controlPoints[index]
        positions.getValueRef(voff).set(pt.x, pt.y, pt.z)
        voff++
      }
    }

    const material = new Material('hullMaterial', 'FlatSurfaceShader')
    material.getParameter('BaseColor').setValue(Color.random(-0.25))
    hull.material = material

    this.__hulls[surfaceId] = hull
    return hull
  }

  /**
   * The dumpDebugSurfaces method.
   */
  dumpDebugSurfaces() {
    const surfacesData = []
    for (let i = 0; i < this.__numSurfaces; i++) {
      try {
        surfacesData.push(this.getSurfaceData(i, false))
      } catch (e) {
        console.warn('Error accessing Surface: ', i, e)
        surfacesData.push({})
      }
    }
    return surfacesData
  }

  /**
   * The dumpDebugCurves method.
   */
  dumpDebugCurves() {
    const curvesData = []
    for (let i = 0; i < this.__numSurfaces; i++) {
      try {
        curvesData.push(this.getCurveData(i))
      } catch (e) {
        console.warn('Error accessing Curve: ', i, e)
        curvesData.push({})
      }
    }
    return curvesData
  }

  /**
   * The logFormfactors method.
   */
  logFormfactors() {
    for (const ff in this.__formFactors) console.log(ff + ':' + this.__formFactors[ff])
  }
}

export { CADSurfaceLibrary }
