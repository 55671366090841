import { shaderLibrary } from '@zeainc/zea-engine'

shaderLibrary.setShaderModule(
  'GLSLNURBSCurves.glsl',
  `

struct NURBSCurveData {
  vec2 domain;
  bool periodic;
  int degree;
  int numCPs;
  int numKnots;

  int cpStart;
  int kpStart;
};

void loadNURBSCurve3dData(inout GLSLBinReader reader, sampler2D texture, out NURBSCurveData result) {

  result.domain.x = GLSLBinReader_readFloat(reader, texture);
  result.domain.y = GLSLBinReader_readFloat(reader, texture);
  result.degree = GLSLBinReader_readUInt(reader, texture);

  result.numCPs = GLSLBinReader_readUInt(reader, texture);
  result.numKnots = GLSLBinReader_readUInt(reader, texture);
  int flags = GLSLBinReader_readUInt(reader, texture);
  result.periodic = testFlag(flags, CURVE_FLAG_PERIODIC);

  result.cpStart = 2*4; // 2 RGBA pixels of data before the knot values start.
  result.kpStart = result.cpStart + (result.numCPs*4);
}

vec4 curve_cp3d(int u, inout GLSLBinReader r, NURBSCurveData d, sampler2D t) {
  int index = u * 4;
  return GLSLBinReader_readVec4(r, t, d.cpStart + index);
}

// https://github.com/akshatamohanty/wildcat-cad/blob/650e18d665ccde3dbc4c78029e35c38951581c92/Source/Geometry/Shaders/ns23_default_plM.fsh
// https://github.com/mrdoob/three.js/blob/6c7f000734f8579da37fb39e5c2e9e5e2dfb14f8/examples/js/curves/NURBSUtils.js

/*
  Calculate rational B-Spline curve point. See The NURBS Book, page 134, algorithm A4.3.
*/
PosNorm calcNURBSCurve3dPoint(float param, inout GLSLBinReader r, sampler2D t) {

  NURBSCurveData d;
  loadNURBSCurve3dData(r, t, d);

  float u = d.domain.x + param * ( d.domain.y - d.domain.x ); // linear mapping param->u
  //if(d.periodic)
  //    u = wrap(u, kp(0, r, t, d.kpStart), kp(d.numKnots-1, r, t, d.kpStart));
 
  float bv[MAX_DEGREE+1];
  float bvds[MAX_DEGREE+1];

#ifdef EXPORT_KNOTS_AS_DELTAS
  highp float knots[MAX_DEGREE*2+1];
  int span = findSpan(u, d.degree, d.numKnots, d.kpStart, r, t, knots);
  //return PosNorm(vec3(span, knots[d.degree], knots[d.degree+1]), vec3(1.0), CURVE_TYPE_NURBS_CURVE);
  //return PosNorm(vec3(knots[d.degree-1], knots[d.degree], knots[d.degree+1]), vec3(1.0), CURVE_TYPE_NURBS_CURVE);
  //return PosNorm(vec3(knots[d.degree+1], knots[d.degree+2], knots[d.degree+3]), vec3(1.0), CURVE_TYPE_NURBS_CURVE);

  calcBasisValues(u, d.degree, knots, bv, bvds);
  // return PosNorm(vec3(bv[0], bv[1], bv[2]), vec3(bv[3], bv[4], bv[5]), CURVE_TYPE_NURBS_CURVE);
#else
  int span = findSpan(u, d.degree, d.numKnots, d.kpStart, r, t, d.periodic);
  // return PosNorm(vec3(float(span)), vec3(1.0), CURVE_TYPE_NURBS_CURVE);
  // return PosNorm(vec3(kp(span-1, r, t, d.kpStart), kp(span-0, r, t, d.kpStart), kp(span+1, r, t, d.kpStart)), vec3(0.0), CURVE_TYPE_NURBS_CURVE);

  // Invalid knot vectors exist where all the values are identical.
  if (kp(span, r, t, d.kpStart) == kp(span+1, r, t, d.kpStart)) {
    for(int x=0; x <= d.degree; x++)
      bv[x] = 0.0;
    bv[d.degree] = 1.0;
  }
  else {
    calcBasisValues(u, span, d.degree, d.kpStart, d.numKnots, r, t, bv, bvds);
  }
  // return PosNorm(vec3(bv[0], bv[1], bv[2]), vec3(bv[3], bv[4], bv[5]), CURVE_TYPE_NURBS_CURVE);
#endif


  float w = 0.0;
  vec3 pos = vec3(0.0);
  vec3 tangent = vec3(0.0);

  int cv0 = (span - d.degree);
#ifdef ENABLE_ES3
  for(int x=0; x <= d.degree; x++) {
#else
  for(int x=0; x < MAX_DEGREE; x++) {
    if(x > d.degree) // x<=degree
      break;
#endif
    int index = cv0 + x;
    vec4 pt = curve_cp3d(index, r, d, t);

    if(d.degree < 3) {
      // Rhino style evaluation....
      highp float bvw = bv[x];
      pos += pt.xyz * bvw;
      w += pt.w * bvw;
    }
    else {
      // Tiny NURBS/CADEx style evaluation....
      highp float bvw = pt.w * bv[x];
      pos += pt.xyz * bvw;
      w += bvw;
    }

    tangent += pt.xyz * bvds[x];
  }

  pos /= w;


  // Calc tangent
  if(d.degree == 1){
    vec3 pt0 = curve_cp3d(cv0, r, d, t).xyz;
    vec3 pt1 = curve_cp3d(cv0+1, r, d, t).xyz;
    tangent = pt1 - pt0;
  }
  else {
    if (length(tangent) < 0.05) {

      float spanLerp = u - knots[span];
      float spanRange = knots[span+1] - knots[span];
      int cv = int(floor((spanLerp / spanRange) * float(d.degree-1)));

      vec3 pt0 = curve_cp3d(cv, r, d, t).xyz;
      vec3 pt1 = curve_cp3d(cv+1, r, d, t).xyz;
      tangent = pt1 - pt0;
    }
  }

  return PosNorm(pos, normalize(tangent), CURVE_TYPE_NURBS_CURVE);
}



`
)
