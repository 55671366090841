import { shaderLibrary } from '@zeainc/zea-engine'

shaderLibrary.setShaderModule(
  'GLSLNURBS.glsl',
  `

#define MAX_KNOTS 256
// Note: The BRP motorcycle features many surfaces of degree 15.
#define MAX_DEGREE 16

float kp(int index, inout GLSLBinReader r, sampler2D t, int offset) {
  return GLSLBinReader_readFloat(r, t, offset+index);
}

#ifdef EXPORT_KNOTS_AS_DELTAS

int findSpan(float u, in int degree, in int numKnots, int kpOff, inout GLSLBinReader r, sampler2D t, out highp float knots[MAX_DEGREE*2+1]) {
  
  float nextKnot = kp(0, r, t, kpOff);
  float knot = nextKnot;

  int span = 1;
  int n = numKnots - degree - 1;
  // Linear Search...
  for (; span<n; span++){
    nextKnot += kp(span, r, t, kpOff);
    if (span > degree && u < nextKnot){
      span--;
      break;
    }
    knot = nextKnot;
  }
  if (span == n) {
    span--;
  }


  //Calculate knot values
  knots[degree] = knot;
  float left = knot;
  float right = knot; 
  for (int i=1; i<=degree; i++) {
    left -= kp(span-i+1, r, t, kpOff);
    right += kp(span+i, r, t, kpOff);
    knots[degree-i] = left;
    knots[degree+i] = right;
  }

  return span;
}

void calcBasisValues(in float u, in int degree, in highp float knots[MAX_DEGREE*2+1], out highp float basisValues[MAX_DEGREE+1], out highp float bvD[MAX_DEGREE+1]) {
  
  highp vec2 savedTemp;
  highp float left[MAX_DEGREE+1];
  highp float right[MAX_DEGREE+1];

  //Basis[0] is always 1.0
  basisValues[0] = 1.0;
  // Calculate basis values
  for (int i=1; i<=degree; i++) {
    left[i] = u - knots[degree+1-i];
    right[i] = knots[degree+i] - u;

    savedTemp.x = 0.0;
    for (int j=0; j<i; j++) {
      float rv = right[j+1];
      float lv = left[i-j];
      savedTemp.y = basisValues[j] / (rv + lv);
      basisValues[j] = savedTemp.x + rv * savedTemp.y;
      savedTemp.x = lv * savedTemp.y;
    }
    basisValues[i] = savedTemp.x;

    // Calculate N' if on second to last iteration
    if (i == degree-1 || degree == 1) {
      savedTemp.x = 0.0;
      //Loop through all basis values
      for (int j=0; j<degree; j++) {
        // Calculate a temp variable
        int jr_z = j + 1;
        //Calculate right side
        float kp_0 = knots[jr_z + degree];
        float kp_1 = knots[jr_z];
        savedTemp.y = (float(degree) * basisValues[j]) / (kp_0 - kp_1);
        // Calculate derivative value
        bvD[j] = savedTemp.x - savedTemp.y;
        // Swap right side to left
        savedTemp.x = savedTemp.y;
      }
      //Save the last der-basis
      bvD[degree] = savedTemp.x;
    }
  }
}

#else

// http://read.pudn.com/downloads134/sourcecode/math/569665/nurbsR2006b/findspan.c__.htm
// Note: I have found the 'early outs' in the Three code to be more correct
// https://github.com/mrdoob/three.js/blob/b8d8a8625465bd634aa68e5846354d69f34d2ff5/examples/js/curves/NURBSUtils.js
int findSpan(float u, in int degree, in int numKnots, int kpOff, inout GLSLBinReader r, sampler2D t, bool periodic) {
  
  // early outs
  int n = numKnots - degree - 1;
  if(u >= kp(n, r, t, kpOff))
    return n-1;
  if(u <= kp(degree, r, t, kpOff)) {
    return degree;
  }

  // Linear Search...
#ifdef ENABLE_ES3
  int i = degree; 
  for (; i<n; i++){
#else
  // Note: loop values must be constant.
  // Loops start at 1 because that is the minimum degree for a curve.
  for (int i = 1; i<MAX_KNOTS; i++){
    if(i >= degree && i < numKnots-1){
#endif
    if (u < kp(i+1, r, t, kpOff)){
      return i;
    }
#ifndef ENABLE_ES3
  }
#endif
  }

  return i;
}


void calcBasisValues(in float u, in int span, int degree, int kpOff, int numKnots, inout GLSLBinReader r, sampler2D t, out highp float basisValues[MAX_DEGREE+1], out highp float bvD[MAX_DEGREE+1]) {
  
  highp vec2 savedTemp;
  highp float left[MAX_DEGREE+1];
  highp float right[MAX_DEGREE+1];

  // Basis[0] is always 1.0
  basisValues[0] = 1.0;
  // Calculate basis values
#ifdef ENABLE_ES3
  for (int i=1; i<=degree; i++) {
#else
  for (int i=1; i<MAX_DEGREE; i++) {
    if(i > degree) // i<=degree
      break;
#endif
    left[i] = u - kp(span+1-i, r, t, kpOff);
    right[i] = kp(span+i, r, t, kpOff) - u;

    savedTemp.x = 0.0;
#ifdef ENABLE_ES3
    for (int j=0; j<i; j++) {
#else
    for (int j=0; j<MAX_DEGREE; j++) {
      if(j >= i) // j < i
        break;
#endif
      float rv = right[j+1];
      float lv = left[i-j];
      savedTemp.y = basisValues[j] / (rv + lv);
      basisValues[j] = savedTemp.x + rv * savedTemp.y;
      savedTemp.x = lv * savedTemp.y;
    }
    basisValues[i] = savedTemp.x;
    
    // Calculate N' if on second to last iteration
    if (i == degree-1 || degree == 1) {
      savedTemp.x = 0.0;
      // Loop through all basis values
#ifdef ENABLE_ES3
      for (int j=0; j<degree; j++) {
#else
      for (int j=0; j<MAX_DEGREE; j++) {
        if(j >= degree) // j < degree
          break;
#endif
        // Calculate a temp variable
        int jr_z = span - degree + j + 1;
        // Calculate right side
        float kp_0 = kp(jr_z + degree, r, t, kpOff);
        float kp_1 = kp(jr_z, r, t, kpOff);
        savedTemp.y = (float(degree) * basisValues[j]) / (kp_0 - kp_1);
        // Calculate derivative value
        bvD[j] = savedTemp.x - savedTemp.y;
        // Swap right side to left
        savedTemp.x = savedTemp.y;
      }
      // Save the last der-basis
#ifdef ENABLE_ES3
      bvD[degree] = savedTemp.x;
#else
      if(degree == 1)
        bvD[1] = savedTemp.x;
      else if(degree == 2)
        bvD[2] = savedTemp.x;
      else if(degree == 3)
        bvD[3] = savedTemp.x;
      else if(degree == 4)
        bvD[4] = savedTemp.x;
      else if(degree == 5)
        bvD[5] = savedTemp.x;
      else if(degree == 6)
        bvD[6] = savedTemp.x;
      else if(degree == 7)
        bvD[7] = savedTemp.x;
      else if(degree == 8)
        bvD[8] = savedTemp.x;
      else if(degree == 9)
        bvD[9] = savedTemp.x;
      else if(degree == 10)
        bvD[10] = savedTemp.x;
      else if(degree == 11)
        bvD[11] = savedTemp.x;
      else if(degree == 12)
        bvD[12] = savedTemp.x;
      else if(degree == 13)
        bvD[13] = savedTemp.x;
      else if(degree == 14)
        bvD[14] = savedTemp.x;
      else if(degree == 15)
        bvD[15] = savedTemp.x;
#endif
    }
  }
}


#endif

`
)
