import { EventEmitter, Vec3, GLTexture2D, MathFunctions } from '@zeainc/zea-engine'
import { CADBody } from './CADBody.js'
import { GLSurfaceDrawSet } from './GLSurfaceDrawSet.js'
import { GLCurveDrawSet } from './GLCurveDrawSet.js'
import { GLCurveLibrary } from './GLCurveLibrary.js'
import { GLSurfaceLibrary } from './GLSurfaceLibrary.js'
import { GLTrimSetLibrary } from './GLTrimSetLibrary.js'
import { GLCADBody } from './GLCADBody.js'

import {
  pixelsPerDrawItem, // The number of RGBA pixels per draw item.
  drawItemShaderAttribsStride,
  floatsPerSceneBody,
} from './CADConstants.js'

// [bodyDescId, surfaceId, cadBodyDesc.xy], [glmaterialcoords.xy][tr-xyz], [ori], [sc], [highlight], [cutPlane]
const pixelsPerCADBody = 7

import GLCADAssetWorker from 'web-worker:./GLCADAssetWorker.js'
// import {
//   GLCADAssetWorker_onmessage
// } from './GLCADAssetWorker.js';

/**  Class representing a GL CAD asset.
 * @ignore
 */
class GLCADAsset extends EventEmitter {
  /**
   * Create a GL CAD asset.
   * @param {any} gl - The gl value.
   * @param {any} assetId - The assetId value.
   * @param {any} cadAsset - The cadAsset value.
   * @param {any} cadpassdata - The cadpassdata value.
   */
  constructor(gl, assetId, cadAsset, cadpassdata) {
    super()
    this.__gl = gl
    this.__assetId = assetId
    this.__cadAsset = cadAsset
    this.__numSurfaces = cadAsset.getSurfaceLibrary().getNumSurfaces()
    this.__numBodies = cadAsset.getBodyLibrary().getNumBodies()
    this.__numMaterials = cadAsset.getMaterialLibrary().getNumMaterials()
    this.__numHighlightedGeoms = 0
    this.__ready = false

    this.__visible = this.__cadAsset.isVisible()
    this.__assetVisibilityChanged = this.__assetVisibilityChanged.bind(this)
    this.__cadAsset.on('visibilityChanged', this.__assetVisibilityChanged)

    const updateDisplayEdges = () => {
      this.displayEdges = this.__cadAsset.getParameter('DisplayEdges').getValue()
      if (this.displayEdges) cadpassdata.incDisplayEdges()
      else cadpassdata.decDisplayEdges()
    }
    updateDisplayEdges()
    this.displayEdgesChangedId = this.__cadAsset.getParameter('DisplayEdges').on('valueChanged', updateDisplayEdges)

    const updateEdgeColor = () => {
      this.edgeColor = this.__cadAsset.getParameter('EdgeColor').getValue().asArray()
      this.emit('updated')
    }
    updateEdgeColor()
    this.edgesColorChangedId = this.__cadAsset.getParameter('EdgeColor').on('valueChanged', updateEdgeColor)

    this.__cadpassdata = cadpassdata

    this.__curveLibrary = new GLCurveLibrary(
      gl,
      cadpassdata,
      this.__cadAsset.getSurfaceLibrary(),
      cadAsset.getVersion()
    )
    this.__surfaceLibrary = new GLSurfaceLibrary(
      gl,
      cadpassdata,
      this.__cadAsset.getSurfaceLibrary(),
      this.__curveLibrary,
      cadAsset.getVersion()
    )
    const trimSetsBuffer = this.__cadAsset.getTrimSetLibrary().getBinaryBuffer()
    if (trimSetsBuffer && trimSetsBuffer.byteLength > 8) {
      this.__trimSetLibrary = new GLTrimSetLibrary(
        gl,
        cadpassdata,
        this.__cadAsset.getTrimSetLibrary(),
        this.__curveLibrary
      )
    }

    {
      const bodyLibraryBuffer = this.__cadAsset.getBodyLibrary().getBinaryBuffer()
      if (bodyLibraryBuffer) {
        const bodyTexSize = Math.sqrt(bodyLibraryBuffer.byteLength / 16) // RGBA32 pixels
        this.__bodyDescTexture = new GLTexture2D(gl, {
          format: 'RGBA',
          type: 'FLOAT',
          width: bodyTexSize,
          height: bodyTexSize,
          filter: 'NEAREST',
          wrap: 'CLAMP_TO_EDGE',
          mipMapped: false,
          data: new Float32Array(bodyLibraryBuffer),
        })
      }
    }

    this.__bindAttr = (location, channels, type, stride, offset, instanced = true) => {
      gl.enableVertexAttribArray(location)
      gl.vertexAttribPointer(location, channels, gl.FLOAT, false, stride, offset)
      if (instanced) gl.vertexAttribDivisor(location, 1) // This makes it instanced
    }

    this.__trimCurveDrawSets = {}
    this.__surfaceDrawSets = {}
    this.__curveDrawSets = {}

    // ////////////////////////////////////////////////

    this.loadWorker()
  }

  /**
   * @private
   */
  __assetVisibilityChanged() {
    this.__visible = this.__cadAsset.isVisible()
    this.emit('updated')
  }

  /**
   * The getCADAsset method.
   * @return {any} - The return value.
   */
  getCADAsset() {
    return this.__cadAsset
  }

  /**
   * The getNumSurfaces method.
   * @return {any} - The return value.
   */
  getNumSurfaces() {
    return this.__numSurfaces
  }

  /**
   * The getNumBodies method.
   * @return {any} - The return value.
   */
  getNumBodies() {
    return this.__numBodies
  }

  /**
   * The getNumMaterials method.
   * @return {any} - The return value.
   */
  getNumMaterials() {
    return this.__numMaterials
  }

  /**
   * The incHighlightedCount method.
   * @param {any} count - The count param.
   */
  incHighlightedCount(count) {
    this.__numHighlightedGeoms += count
    this.__cadpassdata.incHighlightedCount(count)
  }

  /**
   * The decHighlightedCount method.
   * @param {any} count - The count param.
   */
  decHighlightedCount(count) {
    this.__numHighlightedGeoms -= count
    this.__cadpassdata.decHighlightedCount(count)
  }

  /**
   * The loadWorker method.
   */
  loadWorker() {
    const numBodyItems = this.__cadAsset.getNumBodyItems()
    if (numBodyItems == 0) return

    // let tmp = 0;
    // this.__cadAsset.traverse((treeItem) => {
    //     if (treeItem instanceof CADBody) {
    //       console.log(treeItem.getPath())
    //       tmp++;
    //       return false;
    //     } else
    //       return true;
    // });
    // if(numBodyItems != tmp){
    //   console.log("numBodyItems", numBodyItems, tmp)
    //   numBodyItems = tmp;
    // }

    // Only support power 2 textures. Else we get strange corruption on some GPUs
    // in some scenes.
    let cadBodiesTextureSize = MathFunctions.nextPow2(Math.round(Math.sqrt(numBodyItems * pixelsPerCADBody) + 0.5))
    // Size should be a multiple of pixelsPerCADBody, so each geom item is always contiguous
    // in memory. (makes updating a lot easier. See __updateItemInstanceData below)
    if (cadBodiesTextureSize % pixelsPerCADBody != 0)
      cadBodiesTextureSize += pixelsPerCADBody - (cadBodiesTextureSize % pixelsPerCADBody)

    this.cadBodiesTextureData = new Float32Array(cadBodiesTextureSize * cadBodiesTextureSize * 4) // 4==RGBA pixels.

    // Calculate the entroid to offset all the Xfo values.
    // This is to work around an issue on Mobile GPUs where
    // the fragment shader stage only supports Flaot16 operations.
    // The Matrix calculated in the DrawItems shader contains artifacts
    // because of the low precision.
    // If the asset is invisible when loaded, then the bbox is
    // not valid and the centroid becomes NaN
    const assetBBox = this.__cadAsset.getParameter('BoundingBox').getValue()
    // this.__assetCentroid = assetBBox.center()
    // if (
    //   Number.isNaN(this.__assetCentroid.x) ||
    //   Number.isNaN(this.__assetCentroid.y) ||
    //   Number.isNaN(this.__assetCentroid.z)
    // ) {
    this.__assetCentroid = new Vec3()
    // }

    const sceneBodyItemsData = new Float32Array(numBodyItems * floatsPerSceneBody)

    let index = 0
    this.__cadBodies = []
    const highlightedBodies = []

    const highlightChangeBatch = {
      highlightedBodyIds: [],
      unhighlightedBodyIds: [],
      dirty: false,
    }
    const pushhighlightChangeBatchToWorker = () => {
      this.__postMessageToWorker({
        eventType: 'bodyHighlightChanged',
        highlightedBodyIds: highlightChangeBatch.highlightedBodyIds,
        unhighlightedBodyIds: highlightChangeBatch.unhighlightedBodyIds,
      })
      highlightChangeBatch.highlightedBodyIds = []
      highlightChangeBatch.unhighlightedBodyIds = []
      highlightChangeBatch.dirty = false
    }

    this.__dirtyBodyIndices = []
    const bodyItemDataChanged = (bodyId) => {
      if (this.__dirtyBodyIndices.indexOf(bodyId) == -1) {
        this.__dirtyBodyIndices.push(bodyId)
        this.emit('updated')
      }
    }

    const bindCADBody = (cadBody) => {
      const bodyId = index
      if (bodyId >= numBodyItems) return

      // Data passed to the web worker to help setup layout.
      const sceneBodyItemDataByteOffset = bodyId * floatsPerSceneBody * 4 /*bytes/channel*/
      const sceneBodyItemData = new Float32Array(
        sceneBodyItemsData.buffer,
        sceneBodyItemDataByteOffset,
        floatsPerSceneBody
      )

      const cadBodyTextureDataByteOffset = bodyId * pixelsPerCADBody * 4 /*channels/pixel*/ * 4 /*bytes/channel*/
      const cadBodyTextureData = new Float32Array(
        this.cadBodiesTextureData.buffer,
        cadBodyTextureDataByteOffset,
        pixelsPerCADBody * 4 /*channels/pixel*/
      )

      const glCADBody = new GLCADBody(cadBody, bodyId)
      glCADBody.bind(
        this.__cadpassdata,
        sceneBodyItemData,
        cadBodyTextureData,
        bodyItemDataChanged,
        highlightedBodies,
        highlightChangeBatch,
        pushhighlightChangeBatchToWorker
      )

      this.__cadBodies.push(glCADBody)
      index++
    }

    this.__cadAsset.traverse((treeItem) => {
      if (treeItem instanceof CADBody) {
        bindCADBody(treeItem)
        return false
      } else {
        return true
      }
    })

    ////////////////////////////////////////
    // Greate the GLTexture.
    const gl = this.__gl
    this.__cadBodiesTexture = new GLTexture2D(gl, {
      format: 'RGBA',
      type: 'FLOAT',
      width: cadBodiesTextureSize,
      height: cadBodiesTextureSize,
      filter: 'NEAREST',
      wrap: 'CLAMP_TO_EDGE',
      mipMapped: false,
      data: this.cadBodiesTextureData,
    })

    ////////////////////////////////////////
    // Detail Factor
    // The detail factor is used to convert surface 'cost'
    // to a given tesselation level.
    // Here we start with a given tesselation desired for a
    // circle the size of the asset bounding box.
    // We calculate the cost of the circle (curvature * length^2)
    const unitsScale = this.__cadAsset.getUnitsConversion()
    const assetBBoxRadius = (assetBBox.size() * 0.5) / unitsScale

    //////////////////////
    // Calculate a detail value for a circle enclosing our bbox.
    const lod = this.__cadAsset.lod
    const detail = 128 * Math.pow(2, lod)
    // Calculate the arc angle for a cricle subdivided to the detail level
    const arcAngle = (Math.PI * 2.0) / detail
    // Calculate the deviation to the circle at the middle of the arc.
    const errorTolerance = assetBBoxRadius - assetBBoxRadius * Math.cos(arcAngle / 2)

    // The smallest area of a drawn item.
    // The renderer will skip any surfaces smaller than this item.
    const surfaceAreaThreshold = 0 //Math.PI * (assetBBoxRadius * assetBBoxRadius * 0.000000005 * Math.pow(2, lod))
    // Note: on the hospital sprinker system we get the following values on the GTX laptop.
    // assetBBoxRadius: 67447  surfaceAreaThreshold: 285
    // Skipping about 30 surfaces.

    console.log(
      'assetBBoxRadius:',
      assetBBoxRadius,
      ' errorTolerance:',
      errorTolerance,
      ' surfaceAreaThreshold:',
      surfaceAreaThreshold
    )

    ////////////////////////////////////////
    const curvesDataBuffer = this.__cadAsset.getSurfaceLibrary().getCurveBuffer()
    const surfacesDataBuffer = this.__cadAsset.getSurfaceLibrary().getSurfaceBuffer()
    const cadDataVersion = this.__cadAsset.getVersion()

    const trimSetsBuffer = this.__cadAsset.getTrimSetLibrary().getBinaryBuffer()
    let trimTexelSize = -1
    if (trimSetsBuffer) {
      const numAssets = this.__cadpassdata.assetCount
      trimTexelSize = this.__cadAsset.getTrimSetLibrary().getTexelSize(lod, numAssets)
      // const mult = Math.pow(2, this.__cadAsset.getLOD());
      // trimTexelSize = this.__cadAsset.getTrimTexelSize() * mult;
    }

    const bodyLibraryBufferToc = this.__cadAsset.getBodyLibrary().getToc()
    const bodyLibraryBuffer = this.__cadAsset.getBodyLibrary().getBinaryBuffer()

    const transferables = [surfacesDataBuffer, bodyLibraryBufferToc, bodyLibraryBuffer]
    if (trimSetsBuffer) transferables.push(trimSetsBuffer)

    const assemblyData = {
      eventType: 'loadAssembly',
      assetId: this.__assetId,
      curvesDataBuffer,
      surfacesDataBuffer,
      cadDataVersion,
      trimSetsBuffer,
      lod: this.__cadAsset.getLOD(),
      maxTexSize: this.__cadpassdata.maxTexSize / 2,
      errorTolerance,
      surfaceAreaThreshold,
      trimTexelSize,
      sceneBodyItemsData,
      bodyLibraryBufferToc,
      bodyLibraryBuffer,
      highlightedBodies,
    }
    this.__postMessageToWorker(assemblyData, transferables)
  }

  updateBodyTexture(renderstate) {
    const gl = this.__gl

    const texId = this.__gl.TEXTURE0 + renderstate.boundTextures + 1
    gl.activeTexture(texId)
    gl.bindTexture(gl.TEXTURE_2D, this.__cadBodiesTexture.glTex)
    const size = this.__cadBodiesTexture.width
    for (let i = 0; i < this.__dirtyBodyIndices.length; i++) {
      const bodyId = this.__dirtyBodyIndices[i]
      const yoffset = Math.floor((bodyId * pixelsPerCADBody) / size)
      const xoffset = (bodyId * pixelsPerCADBody) % size

      const glCADBody = this.__cadBodies[bodyId]
      glCADBody.updateCadBodyTex()

      const width = pixelsPerCADBody
      const height = 1

      const cadBodyTextureDataByteOffset = bodyId * pixelsPerCADBody * 4 /*channels/pixel*/ * 4 /*bytes/channel*/
      const cadBodyTextureData = new Float32Array(
        this.cadBodiesTextureData.buffer,
        cadBodyTextureDataByteOffset,
        pixelsPerCADBody * 4 /*channels/pixel*/
      )
      this.__cadBodiesTexture.populate(cadBodyTextureData, width, height, xoffset, yoffset, false)
    }
    gl.bindTexture(gl.TEXTURE_2D, null)

    this.__dirtyBodyIndices = []
  }

  /**
   * The __postMessageToWorker method.
   * @param {any} data - The data param.
   * @param {any} transferables - The transferables param.
   * @private
   */
  __postMessageToWorker(data, transferables) {
    // if(this.__cadpassdata.debugMode) {
    //   setTimeout(()=>{
    //     GLCADAssetWorker_onmessage(data, this.__onWorkerMessage.bind(this));
    //   },100);
    // }
    // else
    {
      if (!this.__worker) this.__worker = new GLCADAssetWorker()
      this.__worker.onmessage = (event) => {
        this.__onWorkerMessage(event.data) // loading done...
      }
      this.__worker.postMessage(data, this.__cadpassdata.debugMode ? [] : transferables)
    }
  }

  /**
   * The __onWorkerMessage method.
   * @param {any} data - The data param.
   * @private
   */
  __onWorkerMessage(data) {
    switch (data.eventType) {
      case 'loadAssetDone':
        console.log('Layout Asset:', this.getCADAsset().getName(), data.profiling)

        // ///////////////////////////////
        // Curves, Surfaces and Trim Sets

        // this.__gl.finish();

        if (data.curvesAtlasLayout) {
          this.__curveLibrary.evaluateCurves(
            data.curvesAtlasLayout,
            data.numCurves,
            data.curvesAtlasLayoutTextureSize,
            data.curvesAtlasTextureDim
          )
        }

        // Note: rollup is generating radom problems in production builds
        // values are getting assigned classes. The profiling values here
        // become assigned class definitions, which then cause crashes or
        // garbage logging
        // Here, if we bundle the values into an object, then its ok.
        // This is probably due to the use to WebWorkers this this file.
        // We should try updating rollup and see if these hacks can be
        // removed.
        const values = {}

        if (data.surfacesEvalAttrs) {
          values.surfaceEvalTime = this.__surfaceLibrary.evaluateSurfaces(
            data.surfacesEvalAttrs,
            data.surfacesAtlasLayout,
            data.surfacesAtlasLayoutTextureSize,
            data.surfacesAtlasTextureDim
          )
        }

        if (data.trimCurveDrawSets && data.trimSetAtlasTextureSize[0] > 0 && data.trimSetAtlasTextureSize[1] > 0) {
          this.__trimSetLibrary.evaluateTrimSets(
            data.trimCurveDrawSets,
            data.trimSetAtlasTextureSize,
            data.trimSetsAtlasLayoutData,
            data.trimSetsAtlasLayoutTextureSize
          )
        }

        // ///////////////////////////////
        // Draw Items

        this.__bodyAtlasDim = data.bodyAtlasDim
        // this.updateDrawItems(data.evalDrawItemShaderAttribs)
        this.updateDrawSets(values, data.surfaceDrawSets, data.curveDrawSets)
        this.__ready = true

        this.emit('loaded', {
          numSurfaces: data.profiling.numSurfaces,
          numSurfaceInstances: data.profiling.numSurfaceInstances,
          surfaceEvalTime: values.surfaceEvalTime,
          numBodies: data.profiling.numBodies,
          numMaterials: this.__numMaterials,
          numTriangles: values.numTriangles,
          numDrawSets: values.numDrawSets,
        })
        this.emit('updated')
        break
      case 'highlightedSurfaceDrawSetsChanged':
        for (const drawSetKey in data.highlightedSurfaceDrawSets) {
          const drawSet = this.__surfaceDrawSets[drawSetKey]
          if (!drawSet) {
            console.warn('Selecting invalid items:', drawSetKey)
            continue
          }
          drawSet.setDrawItems(data.highlightedSurfaceDrawSets[drawSetKey], 1)
        }
        this.incHighlightedCount(data.numHighlighted)
        this.decHighlightedCount(data.numUnhighlighted)
        this.emit('updated')
        break
    }

    // if (data.lodChanges.length != 0){
    //     const eachLODChange = (change)=>{
    //         const glsurfacedrawItem = this.__glbodyItems[change.bodyIndex].glsurfacedrawItems[change.surfaceIndex];
    //         glsurfacedrawItem.setLod(change.lod - 1);
    //     }
    //     // console.log("lodChanges:" + data.lodChanges.length)
    //     data.lodChanges.forEach(eachLODChange);
    // }
    // if (data.becomingInvisible.length != 0 || data.becomingVisible.length != 0){
    //     const eachBecomingInvisibleChange = (change)=>{
    //         // console.log("BecomingInvisible:" + change.bodyIndex + ":" + change.surfaceIndex);
    //         const glsurfacedrawItem = this.__glbodyItems[change.bodyIndex].glsurfacedrawItems[change.surfaceIndex];
    //         glsurfacedrawItem.setInvisible();
    //     }
    //     // console.log("becomingInvisible:" + data.becomingInvisible.length)
    //     data.becomingInvisible.forEach(eachBecomingInvisibleChange);

    //     const eachBecomingVisibleChange = (change)=>{
    //         // console.log("BecomingVisible:" + change.bodyIndex + ":" + change.surfaceIndex);
    //         const glsurfacedrawItem = this.__glbodyItems[change.bodyIndex].glsurfacedrawItems[change.surfaceIndex];
    //         glsurfacedrawItem.setVisible();
    //     }
    //     // console.log("becomingVisible:" + data.becomingVisible.length)
    //     data.becomingVisible.forEach(eachBecomingVisibleChange);
    // }
  }

  /**
   * The updateDrawItems method.
   * @param {any} evalDrawItemShaderAttribs - The evalDrawItemShaderAttribs param.
   */
  updateDrawSets(values, surfaceDrawSets, curveDrawSets) {
    values.numTriangles = 0
    values.numDrawSets = 0

    if (surfaceDrawSets) {
      // eslint-disable-next-line guard-for-in
      for (const drawSetKey in surfaceDrawSets) {
        let drawSet = this.__surfaceDrawSets[drawSetKey]
        // Note: on initialization, there are no draw sets, so
        // we always construct the draw set here.
        if (!drawSet) {
          const parts = drawSetKey.split('x')
          const detailX = parseInt(parts[0])
          const detailY = parseInt(parts[1])
          drawSet = new GLSurfaceDrawSet(this.__gl, detailX, detailY)
          this.__surfaceDrawSets[drawSetKey] = drawSet
        }

        const drawSetData = surfaceDrawSets[drawSetKey]
        // eslint-disable-next-line guard-for-in
        for (const subSetKey in drawSetData) {
          const drawItemsData = drawSetData[subSetKey]
          values.numTriangles += drawSet.addDrawItems(drawItemsData, subSetKey)
        }

        values.numDrawSets++
      }
    }
    if (curveDrawSets) {
      // eslint-disable-next-line guard-for-in
      for (const drawSetKey in curveDrawSets) {
        let drawSet = this.__curveDrawSets[drawSetKey]
        // Note: on initialization, there are no draw sets, so
        // we always construct the draw set here.
        if (!drawSet) {
          const detail = parseInt(drawSetKey)
          drawSet = new GLCurveDrawSet(this.__gl, detail)
          this.__curveDrawSets[drawSetKey] = drawSet
        }

        const drawSetData = curveDrawSets[drawSetKey]
        // eslint-disable-next-line guard-for-in
        for (const subSetKey in drawSetData) {
          const drawItemsData = drawSetData[subSetKey]
          drawSet.addDrawItems(drawItemsData, subSetKey)
        }

        values.numDrawSets++
      }
    }
  }

  /**
   * The bindDrawItemsAtlas method.
   * @param {any} renderstate - The renderstate param.
   */

  bindDrawItemsAtlas(renderstate) {
    this.__drawItemsTarget.bindColorTexture(renderstate, renderstate.unifs.drawItemsTexture)
    // this.__drawItemsTexture.bindToUniform(renderstate, renderstate.unifs.drawItemsTexture);
    if (renderstate.unifs.vert_drawItemsTextureSize) {
      this.__gl.uniform2i(
        renderstate.unifs.vert_drawItemsTextureSize.location,
        this.__bodyAtlasDim[0],
        this.__bodyAtlasDim[1]
      )
    }
    if (renderstate.unifs.frag_drawItemsTextureSize) {
      this.__gl.uniform2i(
        renderstate.unifs.frag_drawItemsTextureSize.location,
        this.__bodyAtlasDim[0],
        this.__bodyAtlasDim[1]
      )
    }
  }

  bind(renderstate) {
    const gl = this.__gl
    const unifs = renderstate.unifs

    // console.log("bind:", Object.keys(unifs))

    // this.bindDrawItemsAtlas(renderstate)
    // if (unifs.drawItemsTexture) {
    //   this.__drawItemsTarget.bindColorTexture(
    //     renderstate,
    //     unifs.drawItemsTexture
    //   )
    // }

    if (unifs.vert_drawItemsTextureSize) {
      this.__gl.uniform2i(unifs.vert_drawItemsTextureSize.location, this.__bodyAtlasDim[0], this.__bodyAtlasDim[1])
    }
    if (unifs.frag_drawItemsTextureSize) {
      this.__gl.uniform2i(unifs.frag_drawItemsTextureSize.location, this.__bodyAtlasDim[0], this.__bodyAtlasDim[1])
    }

    if (unifs.bodyDescTexture) {
      this.__bodyDescTexture.bindToUniform(renderstate, unifs.bodyDescTexture)
      gl.uniform2i(unifs.bodyDescTextureSize.location, this.__bodyDescTexture.width, this.__bodyDescTexture.height)
      if (unifs.bodyDescTextureSize_frag) {
        gl.uniform2i(
          unifs.bodyDescTextureSize_frag.location,
          this.__bodyDescTexture.width,
          this.__bodyDescTexture.height
        )
      }
    }

    if (unifs.cadBodiesTexture) {
      this.__cadBodiesTexture.bindToUniform(renderstate, unifs.cadBodiesTexture)
      if (unifs.cadBodiesTextureSize_vert)
        gl.uniform1i(unifs.cadBodiesTextureSize_vert.location, this.__cadBodiesTexture.width)
      if (unifs.cadBodiesTextureSize_frag)
        gl.uniform1i(unifs.cadBodiesTextureSize_frag.location, this.__cadBodiesTexture.width)
    }

    // if (unifs.cutNormal) {
    //   gl.uniform3fv(unifs.cutNormal.location, this.__cutNormal.asArray())
    //   gl.uniform1f(unifs.planeDist.location, this.__cutDist)
    //   if (unifs.cutColor) {
    //     gl.uniform4fv(unifs.cutColor.location, this.__cutColor.asArray())
    //   }
    // }

    if (unifs.assetCentroid) {
      gl.uniform3fv(unifs.assetCentroid.location, this.__assetCentroid.asArray())
    }
  }

  /**
   * The draw method.
   * @param {any} renderstate - The renderstate param.
   * @return {any} - The return value.
   */
  draw(renderstate) {
    if (!this.__visible || !this.__ready) return false

    const boundTextures = renderstate.boundTextures

    if (this.__dirtyBodyIndices.length > 0) {
      this.updateBodyTexture(renderstate)
    }

    this.bind(renderstate)

    if (!this.__surfaceLibrary.bindSurfacesAtlas(renderstate)) {
      renderstate.boundTextures = boundTextures
      return
    }

    if (this.__trimSetLibrary) {
      this.__trimSetLibrary.bindTrimSetAtlasLayout(renderstate)
      this.__trimSetLibrary.bindTrimSetAtlas(renderstate)
    }

    for (const key in this.__surfaceDrawSets) {
      // console.log("draw:" + key)
      const drawSet = this.__surfaceDrawSets[key]
      drawSet.draw(renderstate, renderstate.shaderId)
    }

    renderstate.boundTextures = boundTextures
  }

  /**
   * The drawHighlightedGeoms method.
   * @param {any} renderstate - The renderstate param.
   * @return {any} - The return value.
   */
  drawHighlightedGeoms(renderstate) {
    if (!this.__visible || this.__numHighlightedGeoms == 0) return false

    const boundTextures = renderstate.boundTextures

    this.bind(renderstate)

    this.__surfaceLibrary.bindSurfacesAtlas(renderstate)
    if (this.__trimSetLibrary) {
      this.__trimSetLibrary.bindTrimSetAtlasLayout(renderstate)
      this.__trimSetLibrary.bindTrimSetAtlas(renderstate)
    }

    // Now draw the highlight outline.
    const highlightOutlineID = 1
    for (const key in this.__surfaceDrawSets) {
      // console.log("draw:" + key)
      const drawSet = this.__surfaceDrawSets[key]
      drawSet.draw(renderstate, highlightOutlineID)
    }

    renderstate.boundTextures = boundTextures
  }

  /**
   * The drawNormals method.
   * @param {any} renderstate - The renderstate param.
   * @param {any} shaderKey - The shaderKey param.
   * @return {any} - The return value.
   */
  drawNormals(renderstate, shaderKey) {
    if (!this.__visible || !this.__ready) return false

    const boundTextures = renderstate.boundTextures
    this.bind(renderstate)

    this.__surfaceLibrary.bindSurfacesAtlas(renderstate)
    if (this.__trimSetLibrary) {
      this.__trimSetLibrary.bindTrimSetAtlasLayout(renderstate)
      this.__trimSetLibrary.bindTrimSetAtlas(renderstate)
    }

    for (const key in this.__surfaceDrawSets) {
      const drawSet = this.__surfaceDrawSets[key]
      drawSet.drawNormals(renderstate, shaderKey)
    }

    renderstate.boundTextures = boundTextures
  }

  /**
   * The drawEdges method.
   * @param {any} renderstate - The renderstate param.
   * @param {any} shaderKey - The shaderKey param.
   * @return {any} - The return value.
   */
  drawEdges(renderstate, shaderKey) {
    if (!this.__visible || !this.__ready || !this.displayEdges) return false

    const boundTextures = renderstate.boundTextures
    if (this.__dirtyBodyIndices.length > 0) {
      this.updateBodyTexture(renderstate)
    }

    this.bind(renderstate)
    this.__curveLibrary.bindCurvesAtlas(renderstate)

    const gl = this.__gl
    gl.uniform4fv(renderstate.unifs.edgeColor.location, this.edgeColor)

    for (const key in this.__curveDrawSets) {
      const drawSet = this.__curveDrawSets[key]
      drawSet.draw(renderstate, shaderKey)
    }

    renderstate.boundTextures = boundTextures
  }

  /**
   * The drawGeomData method.
   * @param {any} renderstate - The renderstate param.
   * @return {any} - The return value.
   */
  drawGeomData(renderstate) {
    if (!this.__visible || !this.__ready) return false

    const boundTextures = renderstate.boundTextures

    this.bind(renderstate)

    this.__surfaceLibrary.bindSurfacesAtlas(renderstate)
    if (this.__trimSetLibrary) {
      this.__trimSetLibrary.bindTrimSetAtlasLayout(renderstate)
      this.__trimSetLibrary.bindTrimSetAtlas(renderstate)
    }

    const gl = this.__gl
    const assetIndexUnif = renderstate.unifs.assetIndex
    if (assetIndexUnif) {
      gl.uniform1i(assetIndexUnif.location, this.__assetId)
    }

    for (const key in this.__surfaceDrawSets) {
      // console.log("draw:" + key)
      const drawSet = this.__surfaceDrawSets[key]
      drawSet.draw(renderstate, renderstate.shaderId)
    }

    renderstate.boundTextures = boundTextures
  }

  /**
   * The getGeomItem method.
   * @param {any} bodyId - The bodyId param.
   * @return {any} - The return value.
   */
  getGeomItem(bodyId) {
    return this.__cadBodies[bodyId].cadBody
  }

  /**
   * The getSurfaceData method.
   * @param {any} surfaceId - The surfaceId param.
   * @return {any} - The return value.
   */
  getSurfaceData(surfaceId) {
    return this.__cadAsset.getSurfaceLibrary().getSurfaceData(surfaceId)
  }

  /**
   * The getSurfaceData method.
   * @param {any} renderstate - The renderstate param.
   */
  drawSurfaceAtlas(renderstate) {
    if (this.__surfaceLibrary) this.__surfaceLibrary.drawSurfaceAtlas(renderstate)
  }

  /**
   * The drawTrimSets method.
   * @param {any} renderstate - The renderstate param.
   */
  drawTrimSets(renderstate) {
    if (this.__trimSetLibrary) this.__trimSetLibrary.drawTrimSets(renderstate)
  }

  /**
   * The destroy method.
   */
  destroy() {
    this.__cadAsset.off('visibilityChanged', this.__assetVisibilityChanged)

    this.__cadBodiesTexture.destroy()

    this.__cadBodies.forEach((glCADBody) => glCADBody.destroy())
    this.__cadBodies = []
    this.__curveLibrary.destroy()
    this.__surfaceLibrary.destroy()

    if (this.__trimSetLibrary) {
      this.__trimSetLibrary.destroy()
    }

    for (const drawSetKey in this.__surfaceDrawSets) {
      let drawSet = this.__surfaceDrawSets[drawSetKey]
      drawSet.destroy()
    }
  }
}

export { GLCADAsset }
