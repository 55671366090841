import { Float32, Plane, Mesh, GLMesh } from '@zeainc/zea-engine'

import { numValuesPerTrimSetCurveRef } from './CADConstants.js'

/** This class abstracts the rendering of a collection of geometries to screen.
 * @extends Mesh
 * @ignore
 */
class Fan extends Mesh {
  /**
   * Create a fan.
   * @param {number} detail - The detail value.
   */
  constructor(detail = 1) {
    super()
    this.__detail = detail
    this.addVertexAttribute('vertexIds', Float32)
    this.__rebuild()
  }

  /**
   * The __rebuild method.
   * @private
   */
  __rebuild() {
    this.setNumVertices(this.__detail + 2)
    this.setFaceCounts([this.__detail])

    for (let i = 0; i < this.__detail; i++) {
      this.setFaceVertexIndices(i, [0, i + 1, i + 2])
    }
    const vertexIds = this.getVertexAttribute('vertexIds')
    for (let i = 0; i <= vertexIds.length; i++) {
      vertexIds.setFloat32Value(i, i)
    }
  }
}

/** Class representing a strip.
 * @extends Plane
 * @ignore
 */
class Strip extends Plane {
  /**
   * Create a strip.
   * @param {number} detail - The detail value.
   */
  constructor(detail = 1) {
    super(1, 2, detail, 1, false, false)
  }

  /**
   * The __resize method.
   * @private
   */
  __resize() {
    const sizeX = this.__sizeXParam.getValue()
    const sizeY = this.__sizeYParam.getValue()
    const detailX = this.__detailXParam.getValue()
    const detailY = this.__detailYParam.getValue()
    const positions = this.getVertexAttribute('positions')
    let voff = 0
    for (let i = 0; i <= detailY; i++) {
      const y = (i / detailY - 0.5) * sizeY
      for (let j = 0; j <= detailX; j++) {
        const x = j
        positions.getValueRef(voff).set(x, y, 0.0)
        voff++
      }
    }
    this.setBoundingBoxDirty()
  }
}

const __cache = {}

/** Class representing a GL trim curve draw set.
 * @ignore
 */
class GLTrimCurveDrawSet {
  /**
   * Create a GL trim curve draw set.
   * @param {any} gl - The gl value.
   * @param {any} detail - The detail value.
   * @param {any} trimCurvesDataArray - The trimCurvesDataArray value.
   */
  constructor(gl, detail, trimCurvesDataArray) {
    this.__gl = gl
    this.__detail = detail

    if (!__cache[detail]) {
      __cache[detail] = {
        glfangeom: new GLMesh(gl, new Fan(detail)),
        glstripgeom: new GLMesh(gl, new Strip(detail)),
      }
    }
    this.__glfangeom = __cache[detail].glfangeom
    this.__glstripgeom = __cache[detail].glstripgeom

    this.__buffer = gl.createBuffer()
    gl.bindBuffer(gl.ARRAY_BUFFER, this.__buffer)
    gl.bufferData(gl.ARRAY_BUFFER, trimCurvesDataArray, gl.STATIC_DRAW)

    this.__drawCount = trimCurvesDataArray.length / numValuesPerTrimSetCurveRef
  }

  /**
   * The bindAttr method.
   * @param {any} location - The location param.
   * @param {any} channels - The channels param.
   * @param {any} type - The type param.
   * @param {any} stride - The stride param.
   * @param {any} offset - The offset param.
   */
  bindAttr(location, channels, type, stride, offset) {
    if (location < 0) return
    const gl = this.__gl
    gl.enableVertexAttribArray(location)
    gl.vertexAttribPointer(location, channels, gl.FLOAT, false, stride, offset)
    gl.vertexAttribDivisor(location, 1) // This makes it instanced
  }

  // ////////////////////////////////////
  // Drawing

  /**
   * The setBuffer method.
   */
  setBuffer() {}

  /**
   * The drawFans method.
   * @param {any} renderstate - The renderstate param.
   */
  drawFans(renderstate) {
    const gl = this.__gl

    this.__glfangeom.bind(renderstate)

    const unifs = renderstate.unifs
    const attrs = renderstate.attrs

    gl.bindBuffer(gl.ARRAY_BUFFER, this.__buffer)
    this.bindAttr(attrs.patchCoords.location, 4, gl.FLOAT, numValuesPerTrimSetCurveRef * 4, 0)
    this.bindAttr(attrs.data0.location, 4, gl.FLOAT, numValuesPerTrimSetCurveRef * 4, 4 * 4)
    this.bindAttr(attrs.data1.location, 4, gl.FLOAT, numValuesPerTrimSetCurveRef * 4, 4 * 8)
    this.bindAttr(attrs.data2.location, 2, gl.FLOAT, numValuesPerTrimSetCurveRef * 4, 4 * 12)

    gl.uniform1i(unifs.numCurveVertices.location, this.__detail + 1)

    this.__glfangeom.drawInstanced(this.__drawCount)
  }

  /**
   * The drawStrips method.
   * @param {any} renderstate - The renderstate param.
   */
  drawStrips(renderstate) {
    const gl = this.__gl

    this.__glstripgeom.bind(renderstate)

    const unifs = renderstate.unifs
    const attrs = renderstate.attrs

    gl.bindBuffer(gl.ARRAY_BUFFER, this.__buffer)
    this.bindAttr(attrs.patchCoords.location, 4, gl.FLOAT, numValuesPerTrimSetCurveRef * 4, 0)
    this.bindAttr(attrs.data0.location, 4, gl.FLOAT, numValuesPerTrimSetCurveRef * 4, 4 * 4)
    this.bindAttr(attrs.data1.location, 4, gl.FLOAT, numValuesPerTrimSetCurveRef * 4, 4 * 8)
    this.bindAttr(attrs.data2.location, 2, gl.FLOAT, numValuesPerTrimSetCurveRef * 4, 4 * 12)

    gl.uniform1i(unifs.numCurveVertices.location, this.__detail + 1)

    this.__glstripgeom.drawInstanced(this.__drawCount)
  }

  /**
   * The cleanup method.
   */
  cleanup() {
    // this.__gl.deleteBuffer(this.__buffer);
  }
}

export { GLTrimCurveDrawSet }
