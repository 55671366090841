﻿import { EventEmitter, GrowingPacker, GLTexture2D, Registry } from '@zeainc/zea-engine'

/** Class representing a GL CAD material library.
 * @ignore
 */
class GLCADMaterialLibrary extends EventEmitter {
  /**
   * Create a GL CAD material library.
   * @param {any} gl - The gl value.
   */
  constructor(gl) {
    super()
    this.__gl = gl
    this.__materialDatas = []
    this.__dirtyIndices = []
    this.__numItems = 0
    this.__materialPacker = new GrowingPacker(256, 256)

    this.__needsUpload = false
  }

  /**
   * The addMaterial method.
   * @param {any} material - The material param.
   * @return {any} - The return value.
   */
  addMaterial(material) {
    if (material.getMetadata('glmaterialcoords')) {
      return
    }

    this.__numItems++

    const coords = this.__materialPacker.addBlock({ w: 2, h: 1 })
    const materialId = this.__materialDatas.length
    this.__materialDatas.push({
      material,
      coords,
    })

    material.on('parameterValueChanged', () => {
      // this.__renderer.requestRedraw();
      this.__dirtyIndices.push(materialId)
      this.emit('updated')
    })

    material.setMetadata('glmaterialcoords', coords)

    this.__dirtyIndices.push(materialId)

    return coords
  }

  /**
   * The needsUpload method.
   * @return {any} - The return value.
   */
  needsUpload() {
    return this.__dirtyIndices.length > 0
  }

  /**
   * The uploadMaterials method.
   */
  uploadMaterials() {
    const gl = this.__gl
    const width = this.__materialPacker.root.w
    const height = this.__materialPacker.root.h
    // console.log('Num Used Materials:' + this.__numItems, width, height)
    if (!this.__materialsTexture) {
      this.__materialsTexture = new GLTexture2D(gl, {
        format: 'RGBA',
        type: 'FLOAT',
        width,
        height,
        filter: 'NEAREST',
        wrap: 'CLAMP_TO_EDGE',
        mipMapped: false,
      })
      this.__materialsTexture.clear()
    } else if (this.__materialsTexture.width != width || this.__materialsTexture.height != height) {
      throw new Error('Cannot resize here. Need a resize the preserves the data.')
      this.__materialsTexture.resize(width, height)
      this.__dirtyIndices = Array(this.__bodyDrawItems.length)
        .fill()
        .map((v, i) => i)
    }

    gl.bindTexture(gl.TEXTURE_2D, this.__materialsTexture.glTex)
    const typeId = this.__materialsTexture.getTypeID()
    const formatId = this.__materialsTexture.getFormatID()

    const eachMaterial = (value) => {
      const materialData = this.__materialDatas[value]
      const material = materialData.material

      let shaderClass = Registry.getBlueprint(material.getShaderName())
      if (!shaderClass || !shaderClass.getPackedMaterialData) {
        shaderClass = Registry.getBlueprint('GLDrawCADSurfaceShader')
      }

      const matData = shaderClass.getPackedMaterialData(material)

      const width = matData.length / 4 // 4==RGBA pixels.
      const height = 1

      const coords = materialData.coords
      if (typeId == gl.FLOAT) {
        gl.texSubImage2D(gl.TEXTURE_2D, 0, coords.x, coords.y, width, height, formatId, typeId, matData)
      } else {
        const unit16s = Math.convertFloat32ArrayToUInt16Array(matData)
        gl.texSubImage2D(gl.TEXTURE_2D, 0, coords.x, coords.y, width, height, formatId, typeId, unit16s)
      }
    }
    this.__dirtyIndices.forEach(eachMaterial)
    this.__dirtyIndices = []
    gl.bindTexture(gl.TEXTURE_2D, null)
  }

  /**
   * The bind method.
   * @param {any} renderstate - The renderstate param.
   * @return {any} - The return value.
   */
  bind(renderstate) {
    if (!this.__materialsTexture) return false

    const gl = this.__gl
    const unifs = renderstate.unifs
    if (unifs.materialsTexture) this.__materialsTexture.bindToUniform(renderstate, unifs.materialsTexture)
    if (unifs.materialsTextureSize)
      gl.uniform2i(unifs.materialsTextureSize.location, this.__materialsTexture.width, this.__materialsTexture.height)
    return true
  }
}
export { GLCADMaterialLibrary }
