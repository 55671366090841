/* eslint-disable camelcase */
import { GLShader } from '@zeainc/zea-engine'

/** Class representing a GL debug trim sets shader.
 * @extends GLShader
 * @ignore
 */
class GLDebugTrimSetsShader extends GLShader {
  /**
   * Create a GL debug trim sets shader.
   * @param {any} gl - The gl value.
   */
  constructor(gl) {
    super(gl)

    this.setShaderStage(
      'VERTEX_SHADER',
      `
precision highp float;

attribute vec4 positions;

/* VS Outputs */
varying vec2 v_texCoord;
 
void main()
{
    v_texCoord = (positions.xy + 0.5);
    gl_Position =  vec4(positions.xy * 2.0, 0.0, 1.0);
    // gl_Position =  vec4(positions.xy + vec2(-0.5, 0.5), 0.0, 1.0);
}
`
    )
    this.setShaderStage(
      'FRAGMENT_SHADER',
      `
precision highp float;

uniform sampler2D trimSetAtlasTexture;

/* VS Outputs */
varying vec2 v_texCoord;
 
#ifdef ENABLE_ES3
out vec4 fragColor;
#endif

void main(void) {

#ifndef ENABLE_ES3
  vec4 fragColor;
#endif

  vec4 col = texture2D(trimSetAtlasTexture, v_texCoord);
  vec3 rgb = col.rgb / col.a;
  fragColor = vec4(rgb,1.0);
  
#ifndef ENABLE_ES3
  gl_FragColor = fragColor;
#endif
}
`
    )
  }
}

export { GLDebugTrimSetsShader }
