/* eslint-disable camelcase */
import { GLShader, shaderLibrary, Registry } from '@zeainc/zea-engine'

/** Class representing a GL flatten trim sets shader.
 * @extends GLShader
 * @ignore
 */
class GLFlattenTrimSetsShader extends GLShader {
  /**
   * Create a GL flatten trim sets shader.
   * @param {any} gl - The gl value.
   */
  constructor(gl) {
    super(gl)

    this.__shaderStages['VERTEX_SHADER'] = shaderLibrary.parseShader(
      'GLFlattenTrimSetsShader.vertexShader',
      `
precision highp float;

attribute vec4 positions;

/* VS Outputs */
varying vec2 v_texCoord;
 
void main()
{
    v_texCoord = (positions.xy + 0.5);
    gl_Position =  vec4(positions.xy * 2.0, 0.0, 1.0);
}
`
    )

    this.__shaderStages['FRAGMENT_SHADER'] = shaderLibrary.parseShader(
      'GLFlattenTrimSetsShader.fragmentShader',
      `
precision highp float;

uniform sampler2D trimSetAtlasTexture;
uniform ivec2 trimSetAtlasTextureSize;


/* VS Outputs */
varying vec2 v_texCoord;

// returns true if the texel will be kept
// When the fans are rendered, the pixels are acumulated. 
// An even number means that the pixel should be discarded
// and an odd number mean the pixel should be kept.
bool sampleAtlas(vec2 offset){
    return mod(texture2D(trimSetAtlasTexture, v_texCoord + (offset / vec2(trimSetAtlasTextureSize))).r * 255.0, 2.0) > 0.5;
}

int scoreAtlas(vec2 offset){
  if(sampleAtlas(offset))
    return 1;
  else
    return 0;
}

#ifdef ENABLE_ES3
out vec4 fragColor;
#endif

void main(void) {

#ifndef ENABLE_ES3
    vec4 fragColor;
#endif

  // if(sampleAtlas(vec2(0.0, 0.0))){
  //   fragColor = vec4(1.0, 0.5, 0.5, 1.0);
  // }
  // else{
  //   fragColor = vec4(0.0, 0.5, 0.5, 1.0);
  // }
  
  // Smoothing. Look at neighboring pixels to see
  // if we should fill in the gaps. Due to floating
  // point issues, we see random pixels floating
  // in the air that shold have been trimmed by the
  // fan. If a point 
  int score = scoreAtlas(vec2(-1.0, 0.0)) + 
              scoreAtlas(vec2(-1.0,-1.0)) + 
              scoreAtlas(vec2( 0.0,-1.0)) + 
              scoreAtlas(vec2( 1.0,-1.0)) + 
              scoreAtlas(vec2( 1.0, 0.0)) + 
              scoreAtlas(vec2( 1.0, 1.0)) + 
              scoreAtlas(vec2( 0.0, 1.0)) + 
              scoreAtlas(vec2(-1.0, 1.0));

  float r = 0.0;
  if(sampleAtlas(vec2(0.0, 0.0))){
    if(score >= 4) // corner verts have a score of 3
      r = 1.0;
    else
      r = 0.0;
  }
  else{
    if(score < 4) 
      r = 0.0;
    else
      r = 1.0;
  }
  fragColor = vec4(r, r, 0.0, 0.0);
  
#ifndef ENABLE_ES3
    gl_FragColor = fragColor;
#endif
}
`
    )
  }
}

Registry.register('GLFlattenTrimSetsShader', GLFlattenTrimSetsShader)

export { GLFlattenTrimSetsShader }
