import { shaderLibrary } from '@zeainc/zea-engine'

shaderLibrary.setShaderModule(
  'GLSLCADSimpleSurfaces.glsl',
  `

  // http://cadexchanger.com/download/sdk/doc/dev/html/sdk_data_model_geometry_topology.html#sdk_data_model_geometry_surfaces


/////////////////////////////////////////
// Plane
// A plane is parametrized as follows: S(u,v) = P + u * dX + v * dY, where

// P is an origin point,
// dX and dY are directions (unit vectors) of X and Y axes respectively,
// u, v belongs to (-Infinity, +Infinity).

PosNorm calcPlaneSurfacePoint(vec2 params, inout GLSLBinReader reader, sampler2D texture) {
  box2 domain = loadBox2(reader, texture);
  vec2 uv = mapDomain(domain, params);

  vec3 pos = vec3(uv.x, uv.y, 0.0);
  vec3 normal = vec3(0.0, 0.0, 1.0);

  return PosNorm(pos, normal, SURFACE_TYPE_PLANE);
}

/////////////////////////////////////////
// Poly Plane
// A plane is parametrized as follows: S(u,v) = P + u * dX + v * dY, where

// P is an origin point,
// dX and dY are directions (unit vectors) of X and Y axes respectively,
// u, v belongs to (-Infinity, +Infinity).

PosNorm calcPolyPlaneSurfacePoint(vec2 params, inout GLSLBinReader reader, sampler2D texture) {
  // Domain does not need to be mapped in this case.
  vec2 uv = params;
  vec2 p0 = GLSLBinReader_readVec2(reader, texture);
  vec2 p1 = GLSLBinReader_readVec2(reader, texture);
  vec2 p2 = GLSLBinReader_readVec2(reader, texture);
  vec2 p3 = GLSLBinReader_readVec2(reader, texture);

  vec2 pos = mix(mix(p0, p1, uv.x), mix(p3, p2, uv.x), uv.y);
  vec3 normal = vec3(0.0, 0.0, 1.0);

  return PosNorm(vec3(pos, 0.0), normal, SURFACE_TYPE_POLY_PLANE);
}


/////////////////////////////////////////
// Fan
// A plane is parametrized as follows: S(u,v) = P + u * dX + v * dY, where

// P is an origin point,
// dX and dY are directions (unit vectors) of X and Y axes respectively,
// u, v belongs to (-Infinity, +Infinity).

PosNorm calcFanSurfacePoint(vec2 params, inout GLSLBinReader reader, sampler2D texture) {
  // Domain does not need to be mapped in this case.
  vec2 uv = params;
  // Skip forward 2 values for each vertex.
  reader.offset += int(2.0 * floor(v_vertexCoord.x));
  vec2 pos = GLSLBinReader_readVec2(reader, texture);
  vec3 normal = vec3(0.0, 0.0, 1.0);

  return PosNorm(vec3(pos, 0.0), normal, SURFACE_TYPE_FAN);
}



/////////////////////////////////////////
// Cone
// A conical surface is parametrized as follows: S(u,v) = P + r * cos(u) * Dx + r * sin(u) * Dy + v * cos(φ) * Dz, where

// P is an origin point,
// Dx, Dy and Dz are directions (unit vectors) of X, Y and Z axes respectively,
// φ - semi-angle, i.e. an angle between Dz and any generatrix,
// r = R + v * sin(φ), i.e. a radius of a circle at respective parameter v,
// u belongs to [0, 2 * PI],
// v belongs to (-infinity, +infinity).
// U-parameter is an angle along the circle at a given parameter V and V-parameter is a length along the cone. Thus, U-isolines are lines and V-isoline are circles.

// V-isoline at V=0 is a circle of radius R in the plane defined by an axis placement.

// Conical surface contains both halfs of mathematical cone.

// Conical surface is U-periodical with period 2 * PI. At a cone apex, r equals 0, hence V-parameter of an apex equals -R / sin(φ)


PosNorm calcConeSurfacePoint(vec2 params, inout GLSLBinReader reader, sampler2D texture) {
  box2 domain = loadBox2(reader, texture);
  float r = GLSLBinReader_readFloat(reader, texture);
  float semiAngle = GLSLBinReader_readFloat(reader, texture);
  vec2 uv = mapDomain(domain, params);

  float u = uv.x;
  float v = uv.y;
  float r_at_v = r + v * sin(semiAngle);
  vec3 pos = vec3(r_at_v * cos(u), r_at_v * sin(u), v * cos(semiAngle));
  vec3 normal = normalize(vec3(cos(u)*cos(semiAngle), sin(u)*cos(semiAngle), -sin(semiAngle)));
  return PosNorm(pos, normal, SURFACE_TYPE_CONE);
}


/////////////////////////////////////////
// Cylinder
// A cylindrical surface is parametrized as follows: S(u,v) = P + R * cos(u) * dX + R * sin(u) * dY + v * dZ, where

// P is an origin point,
// dX, dY and dZ are directions (unit vectors) of X, Y and Z axes respectively,
// R is a radius,
// u belongs to [0, 2 * PI],
// v belongs to (-infinity, +infinity).


PosNorm calcCylinderSurfacePoint(vec2 params, inout GLSLBinReader reader, sampler2D texture) {
  box2 domain = loadBox2(reader, texture);
  float r = GLSLBinReader_readFloat(reader, texture);
  vec2 uv = mapDomain(domain, params);

  vec3 normal = vec3(cos(uv.x), sin(uv.x), 0.0);
  vec3 pos = r * normal + vec3(0.0, 0.0, uv.y);

  return PosNorm(pos, normal, SURFACE_TYPE_CYLINDER);
}


/////////////////////////////////////////
// Sphere
// A spherical surface is parametrized as follows: S(u,v) = P + R * cos(v) * (cos(u) * Dx + sin(u) * Dy) + R * sin(v) * Dz, where

// P is an origin point,
// Dx, Dy and Dz are directions (unit vectors) of X, Y and Z axes respectively,
// R is a radius,
// u belongs to [0, 2 * PI],
// v belongs to [-PI/2, +PI/2].
// U-parameter is an angle of rotation around the Dz axis counterclockwise (i.e. similar to longitude on the Earth), and V-parameter is an angle between plane defined by an axis placement and line from P to a point on a sphere (i.e. latitude). Thus, U-isolines are semi-circles and V-isoline are circles.

// V-isoline at V=0 is a circle of radius R in the plane defined by an axis placement. U-isoline at U=0 corresponds to a semi-circle from south to north pole.

// Spherical surface is U-periodical with period 2 * PI.

// If a face lies on a full spherical surface its boundary wire will contain two a degenerated edges corresponding to the south and north poles (V equals -PI/2 and PI/2 respectively), and a seam-edge.


PosNorm calcSphereSurfacePoint(vec2 params, inout GLSLBinReader reader, sampler2D texture) {
  box2 domain = loadBox2(reader, texture);
  vec2 uv = mapDomain(domain, params);

  float r = GLSLBinReader_readFloat(reader, texture);

  float u = uv.x;
  float v = uv.y;
  vec3 normal = vec3(cos(v) * cos(u), cos(v) * sin(u), sin(v));
  vec3 pos = r * normal;

  return PosNorm(pos, normal, SURFACE_TYPE_SPHERE);
}

/////////////////////////////////////////
// Torus

// A toroidal surface is parametrized as follows: S(u,v) = (R1 + R2 * cos(v)) * (cos(u) * Dx + sin(u) * Dy) + R2 * sin(v) * Dz, where

// R1 is a major radius,
// R2 is a minor radius,
// u belongs to [0, 2 * PI],
// v belongs to [0, 2 * PI],
// U-parameter is an angle when rotating around the Dz axis counterclockwise, and V-parameter is an angle in circular section at a given parameter U. Thus, U-isolines circles lying in the plane containing Z axis and V-isolines are circles in the planes perpendicular to Z axis.

// V-isoline at V=0 is a circle of radius (R1 + R2) in the plane defined by an axis placement. U-isoline at U=0 is a circle of radius R2 in the plane containing Z and X axes.

// Radii R1 and R2 must be positive. If R2 > R1 then toroidal surface will be self-intersecting.

// Toroidal surface is both U- and V-periodical with periods 2 * PI.

// If a face lies on a full toroidal surface its boundary wire will contain two seam-edges, corresponding to U=0 and U=2*PI, and V=0 and V=2*PI respectively.


PosNorm calcTorusSurfacePoint(vec2 params, inout GLSLBinReader reader, sampler2D texture) {
  box2 domain = loadBox2(reader, texture);
  vec2 uv = mapDomain(domain, params);

  float majorRadius = GLSLBinReader_readFloat(reader, texture);
  float minorRadius = GLSLBinReader_readFloat(reader, texture);
  float u = uv.x;
  float v = uv.y;
  vec3 pos = (majorRadius + minorRadius * cos(v)) * vec3(cos(u), sin(u), 0.0) + vec3(0.0, 0.0, minorRadius * sin(v));

  vec3 normal = vec3(cos(v) * cos(u), cos(v) * sin(u), sin(v));
  // vec3 pos = majorRadius * normal;

  return PosNorm(pos, normal, SURFACE_TYPE_TORUS);
}

`
)

shaderLibrary.setShaderModule(
  'GLSLCADCompoundSurfaces.glsl',
  `

/////////////////////////////////////////
// LinearExtrusion

PosNorm calcLinearExtrusionSurfacePoint(vec2 params, inout GLSLBinReader reader, sampler2D texture) {
  box2 domain = loadBox2(reader, texture);
  
  int curve_index = GLSLBinReader_readUIntFrom2xUFloat16(reader, texture);

  vec3 curve_tr = vec3(
    GLSLBinReader_readFloat(reader, texture),
    GLSLBinReader_readFloat(reader, texture),
    GLSLBinReader_readFloat(reader, texture)
  );
  vec4 curve_ori = vec4(
    GLSLBinReader_readFloat(reader, texture),
    GLSLBinReader_readFloat(reader, texture),
    GLSLBinReader_readFloat(reader, texture),
    GLSLBinReader_readFloat(reader, texture)
    );
  vec3 curve_sc = vec3(
    GLSLBinReader_readFloat(reader, texture),
    GLSLBinReader_readFloat(reader, texture),
    GLSLBinReader_readFloat(reader, texture)
  );
  vec2 uv = mapDomain(domain, params);

  PosNorm curveResult = evalCADCurve3d(curve_index, uv.x);
  
  vec3 pos = quat_rotateVec3(curve_ori, curveResult.pos * curve_sc) + curve_tr;
  pos.z += uv.y;

  vec3 normal = normalize(cross(vec3(0.0, 0.0, 1.0), quat_rotateVec3(curve_ori, curveResult.normal)));

  return PosNorm(pos, normal, SURFACE_TYPE_LINEAR_EXTRUSION);
}


/////////////////////////////////////////
// Revolution
PosNorm calcRevolutionSurfacePoint(vec2 params, inout GLSLBinReader reader, sampler2D texture, bool flipDomain) {
  box2 domain = loadBox2(reader, texture);

  int curve_index = GLSLBinReader_readUIntFrom2xUFloat16(reader, texture);

  vec3 curve_tr = vec3(
    GLSLBinReader_readFloat(reader, texture),
    GLSLBinReader_readFloat(reader, texture),
    GLSLBinReader_readFloat(reader, texture)
  );
  vec4 curve_ori = vec4(
    GLSLBinReader_readFloat(reader, texture),
    GLSLBinReader_readFloat(reader, texture),
    GLSLBinReader_readFloat(reader, texture),
    GLSLBinReader_readFloat(reader, texture)
    );
  vec3 curve_sc = vec3(
    GLSLBinReader_readFloat(reader, texture),
    GLSLBinReader_readFloat(reader, texture),
    GLSLBinReader_readFloat(reader, texture)
  );
  vec2 uv = mapDomain(domain, params);

  vec3 axis = vec3(0.0, 0.0, 1.0);
  PosNorm curveResult;
  vec4 rev;
  if (flipDomain) {
    curveResult = evalCADCurve3d(curve_index, uv.x);
    rev = quat_fromAxisAndAngle(axis, uv.y);
  } else {
    curveResult = evalCADCurve3d(curve_index, uv.y);
    rev = quat_fromAxisAndAngle(axis, uv.x);
  }

  vec3 p_t = quat_rotateVec3(curve_ori, curveResult.pos * curve_sc) + curve_tr;
  vec3 pos = quat_rotateVec3(rev, p_t);

  vec3 p_n = quat_rotateVec3(rev, quat_rotateVec3(curve_ori, curveResult.normal));
  
  vec3 tangent;
  if (abs(1.0 - dot(p_n, axis)) > 0.001) {
    tangent = cross(p_n, axis);
  } else {
    tangent = cross(pos, axis);
  }
  // TODO: Find a conclusive test file that demonstrates this as correct.
  // I think it is the master cylinder sample.
  // vec3 normal = normalize(cross(p_n, tangent));
  vec3 normal = normalize(cross(tangent, p_n));

  // vec3 pos;
  // vec3 normal;
  // pos.x = float(partA);
  // pos.y = float(partB);
  // pos.z = float(curve_index);
  return PosNorm(pos, normal, SURFACE_TYPE_REVOLUTION);
}


/////////////////////////////////////////
// OffsetSurface

PosNorm calcOffsetSurfaceSurfacePoint(vec2 params, inout GLSLBinReader reader, sampler2D texture) {
  box2 domain = loadBox2(reader, texture);
  int surfaceId = GLSLBinReader_readUInt(reader, texture);
  float offset = GLSLBinReader_readFloat(reader, texture);
  vec2 uv = mapDomain(domain, params);

/*
  GLSLBinReader subSurfaceReader = reader;
  subSurfaceReader.start = 
  GLSLBinReader_init(reader, surfaceDataTextureSize, region, start, 32);

  vec3 p = calcCurvePoint(uv.x);
  vec3 pos = p + dir * (dist * uv.y);
  
*/
  vec3 pos;
  vec3 normal;
  return PosNorm(pos, normal, SURFACE_TYPE_OFFSET_SURFACE);
}



`
)
