import { GLTexture2D, GLRenderTarget } from '@zeainc/zea-engine'
import { valuesPerCurveLibraryLayoutItem } from './CADConstants.js'
import { GLEvaluateCADCurveShader } from './GLEvaluateCADCurveShader.js'

/** Class representing a GL curve library.
 * @ignore
 */
class GLCurveLibrary {
  /**
   * Create a GL curve library.
   * @param {any} gl - The gl value.
   * @param {any} cadpassdata - The cadpassdata value.
   * @param {any} surfacesLibrary - The surfacesLibrary value.
   * @param {any} version - The version object.
   */
  constructor(gl, cadpassdata, surfacesLibrary, version) {
    this.__gl = gl
    this.__cadpassdata = cadpassdata
    this.__surfacesLibrary = surfacesLibrary
    this.cadDataVersion = version

    const curvesDataBuffer = this.__surfacesLibrary.getCurveBuffer()
    const curveTexSize = Math.sqrt(curvesDataBuffer.byteLength / 8)

    this.__curveDataTexture = new GLTexture2D(gl, {
      format: 'RGBA',
      type: 'HALF_FLOAT',
      width: curveTexSize,
      height: curveTexSize,
      filter: 'NEAREST',
      wrap: 'CLAMP_TO_EDGE',
      mipMapped: false,
      data: new Uint16Array(curvesDataBuffer),
    })

    this.__bindAttr = (location, channels, type, stride, offset, instanced = true) => {
      gl.enableVertexAttribArray(location)
      gl.vertexAttribPointer(location, channels, gl.FLOAT, false, stride, offset)
      if (instanced) gl.vertexAttribDivisor(location, 1) // This makes it instanced
    }
  }

  // /////////////////////////////////////////////////////////////
  // Curves

  /**
   * The evaluateCurves method.
   * @param {any} curvesAtlasLayout - The curvesAtlasLayout param.
   * @param {any} numCurves - The numCurves param.
   * @param {any} curvesAtlasLayoutTextureSize - The curvesAtlasLayoutTextureSize param.
   * @param {any} curvesAtlasTextureDim - The curvesAtlasTextureDim param.
   */
  evaluateCurves(curvesAtlasLayout, numCurves, curvesAtlasLayoutTextureSize, curvesAtlasTextureDim) {
    // console.log("evaluateCurves:" + assetId + ":" + curvesAtlasTextureDim);

    const count = numCurves
    if (count == 0) return

    const gl = this.__gl
    {
      this.__curveAtlasLayoutTexture = new GLTexture2D(this.__gl, {
        format: 'RGBA',
        type: 'FLOAT',
        width: curvesAtlasLayoutTextureSize[0],
        height: curvesAtlasLayoutTextureSize[1],
        filter: 'NEAREST',
        wrap: 'CLAMP_TO_EDGE',
        mipMapped: false,
        data: curvesAtlasLayout,
      })
    }

    if (!this.__curvesAtlasRenderTarget) {
      this.__curvesAtlasRenderTarget = new GLRenderTarget(gl, {
        format: 'RGBA',
        type: 'FLOAT',
        width: curvesAtlasTextureDim[0],
        height: curvesAtlasTextureDim[1],
        filter: 'NEAREST',
        wrap: 'CLAMP_TO_EDGE',
        mipMapped: false,
      })
      this.__curvesTangentAtlasRenderTarget = new GLRenderTarget(gl, {
        format: 'RGBA',
        type: 'FLOAT',
        width: curvesAtlasTextureDim[0],
        height: curvesAtlasTextureDim[1],
        filter: 'NEAREST',
        wrap: 'CLAMP_TO_EDGE',
        mipMapped: false,
      })
    } else if (
      this.__curvesAtlasRenderTarget.width != curvesAtlasTextureDim[0] ||
      this.__curvesAtlasRenderTarget.height != curvesAtlasTextureDim[1]
    ) {
      // Copy the previous image into a new one, and then destroy the prvious.
      this.__curvesAtlasRenderTarget.resize(curvesAtlasTextureDim[0], curvesAtlasTextureDim[1], true)
    }

    const renderstate = {
      shaderopts: {
        directives: [...gl.shaderopts.directives],
      },
    }
    this.__curvesAtlasRenderTarget.bindForWriting(renderstate, true)

    if (this.cadDataVersion.compare([0, 0, 0]) > 0) {
      renderstate.shaderopts.directives.push('#define EXPORT_KNOTS_AS_DELTAS 1')
    }
    if (this.cadDataVersion.compare([0, 0, 26]) > 0) {
      renderstate.shaderopts.directives.push('#define INTS_PACKED_AS_2FLOAT16 1')
    }

    if (!this.evaluateCurveShader) {
      this.evaluateCurveShader = new GLEvaluateCADCurveShader(gl)
    }

    this.evaluateCurveShader.bind(renderstate)
    this.__cadpassdata.glplanegeom.bind(renderstate)

    const unifs = renderstate.unifs
    const attrs = renderstate.attrs

    gl.uniform2i(
      unifs.curvesAtlasTextureSize.location,
      this.__curvesAtlasRenderTarget.width,
      this.__curvesAtlasRenderTarget.height
    )

    this.__curveDataTexture.bindToUniform(renderstate, unifs.curveDataTexture)
    gl.uniform2i(unifs.curveDataTextureSize.location, this.__curveDataTexture.width, this.__curveDataTexture.height)

    const buffer = gl.createBuffer()
    gl.bindBuffer(gl.ARRAY_BUFFER, buffer)
    gl.bufferData(gl.ARRAY_BUFFER, curvesAtlasLayout, gl.STATIC_DRAW)

    this.__bindAttr(attrs.patchCoords.location, 4, gl.FLOAT, valuesPerCurveLibraryLayoutItem * 4, 0)
    this.__bindAttr(attrs.curveDataCoords.location, 2, gl.FLOAT, valuesPerCurveLibraryLayoutItem * 4, 4 * 4)

    // //////////////////////////////////////////////
    // Bind each Fbo and render separately.
    // Bizzarly, this has turned out to be much faster
    // than using mutiple render targets...
    gl.uniform1i(unifs.writeTangents.location, 0)

    this.__cadpassdata.glplanegeom.drawInstanced(count)

    this.__curvesTangentAtlasRenderTarget.bindForWriting(renderstate, true)

    gl.uniform1i(unifs.writeTangents.location, 1)
    this.__cadpassdata.glplanegeom.drawInstanced(count)
    // //////////////////////////////////////////////

    gl.deleteBuffer(buffer)

    const logCurveData = (curveId) => {
      this.__curvesAtlasRenderTarget.bindForReading()
      const layout = [
        curvesAtlasLayout[curveId * valuesPerCurveLibraryLayoutItem + 0],
        curvesAtlasLayout[curveId * valuesPerCurveLibraryLayoutItem + 1],
        curvesAtlasLayout[curveId * valuesPerCurveLibraryLayoutItem + 2],
        curvesAtlasLayout[curveId * valuesPerCurveLibraryLayoutItem + 3],
      ]
      console.log('----------------------------------')
      console.log('logCurveData ' + curveId + ':[' + layout[0] + ',' + layout[1] + ']:' + layout[2] + 'x' + layout[3])
      const pixels = new Float32Array(layout[2] * 4)
      for (let i = 0; i < layout[3]; i++) {
        gl.readPixels(layout[0], layout[1] + i, layout[2], 1, gl.RGBA, gl.FLOAT, pixels)
        for (let j = 0; j < layout[2]; j++) {
          console.log(j, ':', pixels[j * 4 + 0], pixels[j * 4 + 1], pixels[j * 4 + 2], pixels[j * 4 + 3])
        }
        // console.log(i+":" + pixels);
      }
    }
    // console.log("----------------------------------");
    // logCurveData(35799)
    // logCurveData(1)
    // console.log("----------------------------------");

    this.__curvesTangentAtlasRenderTarget.unbind()
    gl.finish()
  }

  /**
   * The bindCurvesAtlas method.
   * @param {any} renderstate - The renderstate param.
   */
  bindCurvesAtlasLayout(renderstate) {
    const gl = this.__gl
    const unifs = renderstate.unifs
    if (this.__curvesAtlasRenderTarget) {
      if (unifs.curvesAtlasLayoutTexture) {
        this.__curveAtlasLayoutTexture.bindToUniform(renderstate, unifs.curvesAtlasLayoutTexture)
        gl.uniform2i(
          unifs.curvesAtlasLayoutTextureSize.location,
          this.__curveAtlasLayoutTexture.width,
          this.__curveAtlasLayoutTexture.height
        )
      }
    }
  }

  /**
   * The bindCurvesAtlas method.
   * @param {any} renderstate - The renderstate param.
   */
  bindCurvesAtlas(renderstate) {
    const gl = this.__gl
    const unifs = renderstate.unifs
    if (this.__curvesAtlasRenderTarget) {
      this.__curvesAtlasRenderTarget.bindColorTexture(renderstate, unifs.curvesAtlasTexture)

      if (unifs.curveTangentsTexture) {
        this.__curvesTangentAtlasRenderTarget.bindColorTexture(renderstate, unifs.curveTangentsTexture)
      }
      if (unifs.curvesAtlasTextureSize) {
        gl.uniform2i(
          unifs.curvesAtlasTextureSize.location,
          this.__curvesAtlasRenderTarget.width,
          this.__curvesAtlasRenderTarget.height
        )
      }

      if (unifs.curvesAtlasLayoutTexture) {
        this.__curveAtlasLayoutTexture.bindToUniform(renderstate, unifs.curvesAtlasLayoutTexture)
        gl.uniform2i(
          unifs.curvesAtlasLayoutTextureSize.location,
          this.__curveAtlasLayoutTexture.width,
          this.__curveAtlasLayoutTexture.height
        )
      }
    }
  }

  /**
   * The destroy method.
   */
  destroy() {
    this.__curveDataTexture.destroy()
    if (this.__curveAtlasLayoutTexture) {
      this.__curveAtlasLayoutTexture.destroy()
      this.__curvesAtlasRenderTarget.destroy()
      this.__curvesTangentAtlasRenderTarget.destroy()
    }
  }
}

export { GLCurveLibrary }
