/* eslint-disable camelcase */
import { GLShader, shaderLibrary } from '@zeainc/zea-engine'

import './GLSLBinReader.js'
import './GLSLMath.js'
import './GLSLCADCurves.js'
import './GLSLCADSurfaces.js'
import './GLSLNURBS.js'
import './GLSLNURBSCurves.js'
import './GLSLNURBSSurfaces.js'

const GLEvaluateCADSurfaceShader_VERTEX_SHADER = shaderLibrary.parseShader(
  'GLEvaluateCADSurfaceShader.vertexShader',
  `
precision highp float;

attribute vec3 positions;
instancedattribute float surfaceId;

uniform sampler2D surfaceAtlasLayoutTexture;
uniform ivec2 surfaceAtlasLayoutTextureSize;

uniform ivec2 surfacesAtlasTextureSize;

<%include file="GLSLUtils.glsl"/>
<%include file="GLSLBinReader.glsl"/>

/* VS Outputs */
varying float v_surfaceId;      // flat
varying vec3 v_geomDataCoords;  // flat
varying vec2 v_patchSize;       // flat
varying vec2 v_vertexCoord;



void main(void) {

  GLSLBinReader reader;
  GLSLBinReader_init(reader, surfaceAtlasLayoutTextureSize, 32);
  vec4 patchCoords = GLSLBinReader_readVec4(reader, surfaceAtlasLayoutTexture, int(surfaceId)*8);
  vec4 surfaceDataCoords = GLSLBinReader_readVec4(reader, surfaceAtlasLayoutTexture, (int(surfaceId)*8)+4);

  vec2 patchPos = patchCoords.xy;
  v_patchSize = patchCoords.zw;

  v_surfaceId = surfaceId;
  v_geomDataCoords = surfaceDataCoords.xyz;
  v_vertexCoord = (positions.xy + 0.5) * v_patchSize;

  vec2 pos = (patchPos + v_vertexCoord) / vec2(surfacesAtlasTextureSize);
  gl_Position = vec4((pos - 0.5) * 2.0, 0.0, 1.0);
}
`
)

shaderLibrary.setShaderModule(
  'GLSLCADSurfaceFragmentShader.glsl',
  `

struct PosNorm {
  vec3 pos;
  vec3 normal;
  int geomType;
};

/* VS Outputs */
varying float v_surfaceId;      // flat
varying vec3 v_geomDataCoords;  // flat
varying vec2 v_patchSize;       // flat
varying vec2 v_vertexCoord;

uniform sampler2D surfaceDataTexture;
uniform ivec2 surfaceDataTextureSize;
uniform int writeNormals;

vec2 initReader(inout GLSLBinReader reader) {

  // compute exact xy coords per pixel by rounding the vertex coord to the nearest integer and then dividing my patch size.
  // The interpollated xy coords from the quad are not exact because the quad must cover the pixels with some margin.

  vec2 params = vec2(floor(v_vertexCoord.x), floor(v_vertexCoord.y));
  if(v_patchSize.x > 1.0)
    params.x /= v_patchSize.x - 1.0;
  if(v_patchSize.y > 1.0)
    params.y /= v_patchSize.y - 1.0;

  ivec4 region = ivec4(0, 0, surfaceDataTextureSize.x, surfaceDataTextureSize.y);
  ivec2 start = ivec2(v_geomDataCoords.xy);
  int flags = int(v_geomDataCoords.z);
  if(testFlag(flags, SURFACE_FLAG_FLIPPED_UV))  {
    float tmp = params.x;
    params.x = params.y;
    params.y = tmp;
  }

  GLSLBinReader_init(reader, surfaceDataTextureSize, region, start, 32);

  return params;
}


struct box2 {
  vec2 p0;
  vec2 p1;
};

box2 loadBox2(inout GLSLBinReader reader, sampler2D texture) {
  box2 domain;
  domain.p0.x = GLSLBinReader_readFloat(reader, texture);
  domain.p0.y = GLSLBinReader_readFloat(reader, texture);
  domain.p1.x = GLSLBinReader_readFloat(reader, texture);
  domain.p1.y = GLSLBinReader_readFloat(reader, texture);
  return domain;
}

vec2 mapDomain(box2 domain, vec2 params) {
  return domain.p0 + params * ( domain.p1 - domain.p0 );
}


`
)

/** Class representing a GL evaluate simple CAD surface shader.
 * @extends GLShader
 * @ignore
 */
class GLEvaluateSimpleCADSurfaceShader extends GLShader {
  /**
   * Create a GL evaluate simple CAD surface shader.
   * @param {any} gl - The gl value.
   */
  constructor(gl) {
    super(gl)

    this.__shaderStages['VERTEX_SHADER'] = GLEvaluateCADSurfaceShader_VERTEX_SHADER

    this.__shaderStages['FRAGMENT_SHADER'] = shaderLibrary.parseShader(
      'GLEvaluateSimpleCADSurfaceShader.fragmentShader',
      `
// #extension GL_EXT_draw_buffers : require
precision highp float;

<%include file="GLSLUtils.glsl"/>
<%include file="GLSLBinReader.glsl"/>
<%include file="GLSLCADConstants.glsl"/>
<%include file="GLSLCADSurfaceFragmentShader.glsl"/>

<%include file="GLSLCADSimpleSurfaces.glsl"/>

PosNorm evalCADSurfaces(vec2 params, inout GLSLBinReader reader, sampler2D texture) {
  // Evaluate the surface per vertex
  int geomType = GLSLBinReader_readInt(reader, texture);

  PosNorm posNorm;
  if(geomType == SURFACE_TYPE_PLANE) {
    posNorm = calcPlaneSurfacePoint(params, reader, texture);
  } else if(geomType == SURFACE_TYPE_POLY_PLANE) {
    posNorm = calcPolyPlaneSurfacePoint(params, reader, texture);
  } else if(geomType == SURFACE_TYPE_FAN) {
    posNorm = calcFanSurfacePoint(params, reader, texture);
  } else if(geomType == SURFACE_TYPE_CONE) {
    posNorm = calcConeSurfacePoint(params, reader, texture);
  } else if(geomType == SURFACE_TYPE_CYLINDER) {
    posNorm = calcCylinderSurfacePoint(params, reader, texture);
  } else if(geomType == SURFACE_TYPE_SPHERE) {
    posNorm = calcSphereSurfacePoint(params, reader, texture);
  } else if(geomType == SURFACE_TYPE_TORUS) {
    posNorm = calcTorusSurfacePoint(params, reader, texture);
  }
  return posNorm;
}

#ifdef ENABLE_ES3
out vec4 fragColor;
#endif

void main(void) {

#ifndef ENABLE_ES3
  vec4 fragColor;
#endif
  
  GLSLBinReader reader;
  vec2 xy = initReader(reader);
  PosNorm posNorm = evalCADSurfaces(xy, reader, surfaceDataTexture);

  if(writeNormals == 1) {
    fragColor = vec4(posNorm.normal, float(posNorm.geomType));
  }
  else {
    fragColor = vec4(posNorm.pos, float(posNorm.geomType));
  }
  // gl_FragData[0] = vec4(posNorm.pos, 1.0);
  // gl_FragData[1] = vec4(posNorm.normal, 1.0);

  // fragColor.r = v_geomDataCoords.x;
  // fragColor.g = v_geomDataCoords.y;
  // fragColor.r = floor(v_vertexCoord.x);
  // fragColor.g = floor(v_vertexCoord.y);
  // fragColor.b = v_patchSize.x;
  // fragColor.a = v_patchSize.y;

#ifndef ENABLE_ES3
  gl_FragColor = fragColor;
#endif
}
`
    )
  }
}

/** Class representing a GL evaluate compound CAD surface shader.
 * @extends GLShader
 * @ignore
 */
class GLEvaluateCompoundCADSurfaceShader extends GLShader {
  /**
   * Create a GL evaluate compound CAD surface shader.
   * @param {any} gl - The gl value.
   */
  constructor(gl) {
    super(gl)

    this.__shaderStages['VERTEX_SHADER'] = GLEvaluateCADSurfaceShader_VERTEX_SHADER

    this.__shaderStages['FRAGMENT_SHADER'] = shaderLibrary.parseShader(
      'GLEvaluateCompoundCADSurfaceShader.fragmentShader',
      `
// #extension GL_EXT_draw_buffers : require
precision highp float;

<%include file="GLSLCADConstants.glsl"/>
<%include file="GLSLUtils.glsl"/>
<%include file="GLSLBinReader.glsl"/>
<%include file="GLSLCADSurfaceFragmentShader.glsl"/>

<%include file="GLSLMath.glsl"/>

uniform sampler2D curvesAtlasTexture;
uniform ivec2 curvesAtlasTextureSize;
uniform sampler2D curveTangentsTexture;
uniform sampler2D curvesAtlasLayoutTexture;
uniform ivec2 curvesAtlasLayoutTextureSize;

vec3 getCurveVertex(ivec2 curvePatchCoords, int vertexCoord) {
  return fetchTexel(curvesAtlasTexture, curvesAtlasTextureSize, ivec2(curvePatchCoords.x + vertexCoord, curvePatchCoords.y)).rgb;
}

vec3 getCurveTangent(ivec2 curvePatchCoords, int vertexCoord) {
  return fetchTexel(curveTangentsTexture, curvesAtlasTextureSize, ivec2(curvePatchCoords.x + vertexCoord, curvePatchCoords.y)).rgb;
}

PosNorm evalCADCurve3d(int curveId, float u) {

  GLSLBinReader curveLayoutDataReader;
  GLSLBinReader_init(curveLayoutDataReader, curvesAtlasLayoutTextureSize, 32);
  ivec4 curvePatch = ivec4(GLSLBinReader_readVec4(curveLayoutDataReader, curvesAtlasLayoutTexture, curveId * 8));

  float t = float(curvePatch.z - 1) * u;
  int vertexId0 = min(int(floor(t + 0.5)), curvePatch.z - 1);
  // int vertexId1 = floor(t) + 1.0;
  // float lerp = t - floor(t);

  vec3 p0 = getCurveVertex(curvePatch.xy, vertexId0);
  // vec3 p1 = getCurveVertex(curvePatch.xy, vertexId1);
  vec3 t0 = getCurveTangent(curvePatch.xy, vertexId0);
  // vec3 t1 = getCurveTangent(curvePatch.xy, vertexId1);

  PosNorm res;
  res.pos = p0;//mix(p0, p1, lerp);
  res.normal = normalize(t0);//mix(t0, t1, lerp));
  res.geomType = 0;

  // res.pos.x = u;
  // res.pos.y = float(curveId);
  // res.pos.x = float(curvePatch.x);
  // res.pos.y = float(curvePatch.y);
  // res.pos.z = float(curvePatch.z);
  return res;
}


<%include file="GLSLCADCompoundSurfaces.glsl"/>

PosNorm evalCADSurfaces(vec2 params, inout GLSLBinReader reader, sampler2D texture) {
  // Evaluate the surface per vertex
  int geomType = GLSLBinReader_readInt(reader, texture);

  PosNorm posNorm;
  if(geomType == SURFACE_TYPE_LINEAR_EXTRUSION) {
    posNorm = calcLinearExtrusionSurfacePoint(params, reader, texture);
  } else if(geomType == SURFACE_TYPE_REVOLUTION) {
    posNorm = calcRevolutionSurfacePoint(params, reader, texture, false);
  } else if(geomType == SURFACE_TYPE_REVOLUTION_FLIPPED_DOMAIN) {
    posNorm = calcRevolutionSurfacePoint(params, reader, texture, true);
  } 
  
  return posNorm;
}

#ifdef ENABLE_ES3
out vec4 fragColor;
#endif

void main(void) {

#ifndef ENABLE_ES3
  vec4 fragColor;
#endif
  
  GLSLBinReader reader;
  vec2 xy = initReader(reader);
  PosNorm posNorm = evalCADSurfaces(xy, reader, surfaceDataTexture);

  if(writeNormals == 1) {
    fragColor = vec4(posNorm.normal, float(posNorm.geomType));
  }
  else {
    fragColor = vec4(posNorm.pos, float(posNorm.geomType));
  }

#ifndef ENABLE_ES3
  gl_FragColor = fragColor;
#endif
}
`
    )
  }
}

/** Class representing a GL evaluate NURBS CAD surface shader.
 * @extends GLShader
 * @ignore
 */
class GLEvaluateNURBSCADSurfaceShader extends GLShader {
  /**
   * Create a GL evaluate NURBS CAD surface shader.
   * @param {any} gl - The gl value.
   */
  constructor(gl) {
    super(gl)

    this.__shaderStages['VERTEX_SHADER'] = GLEvaluateCADSurfaceShader_VERTEX_SHADER

    this.__shaderStages['FRAGMENT_SHADER'] = shaderLibrary.parseShader(
      'GLEvaluateNURBSCADSurfaceShader.fragmentShader',
      `
// #extension GL_EXT_draw_buffers : require
precision highp float;

<%include file="GLSLCADConstants.glsl"/>
<%include file="GLSLUtils.glsl"/>
<%include file="GLSLBinReader.glsl"/>
<%include file="GLSLCADSurfaceFragmentShader.glsl"/>

<%include file="GLSLNURBS.glsl"/>
<%include file="GLSLNURBSSurfaces.glsl"/>

PosNorm evalCADSurfaces(vec2 params, inout GLSLBinReader reader, sampler2D texture) {
  // Evaluate the surface per vertex
  int geomType = GLSLBinReader_readInt(reader, texture);

  PosNorm posNorm;
  if(geomType == SURFACE_TYPE_NURBS_SURFACE) {
    posNorm = calcNURBSSurfacePoint(params, reader, texture);
  }
  
  return posNorm;
}

#ifdef ENABLE_ES3
out vec4 fragColor;
#endif

void main(void) {

#ifndef ENABLE_ES3
  vec4 fragColor;
#endif
  
  GLSLBinReader reader;
  vec2 xy = initReader(reader);
  PosNorm posNorm = evalCADSurfaces(xy, reader, surfaceDataTexture);

  if(writeNormals == 1) {
    fragColor = vec4(posNorm.normal, float(posNorm.geomType));
  }
  else {
    fragColor = vec4(posNorm.pos, float(posNorm.geomType));
  }

#ifndef ENABLE_ES3
  gl_FragColor = fragColor;
#endif
}
`
    )
  }
}

export { GLEvaluateSimpleCADSurfaceShader, GLEvaluateCompoundCADSurfaceShader, GLEvaluateNURBSCADSurfaceShader }
