import { EventEmitter, GLFbo, GLTexture2D } from '@zeainc/zea-engine'
import { valuesPerSurfaceLibraryLayoutItem } from './CADConstants.js'
import { BinReader } from './Math/BinReader.js'

import {
  GLEvaluateSimpleCADSurfaceShader,
  GLEvaluateCompoundCADSurfaceShader,
  GLEvaluateNURBSCADSurfaceShader,
} from './GLEvaluateCADSurfaceShader.js'

/** Class representing a GL surface library.
 * @ignore
 */
class GLSurfaceLibrary extends EventEmitter {
  /**
   * Create a GL surface library.
   * @param {any} gl - The gl value.
   * @param {any} cadpassdata - The cadpassdata value.
   * @param {any} surfacesLibrary - The surfacesLibrary value.
   * @param {any} glCurveLibrary - The glCurveLibrary value.
   */
  constructor(gl, cadpassdata, surfacesLibrary, glCurveLibrary, version) {
    super()
    this.__gl = gl
    this.__cadpassdata = cadpassdata
    this.__surfacesLibrary = surfacesLibrary
    this.__glCurveLibrary = glCurveLibrary
    this.cadDataVersion = version

    const surfacesDataBuffer = this.__surfacesLibrary.getSurfaceBuffer()
    const surfaceTexSize = Math.sqrt(surfacesDataBuffer.byteLength / 8)

    this.__surfaceDataTexture = new GLTexture2D(gl, {
      format: 'RGBA',
      type: 'HALF_FLOAT',
      width: surfaceTexSize,
      height: surfaceTexSize,
      filter: 'NEAREST',
      wrap: 'CLAMP_TO_EDGE',
      mipMapped: false,
      data: new Uint16Array(surfacesDataBuffer),
    })

    this.__bindAttr = (location, channels, type, stride, offset, instanced = true) => {
      gl.enableVertexAttribArray(location)
      gl.vertexAttribPointer(location, channels, gl.FLOAT, false, stride, offset)
      if (instanced) gl.vertexAttribDivisor(location, 1) // This makes it instanced
    }

    this.evaluateSurfaceShaders = []
    this.__surfaceDrawSets = {}
  }

  // /////////////////////////////////////////////////////////////
  // Surfaces

  /**
   * The drawSurfaceData method.
   * @return {boolean} - The return value.
   */
  drawSurfaceData() {
    const renderstate = {}
    if (!this.__surfaceDataTexture || !this.__cadpassdata.debugTrimSetsShader.bind(renderstate)) return false
    // this.bindTrimSetAtlas(renderstate);

    this.__surfaceDataTexture.bindToUniform(renderstate, renderstate.unifs.trimSetAtlasTexture)
    this.__cadpassdata.glplanegeom.bind(renderstate)
    this.__cadpassdata.glplanegeom.draw()
  }

  /**
   * The evaluateSurfaces method.
   * @param {any} surfacesEvalAttrs - The surfacesEvalAttrs param.
   * @param {any} surfacesAtlasLayout - The surfacesAtlasLayout param.
   * @param {any} surfaceAtlasLayoutTextureSize - The surfaceAtlasLayoutTextureSize param.
   * @param {any} surfacesAtlasTextureDim - The surfacesAtlasTextureDim param.
   * @return {any} - The return value.
   */
  evaluateSurfaces(surfacesEvalAttrs, surfacesAtlasLayout, surfaceAtlasLayoutTextureSize, surfacesAtlasTextureDim) {
    // console.log("evaluateSurfaces");
    const t0 = performance.now()

    const totalSurfaceCount = surfacesAtlasLayout.length / valuesPerSurfaceLibraryLayoutItem
    if (totalSurfaceCount == 0) return
    const gl = this.__gl

    {
      this.__surfaceAtlasLayoutTexture = new GLTexture2D(gl, {
        format: 'RGBA',
        type: 'FLOAT',
        width: surfaceAtlasLayoutTextureSize[0],
        height: surfaceAtlasLayoutTextureSize[1],
        filter: 'NEAREST',
        wrap: 'CLAMP_TO_EDGE',
        mipMapped: false,
        data: surfacesAtlasLayout,
      })
    }

    if (!this.__surfacesAtlasTexture) {
      this.__surfacesAtlasTexture = new GLTexture2D(gl, {
        format: 'RGBA',
        type: 'FLOAT',
        width: surfacesAtlasTextureDim[0],
        height: surfacesAtlasTextureDim[1],
        filter: 'NEAREST',
        wrap: 'CLAMP_TO_EDGE',
        mipMapped: false,
      })
      this.__surfacesFbo = new GLFbo(gl, this.__surfacesAtlasTexture)
      this.__surfacesFbo.setClearColor([0, 0, 0, 0])
      this.__surfacesFbo.bindAndClear()

      this.__normalsTexture = new GLTexture2D(gl, {
        format: 'RGBA',
        type: 'FLOAT',
        width: surfacesAtlasTextureDim[0],
        height: surfacesAtlasTextureDim[1],
        filter: 'NEAREST',
        wrap: 'CLAMP_TO_EDGE',
        mipMapped: false,
      })
      this.__normalsFbo = new GLFbo(gl, this.__normalsTexture)
      this.__normalsFbo.setClearColor([0, 0, 0, 0])
      this.__normalsFbo.bindAndClear()
    } else if (
      this.__surfacesAtlasTexture.width != surfacesAtlasTextureDim[0] ||
      this.__surfacesAtlasTexture.height != surfacesAtlasTextureDim[1]
    ) {
      // Copy the previous image into a new one, and then destroy the prvious.
      this.__surfacesAtlasTexture.resize(surfacesAtlasTextureDim[0], surfacesAtlasTextureDim[1], true)
      this.__surfacesFbo.resize() // hack to rebind the texture. Refactor the way textures are resized.
    }

    const renderstate = {}

    // /////////////////////////////////////////////
    // Precompile shaders.
    const shaderopts = { directives: [...gl.shaderopts.directives] }

    if (this.cadDataVersion.compare([0, 0, 6]) >= 0) {
      shaderopts.directives.push('#define EXPORT_KNOTS_AS_DELTAS 1')
    }
    if (this.cadDataVersion.compare([0, 0, 26]) > 0) {
      shaderopts.directives.push('#define INTS_PACKED_AS_2FLOAT16 1')
    }

    surfacesEvalAttrs.forEach((attr, category) => {
      if (!this.evaluateSurfaceShaders[category]) {
        let shader
        switch (category) {
          case 0:
            shader = new GLEvaluateSimpleCADSurfaceShader(gl)
            break
          case 1:
            shader = new GLEvaluateCompoundCADSurfaceShader(gl)
            break
          case 2:
            shader = new GLEvaluateNURBSCADSurfaceShader(gl)
            break
        }
        shader.compileForTarget(undefined, shaderopts)
        this.evaluateSurfaceShaders[category] = shader
      }
      this.evaluateSurfaceShaders[category].bind(renderstate)
      this.__cadpassdata.glplanegeom.bind(renderstate)

      const unifs = renderstate.unifs
      const attrs = renderstate.attrs

      this.__surfaceAtlasLayoutTexture.bindToUniform(renderstate, unifs.surfaceAtlasLayoutTexture)
      gl.uniform2i(
        unifs.surfaceAtlasLayoutTextureSize.location,
        this.__surfaceAtlasLayoutTexture.width,
        this.__surfaceAtlasLayoutTexture.height
      )

      gl.uniform2i(
        unifs.surfacesAtlasTextureSize.location,
        this.__surfacesAtlasTexture.width,
        this.__surfacesAtlasTexture.height
      )

      this.__surfaceDataTexture.bindToUniform(renderstate, unifs.surfaceDataTexture)
      gl.uniform2i(
        unifs.surfaceDataTextureSize.location,
        this.__surfaceDataTexture.width,
        this.__surfaceDataTexture.height
      )

      // For the linear and radial loft.
      if (unifs.curveTangentsTexture) this.__glCurveLibrary.bindCurvesAtlas(renderstate)

      const buffer = gl.createBuffer()
      gl.bindBuffer(gl.ARRAY_BUFFER, buffer)
      gl.bufferData(gl.ARRAY_BUFFER, attr, gl.STATIC_DRAW)

      this.__bindAttr(attrs.surfaceId.location, 1, gl.FLOAT, 4, 0)

      // //////////////////////////////////////////////
      // Bind each Fbo and render separately.
      // Bizzarly, this has turned out to be much faster
      // than using mutiple render targets...
      this.__surfacesFbo.bind()
      gl.uniform1i(unifs.writeNormals.location, 0)
      this.__cadpassdata.glplanegeom.drawInstanced(attr.length)

      this.__normalsFbo.bind()
      gl.uniform1i(unifs.writeNormals.location, 1)
      this.__cadpassdata.glplanegeom.drawInstanced(attr.length)
      // //////////////////////////////////////////////

      gl.deleteBuffer(buffer)
    })
    this.__surfacesFbo.unbind()

    this.__surfacesAtlasLayout = surfacesAtlasLayout
    // console.log("----------------------------------");
    // // console.log(surfacesAtlasLayout);
    const logSurfaceData = (surfaceId) => {
      this.__surfacesFbo.bindForReading()
      const layout = [
        surfacesAtlasLayout[surfaceId * valuesPerSurfaceLibraryLayoutItem + 0],
        surfacesAtlasLayout[surfaceId * valuesPerSurfaceLibraryLayoutItem + 1],
        surfacesAtlasLayout[surfaceId * valuesPerSurfaceLibraryLayoutItem + 2],
        surfacesAtlasLayout[surfaceId * valuesPerSurfaceLibraryLayoutItem + 3],
      ]
      console.log('----------------------------------')
      console.log(
        'logSurfaceData ' + surfaceId + ':[' + layout[0] + ',' + layout[1] + ']:' + layout[2] + 'x' + layout[3]
      )
      const pixels = new Float32Array(layout[2] * 4)
      for (let i = 0; i < layout[3]; i++) {
        gl.readPixels(layout[0], layout[1] + i, layout[2], 1, gl.RGBA, gl.FLOAT, pixels)
        for (let j = 0; j < layout[2]; j++) {
          console.log(i, j, ':', pixels[j * 4 + 0], pixels[j * 4 + 1], pixels[j * 4 + 2], pixels[j * 4 + 3])
          break
        }
        // console.log(i+":" + pixels);
      }
    }
    // logSurfaceData(9628)
    // console.log("----------------------------------");

    const t = performance.now() - t0
    // console.log("evaluateSurfaces - Done:", t);

    return t
  }

  /**
   * The logSurfaceData method.
   * @param {any} surfaceId - The surfaceId param.
   */
  logSurfaceData(surfaceId) {
    // const layout = [
    //   this.__surfacesAtlasLayout[(surfaceId * valuesPerSurfaceLibraryLayoutItem) + 0],
    //   this.__surfacesAtlasLayout[(surfaceId * valuesPerSurfaceLibraryLayoutItem) + 1],
    //   this.__surfacesAtlasLayout[(surfaceId * valuesPerSurfaceLibraryLayoutItem) + 2],
    //   this.__surfacesAtlasLayout[(surfaceId * valuesPerSurfaceLibraryLayoutItem) + 3]];

    // console.log("logGeomData " + surfaceId + ":[" + layout[0] +","+ layout[1] + "] detail :" + layout[2] +"x"+ layout[3]);

    const surfacesDataBuffer = this.__surfacesLibrary.getSurfaceBuffer()
    const surfacesDataReader = new BinReader(surfacesDataBuffer)
    surfacesDataReader.seek(8 + surfaceId * (8 /* num values per item*/ * 2) /* bpc*/ + 2 /* addr*/ * 2 /* bpc*/)

    const detailU = surfacesDataReader.loadFloat16()
    const detailV = surfacesDataReader.loadFloat16()
    const sizeU = surfacesDataReader.loadFloat16()
    const sizeV = surfacesDataReader.loadFloat16()
    const trimSetIndex = surfacesDataReader.loadFloat16()
    console.log(
      'logGeomData ' +
        surfaceId +
        ' detailU:[' +
        detailU +
        ',' +
        detailV +
        '] sizeU [' +
        sizeU +
        ',' +
        sizeV +
        '] trimSetIndex:' +
        trimSetIndex
    )
  }

  /**
   * The drawSurfaceAtlas method.
   * @param {any} renderstate - The renderstate param.
   * @return {boolean} - The return value.
   */
  drawSurfaceAtlas(renderstate) {
    if (!this.__normalsTexture || !this.__cadpassdata.debugTrimSetsShader.bind(renderstate)) return false
    // this.bindTrimSetAtlas(renderstate);

    this.__normalsTexture.bindToUniform(renderstate, renderstate.unifs.trimSetAtlasTexture)
    this.__cadpassdata.glplanegeom.bind(renderstate)
    this.__cadpassdata.glplanegeom.draw()
  }

  /**
   * The bindSurfacesData method.
   * @param {any} renderstate - The renderstate param.
   */
  bindSurfacesData(renderstate) {
    const gl = this.__gl
    const unifs = renderstate.unifs
    this.__surfaceDataTexture.bindToUniform(renderstate, unifs.surfaceDataTexture)
    gl.uniform2i(
      unifs.surfaceDataTextureSize.location,
      this.__surfaceDataTexture.width,
      this.__surfaceDataTexture.height
    )
  }

  /**
   * The bindSurfacesAtlas method.
   * @param {any} renderstate - The renderstate param.
   * @return {boolean} - returns true if the atlass was bound.
   */
  bindSurfacesAtlas(renderstate) {
    if (!this.__surfacesAtlasTexture) return false
    const unifs = renderstate.unifs
    this.__surfacesAtlasTexture.bindToUniform(renderstate, unifs.surfacesAtlasTexture)
    const gl = this.__gl
    if (unifs.normalsTexture) this.__normalsTexture.bindToUniform(renderstate, unifs.normalsTexture)
    if (unifs.surfacesAtlasTextureSize)
      gl.uniform2i(
        unifs.surfacesAtlasTextureSize.location,
        this.__surfacesAtlasTexture.width,
        this.__surfacesAtlasTexture.height
      )

    if (unifs.numSurfacesInLibrary) {
      gl.uniform1i(unifs.numSurfacesInLibrary.location, this.__surfacesLibrary.getNumSurfaces())
    }

    if (unifs.surfaceAtlasLayoutTexture) {
      this.__surfaceAtlasLayoutTexture.bindToUniform(renderstate, unifs.surfaceAtlasLayoutTexture)
      gl.uniform2i(
        unifs.surfaceAtlasLayoutTextureSize.location,
        this.__surfaceAtlasLayoutTexture.width,
        this.__surfaceAtlasLayoutTexture.height
      )
    }
    return true
  }

  /**
   * The getSurfaceData method.
   * @param {any} surfaceId - The surfaceId param.
   * @return {any} - The return value.
   */
  getSurfaceData(surfaceId) {
    return this.__surfacesLibrary.getSurfaceData(surfaceId)
  }

  /**
   * The destroy method.
   */
  destroy() {
    this.__surfaceDataTexture.destroy()
    if (this.__surfacesAtlasTexture) {
      this.__surfacesAtlasTexture.destroy()
      this.__normalsTexture.destroy()
      this.__surfacesFbo.destroy()
      this.__normalsFbo.destroy()
    }
  }
}

export { GLSurfaceLibrary }
