import { shaderLibrary } from '@zeainc/zea-engine'

shaderLibrary.setShaderModule(
  'GLSLCADCurves.glsl',
  `

  // http://cadexchanger.com/download/sdk/doc/dev/html/sdk_data_model_geometry_topology.html#sdk_data_model_geometry_curves



// http://cadexchanger.com/download/sdk/doc/dev/html/sdk_data_model_geometry_topology.html#sdk_data_model_geometry_surfaces


vec2 loadVec2(inout GLSLBinReader reader, sampler2D texture) {
  return vec2(GLSLBinReader_readFloat(reader, texture),  GLSLBinReader_readFloat(reader, texture));
}

float mapDomain1d(vec2 domain, float param) {
  float u = domain.x + param * ( domain.y - domain.x );
  return u;
}

/////////////////////////////////////////
// Line

PosNorm calcLinePoint(float param, inout GLSLBinReader reader, sampler2D texture) {
  vec2 domain = loadVec2(reader, texture);
  float u = mapDomain1d( domain, param );
  vec3 pos = vec3(u, 0.0, 0.0);
  vec3 norm = vec3(1.0, 0.0, 0.0);
  return PosNorm(pos, norm, CURVE_TYPE_LINE);
}


/////////////////////////////////////////
// Circle

PosNorm calcCirclePoint(float param, inout GLSLBinReader reader, sampler2D texture) {
  vec2 domain = loadVec2(reader, texture);
  float radius = GLSLBinReader_readFloat(reader, texture);

  float u = mapDomain1d( domain, param );
  vec3 pos = vec3(cos(u) * radius, sin(u) * radius, 0.0);
  vec3 norm = vec3(-sin(u), cos(u), 0.0);
  return PosNorm(pos, norm, CURVE_TYPE_CIRCLE);
}



/////////////////////////////////////////
// Elipse3d
// An ellipse is a periodic curve parametrized as follows: C(t) = P + X * R_major * cos(t) + Y * R_minor * sin(t), where

// P is an origin point,
// X and Y are directions,
// R_major and R_minor are major and minor radii,
// t belongs to [0, 2 * PI].
// Note that major radius is always along the X-axis and minor radius - along the Y-axis, and that with t=0 the point corresponds to a major radius.


PosNorm calcElipsePoint(float param, inout GLSLBinReader reader, sampler2D texture) {
  vec2 domain = loadVec2(reader, texture);
  float u = mapDomain1d( domain, param );
  float minorRadius = GLSLBinReader_readFloat(reader, texture);
  float majorRadius = GLSLBinReader_readFloat(reader, texture);
  vec3 pos = vec3(cos(u) * majorRadius, sin(u) * minorRadius, 0.0);
  vec3 norm = normalize(vec3(-sin(u) * majorRadius, cos(u) * minorRadius, 0.0)); // TODO: fix this broken line.

  // pos = vec3(float(minorRadius));

  return PosNorm(pos, norm, CURVE_TYPE_ELIPSE);
}

`
)
