/* eslint-disable no-unused-vars */
import {
  Vec2,
  Vec3,
  Color,
  FilePathParameter,
  BooleanParameter,
  NumberParameter,
  ColorParameter,
  Vec3Parameter,
  Version,
  BinReader,
  SystemDesc,
  AssetItem,
  AssetLoadContext,
  TreeItem,
  Registry,
  resourceLoader,
} from '@zeainc/zea-engine'
import { CADSurfaceLibrary } from './CADSurfaceLibrary.js'
import { CADTrimSetLibrary } from './CADTrimSetLibrary.js'
import { CADBodyLibrary } from './CADBodyLibrary.js'
import { CADBody } from './CADBody.js'

const cadFileExts = new RegExp('\\.(stp|step|jt|3dm|ifc|vlcad|zcad)$', 'i')

// eslint-disable-next-line require-jsdoc
function getLOD() {
  const urlParams = new URLSearchParams(window.location.search)
  if (urlParams.has('lod')) {
    return Number.parseInt(urlParams.get('lod'))
  }
  switch (SystemDesc.deviceCategory) {
    case 'Low':
      return 1
    case 'Medium':
      return 2
    case 'High':
      return 3
  }
  return 1
}

/**
 * Class representing a CAD asset.
 *
 * **Parameters**
 * * **DataFilePath(`FilePathParameter`):** Hosts file information used to load the asset(Like the URL).
 * * **DisplayEdges(`BooleanParameter`):** Hides/Shows edges of the asset(Like a border).
 * * **EdgeColor(`ColorParameter`):** Specifies the color of the edge(border-color).
 *
 * **Events**
 * * **loaded:** Triggered when the  asset is loaded
 * @extends AssetItem
 */
class CADAsset extends AssetItem {
  /**
   * Create a CAD asset.
   * @param {string} name - The name value.
   */
  constructor(name) {
    super(name)

    this.__trimSetLibrary = new CADTrimSetLibrary()
    this.__surfaceLibrary = new CADSurfaceLibrary(this, this.__trimSetLibrary)
    this.__bodyLibrary = new CADBodyLibrary()
    this.__atlasSize = new Vec2()
    this.__numCADBodyItems = 0
    this.__loaded = false

    this.__datafileParam = this.addParameter(new FilePathParameter('FilePath'))
    this.addParameterDeprecationMapping('DataFilePath', 'FilePath')
    this.__datafileParam.on('valueChanged', () => {
      let url
      const file = this.__datafileParam.getFileDesc()
      if (file.metadata && file.metadata.ConvertFile) {
        let zcadFile
        const zcadfileext = new RegExp('\\.(vlcad|zcad)$', 'i')
        file.metadata.ConvertFile.map((metadataFile) => {
          if (zcadfileext.test(metadataFile.filename)) zcadFile = metadataFile
        })
        if (zcadFile) {
          url = zcadFile.url
        } else {
          console.warn('ConvertFile metadata contains no vla file.')
        }
      } else if (file.url) {
        url = file.url
      } else {
        console.warn('CADAsset unable to load file:', file)
      }

      this.load(url)
    })

    this.addParameter(new BooleanParameter('DisplayEdges', true))
    this.addParameter(new ColorParameter('EdgeColor', new Color(0.1, 0.1, 0.1, 0.75)))

    this.lod = getLOD()
    this.curvatureToDetail = 0.5
  }

  /**
   * Returns the loaded status of the AssetItem.
   *
   * @return {boolean} - Returns true if the asset has already loaded its data.
   */
  isLoaded() {
    return this.__loaded
  }

  /**
   * Returns `LOD` parameter value.
   *
   * @return {number} - The return value.
   */
  getLOD() {
    return Math.max(0, this.lod)
  }

  /**
   * The incCADBodyCount method.
   * @private
   */
  incCADBodyCount() {
    this.__numCADBodyItems++
  }

  /**
   * Returns the value of number CADBody items in the asset.
   *
   * @return {number} - The return value.
   */
  getNumBodyItems() {
    return this.__numCADBodyItems
  }

  /**
   * Returns the instantiated `CADSurfaceLibrary` object on current Asset
   *
   * @return {CADSurfaceLibrary} - The return value.
   */
  getSurfaceLibrary() {
    return this.__surfaceLibrary
  }

  /**
   * Returns the instantiated `CADTrimSetLibrary` object of current Asset
   *
   * @return {CADTrimSetLibrary} - The return value.
   */
  getTrimSetLibrary() {
    return this.__trimSetLibrary
  }

  /**
   * Returns the instantiated `CADBodyLibrary` object of current Asset
   *
   * @return {CADBodyLibrary} - The return value.
   */
  getBodyLibrary() {
    return this.__bodyLibrary
  }

  /**
   * Returns the instantiated `MaterialLibrary` object of current Asset
   *
   * @return {MaterialLibrary} - The return value.
   */
  getMaterialLibrary() {
    return this.__materials
  }

  // ////////////////////////////////////////
  // Persistence

  /**
   * Returns the versioon of the data loaded by thie CADAsset.
   *
   * @return {string} - The return value.
   */
  getVersion() {
    return this.cadfileversion
  }

  /**
   * Initializes CADAsset's asset, material, version and layers; adding current `CADAsset` Geometry Item toall the layers in reader
   *
   * @param {BinReader} reader - The reader param.
   * @param {AssetLoadContext} context - The load context object that provides additional data such as the units of the scene we are loading into.
   */
  readRootLevelBinary(reader, context) {
    this.__numCADBodyItems = 0

    context.versions['zea-cad'] = new Version(reader.loadStr())
    context.sdk = reader.loadStr()
    this.cadfileversion = context.versions['zea-cad']
    // console.log('Loading CAD File version:', this.cadfileversion, ' exported using SDK:', context.cadSDK)

    super.readBinary(reader, context)
  }

  /**
   * Loads all the geometries and metadata from the asset file.
   * @param {string} url - The URL of the asset to load
   * @param {AssetLoadContext} context - The load context object that provides additional data such as paths to external references.
   * @return {Promise} - Returns a promise that resolves once the load of the tree is complete. Geometries, textures and other resources might still be loading.
   */
  load(url, context = new AssetLoadContext()) {
    if (this.__loadPromise) return this.__loadPromise
    this.__loadPromise = new Promise((resolve, reject) => {
      const folder = url.lastIndexOf('/') > -1 ? url.substring(0, url.lastIndexOf('/')) + '/' : ''
      const filename = url.lastIndexOf('/') > -1 ? url.substring(url.lastIndexOf('/') + 1) : ''
      const stem = filename.substring(0, filename.lastIndexOf('.'))

      this.url = url

      // These values are used by XRef to generate URLS.
      context.assetItem = this
      context.url = url
      context.folder = folder
      if (!context.resources) context.resources = {}
      context.xrefs = {}

      context.on('done', () => {
        this.__loaded = true
        resolve()
        this.emit('loaded')
      })

      context.incrementAsync()

      // Increment the resource loader counter to provided an update to the progress bar.
      // preload in case we don't have embedded geoms.
      // completed by geomLibrary.on('loaded' ..
      resourceLoader.incrementWorkload(1)
      this.__geomLibrary.once('loaded', () => {
        // A chunk of geoms are now parsed, so update the resource loader.
        resourceLoader.incrementWorkDone(1)
      })

      resourceLoader.loadFile('archive', url).then(
        (entries) => {
          // const desc = entries['desc.json']
          //   ? JSON.parse(new TextDecoder('utf-8').decode(entries['desc.json']))
          //   : { numGeomFiles: 0 }

          const treeReader = new BinReader((entries.tree2 || entries.tree).buffer, 0, SystemDesc.isMobileDevice)

          if (entries.bodies) {
            this.__bodyLibrary.setBinaryBuffers(entries.bodiestoc.buffer, entries.bodies.buffer)
          }

          const name = this.getName()
          this.readRootLevelBinary(treeReader, context)

          // Maintain the name provided by the user before loading.
          if (name != '') this.setName(name)

          context.versions['zea-cad'] = this.getVersion()
          context.versions['zea-engine'] = this.getEngineDataVersion()

          if (entries.geoms) {
            this.__geomLibrary.readBinaryBuffer(filename, entries.geoms.buffer, context)
          } else if (entries['geomLibrary.json']) {
            entries['desc.json']
            const geomLibraryJSON = JSON.parse(new TextDecoder('utf-8').decode(entries['geomLibrary.json']))
            const basePath = folder + stem
            this.__geomLibrary.loadGeomFilesStream(geomLibraryJSON, basePath, context)
          } else {
            // No geoms in this file, so we won't wait for the 'done' event in the GeomLibrary.
            resourceLoader.incrementWorkDone(1)
          }

          if (entries.trimSets) {
            const trimSets = entries.trimSets || entries.trimsets || entries.trimSets2
            const trimSetReader = new BinReader(trimSets.buffer, 0, SystemDesc.isMobileDevice)

            this.__trimSetLibrary.setBinaryBuffer(trimSetReader, this.getVersion())
          }

          if (entries.curves) {
            this.__surfaceLibrary.setBinaryBuffers(entries.curves.buffer, entries.surfaces.buffer, this.getVersion())
          }

          // console.log(this.__name, " NumBaseItems:", this.getNumBaseItems(), " NumCADBodyItems:", this.__numCADBodyItems)
          context.decrementAsync()
        },
        (error) => {
          resourceLoader.incrementWorkDone(1)
          this.emit('error', error)
          reject(error)
        }
      )
    })

    return this.__loadPromise
  }

  // ////////////////////////////////////////
  // Persistence

  /**
   * The toJSON method encodes this type as a json object for persistences.
   *
   * @param {object} context - The context param.
   * @param {number} flags - The flags param.
   * @return {object} - The return value.
   */
  toJSON(context, flags) {
    const j = super.toJSON(context, flags)
    return j
  }

  /**
   * The fromJSON method decodes a json object for this type.
   *
   * @param {object} j - The json object this item must decode.
   * @param {object} context - The context param.
   * @param {callback} onDone - The onDone param.
   */
  fromJSON(j, context, onDone) {
    const loadAssetJSON = () => {
      const flags = TreeItem.LoadFlags.LOAD_FLAG_LOADING_BIN_TREE_VALUES
      super.fromJSON(j, context, flags, onDone)
      context.decAsyncCount()

      // If the asset is nested within a bigger asset, then
      // this subtree can noow be flagged as loded(and added to the renderer);
      if (!this.__loaded) {
        this.emit('loaded')
        this.__loaded = true
      }
    }

    if (j.params && j.params.DataFilePath) {
      this.__datafileLoaded = loadAssetJSON
      context.incAsyncCount()
      const filePathJSON = j.params.DataFilePath
      delete j.params.DataFilePath
      this.__datafileParam.fromJSON(filePathJSON, context)
    } else {
      loadAssetJSON()
    }
  }

  // ////////////////////////////////////////////////////
  // Debugging

  /**
   * The generatePolygonMeshSurfaces method.
   * @param {number} lod - The lod param.
   * @return {any} - The return value.
   * @private
   */
  generatePolygonMeshSurfaces(lod = 0) {
    // Traverse the tree adding items till we hit the leaves(which are usually GeomItems.)
    // let count = 0
    const surfacesTreeItem = new TreeItem('surfaces')
    const traverse = (treeItem) => {
      treeItem.getChildren().forEach((childItem) => {
        if (childItem instanceof CADBody) {
          const cadBodySurfaces = childItem.generatePolygonMeshSurfaces(lod)
          const globalXfo = childItem.getParameter('GlobalXfo').getValue()
          cadBodySurfaces.getParameter('GlobalXfo').setValue(globalXfo)
          surfacesTreeItem.addChild(cadBodySurfaces)
        } else traverse(childItem)
      })
    }
    traverse(this)

    surfaceLibrary.logFormfactors()
    return surfacesTreeItem
  }

  /**
   * The generateHullGeometry method.
   * @return {any} - The return value.
   * @private
   */
  generateHullGeometry() {
    // Traverse the tree adding items till we hit the leaves(which are usually GeomItems.)
    // let count = 0
    const hullTreeItem = new TreeItem('hull')
    const traverse = (treeItem) => {
      treeItem.getChildren().forEach((childItem) => {
        if (childItem instanceof CADBody) {
          const cadBodyHulls = childItem.generateHullGeometry()
          const globalXfo = childItem.getParameter('GlobalXfo').getValue()
          hullTreeItem.getParameter('GlobalXfo').setValue(globalXfo)
          hullTreeItem.addChild(cadBodyHulls)
        } else traverse(childItem)
      })
    }
    traverse(this)

    surfaceLibrary.logFormfactors()
    return hullTreeItem
  }

  // ////////////////////////////////////////
  // Static Methods

  /**
   * Getter for LOADSTATE.
   * @return {any} - The return value.
   * @private
   */
  static get LOADSTATE() {
    return LOADSTATE
  }

  /**
   * The supportsExt method.
   * @param {any} filename - The filename param.
   * @return {any} - The return value.
   * @private
   */
  static supportsExt(filename) {
    return cadFileExts.test(filename)
  }
}

Registry.register('CADAsset', CADAsset)

export { CADAsset }
