import { GLFbo, GLTexture2D } from '@zeainc/zea-engine'
import { GLTrimCurveDrawSet } from './GLTrimCurveDrawSet.js'

import { GLDrawTrimCurveFansShader } from './GLDrawTrimCurveFansShader.js'
import { GLFlattenTrimSetsShader } from './GLFlattenTrimSetsShader.js'
import { GLDrawTrimCurveStripsShader } from './GLDrawTrimCurveStripsShader.js'
import { GLDebugTrimSetsShader } from './GLDebugTrimSetsShader.js'

/** Class representing a GL trim set library.
 * @ignore
 */
class GLTrimSetLibrary {
  /**
   * Create a GL trim set library.
   * @param {any} gl - The gl value.
   * @param {any} cadpassdata - The cadpassdata value.
   * @param {any} trimSetLibrary - The trimSetLibrary value.
   * @param {any} glCurvesLibrary - The glCurvesLibrary value.
   */
  constructor(gl, cadpassdata, trimSetLibrary, glCurvesLibrary) {
    this.__gl = gl
    this.__cadpassdata = cadpassdata
    this.__trimSetLibrary = trimSetLibrary
    this.__glCurvesLibrary = glCurvesLibrary

    const trimSetsBuffer = this.__trimSetLibrary.getBinaryBuffer()
    const trimSetsTexSize = Math.sqrt(trimSetsBuffer.byteLength / 8)
    this.__trimSetsTexture = new GLTexture2D(gl, {
      format: 'RGBA',
      type: 'HALF_FLOAT',
      width: trimSetsTexSize,
      height: trimSetsTexSize,
      filter: 'NEAREST',
      wrap: 'CLAMP_TO_EDGE',
      mipMapped: false,
      data: new Uint16Array(trimSetsBuffer)
    })

    this.__bindAttr = (location, channels, type, stride, offset, instanced = true) => {
      gl.enableVertexAttribArray(location)
      gl.vertexAttribPointer(location, channels, gl.FLOAT, false, stride, offset)
      if (instanced) gl.vertexAttribDivisor(location, 1) // This makes it instanced
    }

    this.__trimCurveDrawSets = {}
  }

  // /////////////////////////////////////////////////////////////
  // Trim Sets

  /**
   * The evaluateTrimSets method.
   * @param {any} trimCurveDrawSets - The trimCurveDrawSets param.
   * @param {any} trimSetAtlasTextureSize - The trimSetAtlasTextureSize param.
   * @param {any} trimSetsAtlasLayoutData - The trimSetsAtlasLayoutData param.
   * @param {any} trimSetsAtlasLayoutTextureSize - The trimSetsAtlasLayoutTextureSize param.
   */
  evaluateTrimSets(
    trimCurveDrawSets,
    trimSetAtlasTextureSize,
    trimSetsAtlasLayoutData,
    trimSetsAtlasLayoutTextureSize
  ) {
    // console.log("evaluateTrimSets:" + trimSetAtlasTextureSize);
    const gl = this.__gl

    {
      this.__trimSetsAtlasLayoutTexture = new GLTexture2D(this.__gl, {
        format: 'RGBA',
        type: 'FLOAT',
        width: trimSetsAtlasLayoutTextureSize[0],
        height: trimSetsAtlasLayoutTextureSize[1],
        filter: 'NEAREST',
        wrap: 'CLAMP_TO_EDGE',
        mipMapped: false,
        data: trimSetsAtlasLayoutData
      })
    }

    if (!this.__trimSetAtlasTexture) {
      this.__trimSetAtlasTextureSize = trimSetAtlasTextureSize

      if (
        this.__trimSetAtlasTextureSize[0] > this.__cadpassdata.maxTexSize ||
        this.__trimSetAtlasTextureSize[1] > this.__cadpassdata.maxTexSize
      ) {
        console.warn(
          'trimSetAtlas  is too big to fit in a texture. The image will be downsized:' +
            this.__trimSetAtlasTextureSize +
            ' maxTexSize:' +
            this.__cadpassdata.maxTexSize
        )
      }

      this.__trimSetAtlasMaskTexture = new GLTexture2D(gl, {
        format: gl.name == 'webgl2' ? 'RG' : 'RGBA',
        type: 'UNSIGNED_BYTE',
        width: Math.min(this.__trimSetAtlasTextureSize[0], this.__cadpassdata.maxTexSize),
        height: Math.min(this.__trimSetAtlasTextureSize[1], this.__cadpassdata.maxTexSize),
        filter: 'NEAREST'
      })
      this.__trimSetAtlasMaskFbo = new GLFbo(gl, this.__trimSetAtlasMaskTexture)
      this.__trimSetAtlasMaskFbo.setClearColor([0, 0, 0, 0])

      // Multi-channel signed distance field
      // https://steamcdn-a.akamaihd.net/apps/valve/2007/SIGGRAPH2007_AlphaTestedMagnification.pdf
      // See: 4.3
      const format = gl.name == 'webgl2' ? 'RG' : 'RGBA'
      const filter = 'LINEAR'

      this.__trimSetAtlasTexture = new GLTexture2D(gl, {
        format,
        type: 'UNSIGNED_BYTE',
        width: Math.min(this.__cadpassdata.maxTexSize, this.__trimSetAtlasTextureSize[0]),
        height: Math.min(this.__cadpassdata.maxTexSize, this.__trimSetAtlasTextureSize[1]),
        magFilter: filter,
        minFilter: filter
      })

      this.__trimSetAtlasFbo = new GLFbo(gl, this.__trimSetAtlasTexture)
      this.__trimSetAtlasFbo.setClearColor([0, 0, 0, 0])
    } else if (
      this.__trimSetAtlasTexture.width != trimSetAtlasTextureSize[0] ||
      this.__trimSetAtlasTexture.height != trimSetAtlasTextureSize[1]
    ) {
      this.__trimSetAtlasTextureSize = trimSetAtlasTextureSize

      // Copy the previous image into a new one, and then destroy the prvious.
      this.__trimSetAtlasTexture.resize(trimSetAtlasTextureSize[0], trimSetAtlasTextureSize[1], true)
      this.__trimSetAtlasFbo.resize() // hack to rebind the texture. Refactor the way textures are resized.

      // this.__trimSetAtlasFbo.bind();
    }

    const renderstate = {}

    // ////////////////////////////////////////////////
    // Render the mask aread using fans

    if (!this.trimCurveFansShader) {
      this.trimCurveFansShader = new GLDrawTrimCurveFansShader(gl)
      this.flattenTrimSetsShader = new GLFlattenTrimSetsShader(gl)
      this.trimCurveStripsShader = new GLDrawTrimCurveStripsShader(gl)
      this.debugTrimSetsShader = new GLDebugTrimSetsShader(gl)
    }

    this.__trimSetAtlasMaskFbo.bindAndClear()

    {
      // / Setup additive blending so that all rendering passes accumulate into the same Fbo.
      gl.enable(gl.BLEND)
      gl.blendEquation(gl.FUNC_ADD)
      gl.blendFunc(gl.ONE, gl.ONE)

      this.trimCurveFansShader.bind(renderstate)
      const unifs = renderstate.unifs

      this.__glCurvesLibrary.bindCurvesAtlas(renderstate)

      gl.uniform2i(
        unifs.trimSetAtlasTextureSize.location,
        this.__trimSetAtlasTextureSize[0],
        this.__trimSetAtlasTextureSize[1]
      )

      // For vertex welding (Not yet implemented)
      if (unifs.trimSetTexture) {
        this.__trimSetsTexture.bindToUniform(renderstate, unifs.trimSetTexture)
        if (unifs.trimSetTextureSize)
          gl.uniform2i(unifs.trimSetTextureSize.location, this.__trimSetsTexture.width, this.__trimSetsTexture.height)

        this.__trimSetsAtlasLayoutTexture.bindToUniform(renderstate, unifs.curvesAtlasLayoutTexture)
        if (unifs.curvesAtlasLayoutTextureSize)
          gl.uniform2i(
            unifs.curvesAtlasLayoutTextureSize.location,
            this.__trimSetsAtlasLayoutTexture.width,
            this.__trimSetsAtlasLayoutTexture.height
          )
      }

      for (const key in trimCurveDrawSets) {
        const detail = parseInt(key)
        if (detail < 0) continue
        let trimCurveDrawSet = this.__trimCurveDrawSets[key]
        if (!trimCurveDrawSet) {
          trimCurveDrawSet = new GLTrimCurveDrawSet(this.__gl, detail, trimCurveDrawSets[key])
          this.__trimCurveDrawSets[key] = trimCurveDrawSet
        }
        trimCurveDrawSet.drawFans(renderstate)
      }

      gl.disable(gl.BLEND)
    }

    // ////////////////////////////////////////////////////
    // Render the float texture as a signed distance field

    this.__trimSetAtlasFbo.bindAndClear()

    // if(false)
    {
      // /////////////////////////
      // Flatten the fans texture
      this.flattenTrimSetsShader.bind(renderstate)
      const unifs = renderstate.unifs
      this.__trimSetAtlasMaskTexture.bindToUniform(renderstate, unifs.trimSetAtlasTexture)
      if (unifs.trimSetAtlasTextureSize)
        this.__gl.uniform2i(
          unifs.trimSetAtlasTextureSize.location,
          this.__trimSetAtlasTextureSize[0],
          this.__trimSetAtlasTextureSize[1]
        )
      this.__cadpassdata.glplanegeom.bind(renderstate)
      this.__cadpassdata.glplanegeom.draw()
    }

    // //////////////////////////////////////
    // Draw the Strips to clean up the edges
    // if(false)
    {
      this.trimCurveStripsShader.bind(renderstate)
      const unifs = renderstate.unifs

      this.__glCurvesLibrary.bindCurvesAtlas(renderstate)

      gl.uniform2i(
        unifs.trimSetAtlasTextureSize.location,
        this.__trimSetAtlasTextureSize[0],
        this.__trimSetAtlasTextureSize[1]
      )
      gl.uniform1f(unifs.stripWidth.location, 1.25)

      // Initially a build up the data around the edges by rasterizing a full color pixel,
      // followed by a subtractive pass that cuts down the borders
      //
      // Initially, after the fans are rasterized.
      //     ----
      //  .  |  .
      //  .  |  .
      //     ----
      //
      // Becomes
      //  -------
      //  .     .
      //  .     .
      //  -------
      //
      // Becomes
      //    . ---
      //    ./
      //   /.
      //  -------
      // After the subtraction pass
      gl.uniform1i(unifs.flatten.location, 1)
      gl.disable(gl.BLEND)

      for (const key in trimCurveDrawSets) {
        const detail = parseInt(key)
        if (detail < 0) continue
        let trimCurveDrawSet = this.__trimCurveDrawSets[key]
        if (!trimCurveDrawSet) {
          trimCurveDrawSet = new GLTrimCurveDrawSet(this.__gl, detail, trimCurveDrawSets[key])
          this.__trimCurveDrawSets[key] = trimCurveDrawSet
        }
        trimCurveDrawSet.drawStrips(renderstate)
      }

      // Now subtract the gradient.
      gl.uniform1i(unifs.flatten.location, 0)
      gl.enable(gl.BLEND)
      gl.blendEquation(gl.MIN)
      gl.blendFunc(gl.ONE, gl.ONE)

      for (const key in trimCurveDrawSets) {
        const detail = parseInt(key)
        if (detail < 0) continue
        let trimCurveDrawSet = this.__trimCurveDrawSets[key]
        if (!trimCurveDrawSet) {
          trimCurveDrawSet = new GLTrimCurveDrawSet(this.__gl, detail, trimCurveDrawSets[key])
          this.__trimCurveDrawSets[key] = trimCurveDrawSet
        }
        trimCurveDrawSet.drawStrips(renderstate)
      }

      gl.disable(gl.BLEND)
    }

    for (const key in trimCurveDrawSets) {
      const trimCurveDrawSet = this.__trimCurveDrawSets[key]
      if (trimCurveDrawSet) trimCurveDrawSet.cleanup()
    }

    this.__trimSetsAtlasLayoutData = trimSetsAtlasLayoutData
    // console.log("----------------------------------");
    // const st_x = Math.round(trimSetAtlasTextureSize[0] / 2)
    // const st_y = trimSetAtlasTextureSize[0] - 4;; //Math.round(trimSetAtlasTextureSize[1] / 2)
    // gl.finish();
    // this.logTrimSet(0);

    gl.finish()
  }

  /**
   * The logTrimSetMask method.
   * @param {any} trimSetId - The trimSetId param.
   */
  logTrimSetMask(trimSetId) {
    const gl = this.__gl
    this.__trimSetAtlasMaskFbo.bind()
    const layout = [
      this.__trimSetsAtlasLayoutData[trimSetId * 4 + 0],
      this.__trimSetsAtlasLayoutData[trimSetId * 4 + 1],
      this.__trimSetsAtlasLayoutData[trimSetId * 4 + 2],
      this.__trimSetsAtlasLayoutData[trimSetId * 4 + 3]
    ]
    console.log('logTrimSetMask ' + trimSetId + ':[' + layout[0] + ',' + layout[1] + ']:' + layout[2] + 'x' + layout[3])
    const pixels = new Uint8Array(layout[2] * 4)
    for (let i = 0; i < layout[3]; i++) {
      gl.readPixels(
        layout[0],
        layout[1] + i,
        layout[2],
        1,
        gl.name == 'webgl2' ? gl.RED : gl.RGBA,
        gl.UNSIGNED_BYTE,
        pixels
      )
      let line = i + ' '
      for (let j = 0; j < layout[2]; j++) {
        // line += (pixels[j * 4] % 2 == 0 ? '-' : '*');
        line += pixels[j * 4]
      }
      console.log(line)
    }
  }

  /**
   * The logTrimSet method.
   * @param {any} trimSetId - The trimSetId param.
   */
  logTrimSet(trimSetId) {
    const gl = this.__gl
    this.__trimSetAtlasFbo.bind()
    const layout = [
      this.__trimSetsAtlasLayoutData[trimSetId * 4 + 0],
      this.__trimSetsAtlasLayoutData[trimSetId * 4 + 1],
      this.__trimSetsAtlasLayoutData[trimSetId * 4 + 2],
      this.__trimSetsAtlasLayoutData[trimSetId * 4 + 3]
    ]
    console.log('logTrimSet ' + trimSetId + ':[' + layout[0] + ',' + layout[1] + ']:' + layout[2] + 'x' + layout[3])
    const pixels = new Uint16Array(layout[2] * 4)
    for (let i = 0; i < layout[3]; i++) {
      gl.readPixels(layout[0], layout[1] + i, layout[2], 1, gl.RGBA, gl.HALF_FLOAT, pixels)
      let line = i + ' '
      for (let j = 0; j < layout[2]; j++) {
        // line += (pixels[j * 4] % 2 == 0 ? '-' : '*');
        line += pixels[j * 4]
      }
      console.log(line)
    }
  }

  /**
   * The bindTrimSetAtlas method.
   * @param {any} renderstate - The renderstate param.
   */
  bindTrimSetAtlas(renderstate) {
    const gl = this.__gl
    const unifs = renderstate.unifs
    const { trimSetAtlasTexture, trimSetAtlasTextureSize } = unifs
    if (this.__trimSetAtlasTexture && trimSetAtlasTexture) {
      this.__trimSetAtlasTexture.bindToUniform(renderstate, trimSetAtlasTexture)
      if (trimSetAtlasTextureSize) {
        gl.uniform2i(
          trimSetAtlasTextureSize.location,
          this.__trimSetAtlasTextureSize[0],
          this.__trimSetAtlasTextureSize[1]
        )
      }

      if (this.ext_filter_anisotropic) {
        // Disable anisotropic filtering on this texture.
        gl.texParameterf(gl.TEXTURE_2D, this.ext_filter_anisotropic.TEXTURE_MAX_ANISOTROPY_EXT, 1.0)
      }
    }
  }

  /**
   * The bindTrimSetAtlasLayout method.
   * @param {any} renderstate - The renderstate param.
   */
  bindTrimSetAtlasLayout(renderstate) {
    // During debugging, we disable trim sets.
    if (!this.__trimSetsAtlasLayoutTexture) return
    const gl = this.__gl
    const unifs = renderstate.unifs
    this.__trimSetsAtlasLayoutTexture.bindToUniform(renderstate, unifs.trimSetsAtlasLayoutTexture)
    gl.uniform2i(
      unifs.trimSetsAtlasLayoutTextureSize.location,
      this.__trimSetsAtlasLayoutTexture.width,
      this.__trimSetsAtlasLayoutTexture.height
    )
  }

  /**
   * The drawTrimSets method.
   * @param {any} renderstate - The renderstate param.
   * @return {boolean} - The return value.
   */
  drawTrimSets(renderstate) {
    if (!this.__trimSetAtlasTexture || !this.debugTrimSetsShader.bind(renderstate)) return false
    // this.bindTrimSetAtlas(renderstate);

    this.__trimSetAtlasTexture.bindToUniform(renderstate, renderstate.unifs.trimSetAtlasTexture)
    this.__cadpassdata.glplanegeom.bind(renderstate)
    this.__cadpassdata.glplanegeom.draw()
  }

  /**
   * The destroy method.
   */
  destroy() {
    this.__trimSetsTexture.destroy()
    if (this.__trimSetsAtlasLayoutTexture) {
      this.__trimSetsAtlasLayoutTexture.destroy()
      this.__trimSetAtlasMaskTexture.destroy()
      this.__trimSetAtlasMaskFbo.destroy()
      this.__trimSetAtlasTexture.destroy()
      this.__trimSetAtlasFbo.destroy()
    }
  }
}

export { GLTrimSetLibrary }
