const CADCurveTypes = {
  CURVE_TYPE_LINE: 20,
  CURVE_TYPE_CIRCLE: 21,
  CURVE_TYPE_ELIPSE: 22,
  //  CURVE_TYPE_HYPERBOLA: 23,
  //  CURVE_TYPE_PARABOLA: 24,
  //  CURVE_TYPE_BEZIERCURVE: 25,
  CURVE_TYPE_NURBS_CURVE: 26,
  //  CURVE_TYPE_OFFSET_CURVE: 27,
  //  CURVE_TYPE_TRIMMED_CURVE: 28
}

const CADSurfaceTypes = {
  SURFACE_TYPE_PLANE: 0,
  SURFACE_TYPE_CONE: 1,
  SURFACE_TYPE_CYLINDER: 2,
  SURFACE_TYPE_SPHERE: 3,
  SURFACE_TYPE_TORUS: 4,
  SURFACE_TYPE_LINEAR_EXTRUSION: 5,
  SURFACE_TYPE_REVOLUTION: 6,
  //  SURFACE_TYPE_BEZIER_SURFACE: 7,
  SURFACE_TYPE_NURBS_SURFACE: 8,
  SURFACE_TYPE_OFFSET_SURFACE: 9,
  SURFACE_TYPE_TRIMMED_RECT_SURFACE: 10,
  SURFACE_TYPE_POLY_PLANE: 14,
  SURFACE_TYPE_FAN: 15,
  SURFACE_TYPE_REVOLUTION_FLIPPED_DOMAIN: 16,
}

const getCurveTypeName = (id) => {
  switch (id) {
    case CADCurveTypes.CURVE_TYPE_LINE:
      return 'CURVE_TYPE_LINE'
    case CADCurveTypes.CURVE_TYPE_CIRCLE:
      return 'CURVE_TYPE_CIRCLE'
    case CADCurveTypes.CURVE_TYPE_ELIPSE:
      return 'CURVE_TYPE_ELIPSE'
    // case CADCurveTypes.CURVE_TYPE_HYPERBOLA: return 'CURVE_TYPE_HYPERBOLA';
    // case CADCurveTypes.CURVE_TYPE_PARABOLA: return 'CURVE_TYPE_PARABOLA';
    // case CADCurveTypes.CURVE_TYPE_BEZIERCURVE: return 'CURVE_TYPE_BEZIERCURVE';
    case CADCurveTypes.CURVE_TYPE_NURBS_CURVE:
      return 'CURVE_TYPE_NURBS_CURVE'
    // case CADCurveTypes.CURVE_TYPE_OFFSET_CURVE: return 'CURVE_TYPE_OFFSET_CURVE';
    // case CADCurveTypes.CURVE_TYPE_TRIMMED_CURVE: return 'CURVE_TYPE_TRIMMED_CURVE';
  }
}

const getSurfaceTypeName = (id) => {
  switch (id) {
    case CADSurfaceTypes.SURFACE_TYPE_PLANE:
      return 'SURFACE_TYPE_PLANE'
    case CADSurfaceTypes.SURFACE_TYPE_CONE:
      return 'SURFACE_TYPE_CONE'
    case CADSurfaceTypes.SURFACE_TYPE_CYLINDER:
      return 'SURFACE_TYPE_CYLINDER'
    case CADSurfaceTypes.SURFACE_TYPE_SPHERE:
      return 'SURFACE_TYPE_SPHERE'
    case CADSurfaceTypes.SURFACE_TYPE_TORUS:
      return 'SURFACE_TYPE_TORUS'
    case CADSurfaceTypes.SURFACE_TYPE_LINEAR_EXTRUSION:
      return 'SURFACE_TYPE_LINEAR_EXTRUSION'
    case CADSurfaceTypes.SURFACE_TYPE_REVOLUTION:
      return 'SURFACE_TYPE_REVOLUTION'
    //    case CADSurfaceTypes.SURFACE_TYPE_BEZIER_SURFACE: return 'SURFACE_TYPE_BEZIER_SURFACE';
    case CADSurfaceTypes.SURFACE_TYPE_NURBS_SURFACE:
      return 'SURFACE_TYPE_NURBS_SURFACE'
    case CADSurfaceTypes.SURFACE_TYPE_OFFSET_SURFACE:
      return 'SURFACE_TYPE_OFFSET_SURFACE'
    case CADSurfaceTypes.SURFACE_TYPE_TRIMMED_RECT_SURFACE:
      return 'SURFACE_TYPE_TRIMMED_RECT_SURFACE'
    case CADSurfaceTypes.SURFACE_TYPE_POLY_PLANE:
      return 'SURFACE_TYPE_POLY_PLANE'
    case CADSurfaceTypes.SURFACE_TYPE_FAN:
      return 'SURFACE_TYPE_FAN'
    case CADSurfaceTypes.SURFACE_TYPE_REVOLUTION_FLIPPED_DOMAIN:
      return 'SURFACE_TYPE_REVOLUTION_FLIPPED_DOMAIN'
  }
}

const geomLibraryHeaderSize = 8 // 2 FP16 pixels at the start of the GeomLibrary and CurveLibrary
// const pixelsPerDrawItem = 10 // The number of RGBA pixels per draw item.
const pixelsPerDrawItem = 3 // tr, ori, sc: number of RGBA pixels per draw item.
const valuesPerCurveTocItem = 8
const valuesPerSurfaceTocItem = 9
const valuesPerCurveLibraryLayoutItem = 8
const valuesPerSurfaceLibraryLayoutItem = 8
//const valuesPerSurfaceRef = 11 // A surfaceRef within a BodyDesc// This is now different based on the version.
const drawItemShaderAttribsStride = 8
const floatsPerSceneBody = 2
const drawShaderAttribsStride = 4 //drawCoords: body ID, Surface index in Body, Surface Id, TrimSet Id // + 2 drawItemTexAddr
const numValuesPerTrimSetCurveRef = 14 //patchCoords.xyzw, tr.xy, ori.xy, ori.zy, addr.xy, flags, curveId, curveIndexWithinLoop

const CURVE_FLAG_PERIODIC = 1 << 0
const CURVE_FLAG_UNUSED2 = 1 << 2
const CURVE_FLAG_COST_IS_DETAIL = 1 << 3

const SURFACE_FLAG_PERIODIC_U = 1 << 0
const SURFACE_FLAG_PERIODIC_V = 1 << 1
const SURFACE_FLAG_UNUSED2 = 1 << 2
const SURFACE_FLAG_UNUSED3 = 1 << 3
const SURFACE_FLAG_FLIPPED_NORMAL = 1 << 4
const SURFACE_FLAG_FLIPPED_UV = 1 << 5
const SURFACE_FLAG_COST_IS_DETAIL_U = 1 << 6
const SURFACE_FLAG_COST_IS_DETAIL_V = 1 << 7

const BODY_FLAG_CUTAWAY = 1 << 8
const BODY_FLAG_INVISIBLE = 1 << 9

export {
  CADCurveTypes,
  CADSurfaceTypes,
  getCurveTypeName,
  getSurfaceTypeName,
  CURVE_FLAG_COST_IS_DETAIL,
  SURFACE_FLAG_PERIODIC_U,
  SURFACE_FLAG_PERIODIC_V,
  SURFACE_FLAG_COST_IS_DETAIL_U,
  SURFACE_FLAG_COST_IS_DETAIL_V,
  SURFACE_FLAG_FLIPPED_NORMAL,
  SURFACE_FLAG_FLIPPED_UV,
  BODY_FLAG_CUTAWAY,
  BODY_FLAG_INVISIBLE,
  geomLibraryHeaderSize,
  pixelsPerDrawItem,
  valuesPerCurveTocItem,
  valuesPerSurfaceTocItem,
  valuesPerCurveLibraryLayoutItem,
  valuesPerSurfaceLibraryLayoutItem,
  /*valuesPerSurfaceRef,*/
  drawItemShaderAttribsStride,
  drawShaderAttribsStride,
  floatsPerSceneBody,
  numValuesPerTrimSetCurveRef,
}
