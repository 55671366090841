/* eslint-disable camelcase */
import { GLShader, shaderLibrary, Registry } from '@zeainc/zea-engine'

/** Class representing a GL draw trim curve strips shader.
 * @extends GLShader
 * @ignore
 */
class GLDrawTrimCurveStripsShader extends GLShader {
  /**
   * Create a GL draw trim curve strips shader.
   * @param {any} gl - The gl value.
   */
  constructor(gl) {
    super(gl)

    this.__shaderStages['VERTEX_SHADER'] = shaderLibrary.parseShader(
      'GLDrawTrimCurveStripsShader.vertexShader',
      `
precision highp float;

attribute vec4 positions;
instancedattribute vec4 patchCoords;         // instanced attribute..
instancedattribute vec4 data0;     // instanced attribute..
instancedattribute vec4 data1;     // instanced attribute..
instancedattribute vec3 data2;     // instanced attribute..

<%include file="GLSLUtils.glsl"/>
<%include file="GLSLMath.glsl"/>

uniform sampler2D curvesAtlasTexture;
uniform ivec2 trimSetAtlasTextureSize;

uniform int numCurveVertices;
uniform float stripWidth;

vec2 getCurveVertex(int vertexId, int flags) {
  if(flags != 0)
    vertexId = numCurveVertices - vertexId - 1;
  return fetchTexel(curvesAtlasTexture, trimSetAtlasTextureSize, ivec2(int(data1.z) + vertexId, int(data1.w))).rg;
}

#define M_PI 3.1415926535897932384626433832795

/* VS Outputs */
varying float v_curveIndexWithinLoop;
varying float v_gradient;

void main(void) {

  int vertexId = ftoi(positions.x);
  float side = positions.y < 0.0 ? -1.0 : 1.0;
  mat2 rot = mat2(data0.zw, data1.xy);
  int curveRefFlags = ftoi(data2.x);
  int curveIndexWithinLoop = ftoi(data2.y);
  v_curveIndexWithinLoop = float(curveIndexWithinLoop);

  // Tranform the curve points by the mat2 to put it into the coords of the trim set.
  vec2 pos = data0.xy + (rot * getCurveVertex(vertexId, curveRefFlags));

  //////////////////////////////////////////////
  vec2 curveTangent;
  if(vertexId > 0) {
    vec2 posPrev = data0.xy + (rot * getCurveVertex(vertexId-1, curveRefFlags));
    curveTangent += pos - posPrev;
  }
  if(vertexId < numCurveVertices-1) {
    vec2 posNext = data0.xy + (rot * getCurveVertex(vertexId+1, curveRefFlags));
    curveTangent += posNext - pos;
  }
  curveTangent = normalize(curveTangent);
  vec2 curveNormal = vec2(-curveTangent.y, curveTangent.x);

  // Fatten the strip
  pos += (curveNormal * side * stripWidth) / patchCoords.zw;

  //////////////////////////////////////////////
  // Extend the strip by one pixel at each end.
  if(vertexId == 0) {
    pos -= curveTangent * (stripWidth / patchCoords.zw) * 0.5;
  }
  else if(vertexId == numCurveVertices-1) {
    pos += curveTangent * (stripWidth / patchCoords.zw) * 0.5;
  }
  //////////////////////////////////////////////

  // Now transform the trim set into the coords of the full texture.
  pos = (patchCoords.xy + (pos * patchCoords.zw));
  pos /= vec2(trimSetAtlasTextureSize);

  // transform the position into clip space.
  gl_Position = vec4((pos * 2.0) - 1.0, 0.0, 1.0);
  

  // The gradient should run 0.0 ... 1.0 from one side of the strip to the other.
  // The side value ranges from -1.0 to +1.0

  v_gradient = (side + 1.0) / 2.0;
  // v_gradient = 1.0;

  // Note: this causes the trim edge to move slightly to grow.
  // This fills in slight gaps betwen trimmed surfaces.
  // This causes lots of atrifacts on some thin surfaces
  // On Mordacious, this causese many artifacts at the border of surfaces.
  // v_gradient = (v_gradient * 1.1) + 0.05;
  // v_gradient = (v_gradient * 1.2) + 0.1;
  // v_gradient = (v_gradient * 1.5) + 0.25;
  // v_gradient = (v_gradient * 2.0) + 0.5;
  // v_gradient = (v_gradient * 2.0) + 1.0;
}
`
    )

    this.__shaderStages['FRAGMENT_SHADER'] = shaderLibrary.parseShader(
      'GLDrawTrimCurveStripsShader.fragmentShader',
      `
precision highp float;

<%include file="GLSLUtils.glsl"/>

uniform int flatten;

/* VS Outputs */
varying float v_curveIndexWithinLoop;
varying float v_gradient;

#ifdef ENABLE_ES3
out vec4 fragColor;
#endif

void main(void) {

#ifndef ENABLE_ES3
  vec4 fragColor;
#endif

  // Initially a build up the data around the edges by rasterizing a full color pixel,
  // followed by a subtractive pass that cuts down the borders
  //
  // Initially, after the fans are rasterized 
  //     ----
  //  .  |  .
  //  .  |  .
  //     ----
  //
  // Becomes 
  //  -------
  //  .     .
  //  .     .
  //  -------
  //
  // Becomes 
  //    . ---
  //    ./     
  //   /. 
  //  -------
  // After the subtraction pass


  
  if (flatten > 0) {
    fragColor = vec4(1.0, 1.0, 1.0, 1.0);
  } else {
    int curveIndexWithinLoop = ftoi(v_curveIndexWithinLoop);
    if (curveIndexWithinLoop % 2 == 0) {
      fragColor = vec4(v_gradient, 1.0, 1.0, 1.0);
    }
    else {
      fragColor = vec4(1.0, v_gradient, 1.0, 1.0);
    }
  }
    
#ifndef ENABLE_ES3
  gl_FragColor = fragColor;
#endif
}
`
    )
  }
}

Registry.register('GLDrawTrimCurveStripsShader', GLDrawTrimCurveStripsShader)

export { GLDrawTrimCurveStripsShader }
